import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { CompanyService } from "../../service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalFormCreateQuestionComponent } from "../modal-form-create-question/modal-form-create-question.component";
import { ModalChoiceComponent } from "../modal-choice/modal-choice.component";
import { ModalFormPermissionsComponent } from "../modal-form-permissions/modal-form-permissions.component";

@Component({
  selector: "modal-form-create",
  templateUrl: "./modal-form-create.component.html",
  styleUrls: ["./modal-form-create.component.css"],
})
export class ModalFormCreateComponent {
  url: string = "https://www.mmlpqtpkasjdashdjahd.com";
  urlSafe: SafeResourceUrl;
  constructor(
    public modalRef: MDBModalRef,
    public sanitizer: DomSanitizer,
    private modalService: MDBModalService,
    private companyService: CompanyService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";

  form_clone;
  is_new: boolean;
  loading: boolean = false;
  titleQuestion: string = "";
  activated: boolean = true;
  questions = [];
  validator = new ValidatorUtils();
  permissions = [];
  access_to_patient = false;
  caption_image;
  caption_text;
  core_plan_id;
  ngOnInit() {
    console.log(this.questions)
  }

  isEmpty(str) {
    if (!str || !str.length) {
      return true;
    }
    return false;
  }

  submit() {
    if (this.isEmpty(this.titleQuestion)) {
      this.openMessage(
        "Erro",
        "Você precisa informar um título para o formulário"
      );
      return;
    }

    if (!this.questions || !this.questions.length) {
      this.openMessage(
        "Erro",
        "Você precisa adicionar pelo menos uma questão para o formulário"
      );
      return;
    }

    const form = {
      title: this.titleQuestion,
      activated: this.activated,
      questions: this.questions,
      doctors: this.permissions,
      access_to_patient: this.access_to_patient,
      caption_image: this.caption_image,
      caption_text: this.caption_text,
      core_plan_id: this.core_plan_id
    };
    //console.log(form);

    this.loading = true;
    this.companyService.createForm(form).subscribe(
      (data) => {
        this.openMessage("Sucesso", "Formulário criado!");
        this.action.next(true);
        this.modalRef.hide();
      },
      (error) => {
        let msg = "Ocorreu um erro, tente novamente";
        if (
          error.response &&
          error.response.data &&
          error.response.data.non_field_errors &&
          error.response.data.non_field_errors.length > 0
        ) {
          msg = error.response.data.non_field_errors[0];
        }
        this.openMessage("Erro", msg);

        this.loading = false;
      }
    );
  }

  openMessage(title, text, bgclick: boolean = false) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  formPermissions() {
    this.modalRef2 = this.modalService.show(ModalFormPermissionsComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: "Editar permissões",
        btn_text: "Salvar",
        selectedDoctors: this.permissions,
        access_to_patient: this.access_to_patient,
        file: this.caption_image,
        captionTitle: this.caption_text

      },
    });
    this.modalRef2.content.action.subscribe((result: any) => {
      this.permissions = result.permissions;
      this.access_to_patient = result.access_to_patient
    });
  }
  getType(q) {
    if (q.is_scale) {
      return "6";
    }

    if (q.is_frequency) {
      return "5";
    }
  
    if (q.is_numeric_scale) {
      return "7";
    }
  
    if (q.is_numeric) {
      return "2";
    }
    if (q.is_image) {
      return "3";
    }
    if (q.is_date) {
      return "4";
    }  
    if (q.is_list) {
      return "8";
    }
    if (q.is_selectable) {
      return "1";
    }
    return "0";
    
  }

  getTypeDate(type) {
    if(type === 'full') 
      return '0';
      if(type === 'date') 
      return '1';
      if(type === 'hour') 
      return '2';
  }

  addQuestion(pos = null) {
    
    this.modalRef2 = this.modalService.show(ModalFormCreateQuestionComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: pos !== null ? "Editar Questão" : "Salvar Questão",
        btn_text: pos !== null ? "Editar" : "Adicionar",
        is_new: pos !== null ? false : true,
        titleQuestion: pos !== null ? this.questions[pos].title : null,
        position: pos !== null ? this.questions[pos].position : 0,
        type:
          pos !== null ? (this.getType(this.questions[pos])) : 0,

        required: pos !== null ? this.questions[pos].required : false,
        number_of_choices:
          pos !== null ? this.questions[pos].number_of_choices : 1,
        alternatives: pos !== null ? this.questions[pos].alternatives : [],

        type_date:  pos !== null ? this.getTypeDate(this.questions[pos].type_date): null,
        min_frequency: pos !== null ? this.questions[pos].min_frequency: null,
        max_frequency: pos !== null ? this.questions[pos].max_frequency: null,
        range_frequency: pos !== null ? this.questions[pos].range_frequency: 1,
        intervals_score_answer: pos !== null ? this.questions[pos].range_frequency: [],
        // is_date: pos !== null ? this.questions[pos].is_date : false,
        // is_frequency: pos !== null ? this.questions[pos].is_frequency : false,
        // is_image: pos !== null ? this.questions[pos].is_image : false,
        // is_numeric: pos !== null ? this.questions[pos].is_numeric : false,
        // is_numeric_scale: pos !== null ? this.questions[pos].is_numeric_scale : false,
        // is_scale: pos !== null ? this.questions[pos].is_scale : false,
        // is_selectable: pos !== null ? this.questions[pos].is_selectable : false,


      },
    });
    this.modalRef2.content.action.subscribe((result: any) => {
      this.modalRef2.hide();
      
      const aux = this.questions;
      if(pos !== null) {
        aux.splice(pos, 1);
      }
      aux.push(result);
      aux.sort((a, b) => a.position - b.position);
      this.questions = aux;
    });
  }

  deleteQuestion(pos) {
    this.modalRef2 = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja deletar a questão?`,
      },
    });
    this.modalRef2.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.questions.splice(pos, 1);
      }
    });
  }

  editQuestion(pos) {
    this.addQuestion(pos);
  }
}
