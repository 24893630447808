import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalChoiceComponent } from "../modals/modal-choice/modal-choice.component";
import { ModalFormAnswerListComponent } from "../modals/modal-form-answer-list/modal-form-answer-list.component";
import { ModalFormAnswerComponent } from "../modals/modal-form-answer/modal-form-answer.component";
import { ModalFormCreateComponent } from "../modals/modal-form-create/modal-form-create.component";
import { ModalFormDetailComponent } from "../modals/modal-form-detail/modal-form-detail.component";
import { ModalFormFillComponent } from "../modals/modal-form-fill/modal-form-fill.component";
import { ModalFormPermissionsComponent } from "../modals/modal-form-permissions/modal-form-permissions.component";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { CompanyService } from "../service/company.service";
import { MatSort } from "@angular/material/sort";
import { ModalFormModelListComponent } from "../modals/modal-form-model-list/modal-form-model-list.component";
import { Router } from "@angular/router";
import { ModalCorePlanCreateComponent } from "../modals/modal-core-plan-create/modal-core-plan-create.component";
import { ModalCorePlanPatientsComponent } from "../modals/modal-core-plan-patients/modal-core-plan-patients.component";

@Component({
  selector: "app-core-plan",
  templateUrl: "./core-plan.component.html",
  styleUrls: ["./core-plan.component.css"],
})
export class CorePlanComponent implements OnInit {
  constructor(
    private companyService: CompanyService,
    private modalService: MDBModalService,
    private router: Router
  ) {}

  displayedColumns: string[] = ["empty", "id", "name", "forms", "icons"];
  data_source = new MatTableDataSource<any>();

  modalRef: MDBModalRef;

  is_loaded: boolean = false;

  /* pagination related variabels */
  page: number = 1;
  count_total: number = 0;
  page_count1: number = 0;
  page_count2: number = 0;
  page_size = 10;

  is_company_admin: boolean = false;
  company_type = localStorage.getItem('companyType')
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngOnInit(): void {
    if (
      localStorage.getItem("admin") === "true" &&
      localStorage.getItem("company") !== null
    ) {
      this.is_company_admin = true;
      this.getCorePlans(1);
    }
  }

  truncateString(str, max) {
    return str.length > max ? str.substr(0, max - 1) + "…" : str;
  }

  getCorePlans(page) {
    this.is_loaded = false;
    this.companyService.getCorePlans().subscribe(
      (data) => {
        this.data_source.data = data;
        this.data_source.sort = this.sort;
        // pagination calc
        this.page = page;
        this.count_total = data.length;
        this.page_count1 = 1 + (page - 1) * this.page_size;
        if (this.page_count1 + (this.page_size - 1) > this.count_total) {
          this.page_count2 = this.count_total;
        } else {
          this.page_count2 = this.page_count1 + (this.page_size - 1);
        }
        this.is_loaded = true;
      },
      (error) => {
        //console.log(error);
        this.is_loaded = true;
      }
    );
  }

  newForm() {
    this.modalRef = this.modalService.show(ModalCorePlanCreateComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: "Adicionar Plano de Cuidado",
        btn_text: "Adicionar",
        is_new: true,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.getCorePlans(this.page);
        this.modalRef.hide();
      }
    });
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  editCorePlan(corePlan) {
    this.modalRef = this.modalService.show(ModalCorePlanCreateComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: "Editar Plano de cuidado",
        btn_text: "Salvar",
        is_new: false,
        core_plan_id: corePlan.id,
        selected_forms: corePlan.forms.map((core) => {
          return core.id;
        }),
        name: corePlan.name,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.getCorePlans(this.page);
        this.modalRef.hide();
      }
    });
  }

  deleteCorePlan(corePlan) {
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja deletar o plano de cuidado ${corePlan.name}?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.openMessage("Por favor, aguarde", "", true);
        this.companyService.deleteCorePlan(corePlan.id).subscribe(
          (data) => {
            this.modalRef.hide();
            this.openMessage(
              "Deletado!",
              "Plano de cuidado deletado com sucesso.",
              false
            );
            this.getCorePlans(this.page);
          },
          (error) => {
            this.modalRef.hide();
            this.error(error, "deletar");
          }
        );
      }
    });
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível ${string} o plano de cuidado.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  next() {
    if (this.page_count2 < this.count_total && this.is_loaded) {
      this.data_source.data = [];
      this.is_loaded = false;
      this.getCorePlans(this.page + 1);
    }
  }

  prev() {
    if (this.page > 1 && this.is_loaded) {
      this.data_source.data = [];
      this.is_loaded = false;
      this.getCorePlans(this.page - 1);
    }
  }

  openFormsPage() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/home/form"])
    );
    window.open(url, "_blank");
  }

  openScalesPage() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/home/scales"])
    );
    window.open(url, "_blank");
  }

  openPatients(corePlan) {
    this.modalRef = this.modalService.show(ModalCorePlanPatientsComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-xl",
      containerClass: "",
      animated: false,
      data: {
        core_plan: corePlan,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.getCorePlans(this.page);
        this.modalRef.hide();
      }
    });
  }
}
