import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalFormQuestionCaptionComponent } from "../modal-form-question-caption/modal-form-question-caption";

@Component({
  selector: "form-question",
  templateUrl: "./form-question.component.html",
  // styleUrls: ["./modal-fill-form.component.css"],
})
export class FormQuestionComponent {
  @Input() required: any;
  @Input() title: any;
  @Input() answers: any;
  @Input() setAnswer: any;
  @Input() question: any;
  @Input() caption_image: any;
  @Input() caption_text: any;
  @Input() is_numeric: any;
  @Input() is_numeric_scale: any;
  @Input() is_image: any;
  @Input() is_date: any;
  @Input() type_date: any;
  @Input() is_frequency: any;
  @Input() min_frequency: any;
  @Input() max_frequency: any;
  @Input() range_frequency: any;
  @Input() intervals_score_answer: any;
  @Input() totalScore: any;
  lastScore = 0;
  modalRef: MDBModalRef;
  constructor(private modalService: MDBModalService) {}
  ngOnInit() {
    console.log(this.answers)
  }
  
  
  datemask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  hourmask = [/\d/, /\d/, ':', /\d/, /\d/];
  dateHourmask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];

  openCaption() {
    this.modalRef = this.modalService.show(ModalFormQuestionCaptionComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      // ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Legenda",
        caption_image: this.caption_image,
        caption_text: this.caption_text,
      },
    });
  }

  async onFileSelected(event) {
    const file: File = event.target.files[0];

    // this.fileNameQuestion[question.id] = file.name;
    // this.fileQuestion[question.id] = file;
    const reader = new FileReader();
    reader.onload = (e) => (this.answers[this.question.id] = reader.result);

    reader.readAsDataURL(file);

    // const upload$ = this.http.post("/api/thumbnail-upload", formData);

    // upload$.subscribe();
  }
  openImageFull(url) {
    window.open(url, "_blank");
  }

  changeValue(value) {
    let newAnswer = this.answers[this.question.id];
    if (!newAnswer) {
      newAnswer = this.min_frequency + value;
    } else {
      newAnswer += value;
    }
    newAnswer = Math.min(newAnswer, this.max_frequency);
    newAnswer = Math.max(newAnswer, this.min_frequency);

    this.answers[this.question.id] = newAnswer
  }

  getScoreByAnswer(value) {
    for (const interval of this.intervals_score_answer) {
      if (value >= interval.start_value && value <= interval.end_value) {
        return interval.score;
      }
    }
    return 0;
  }

  changeValueNumericScale(ev) {
    // console.log(ev);
    const newScore = this.getScoreByAnswer(ev);
    this.totalScore.value -= this.lastScore;
    this.totalScore.value += newScore;
    this.lastScore = newScore;
  }

  update(val, ev) {
    // this.setAnswer({
    //   ...this.answer,
    //   ...this.getValue(val, !this.answer[val.id]),
    // });
    // console.log(ev);
    // console.log(val);

    
    // if (ev) 
    //   this.totalScore.value += val?.score ?? 0;
    // else
    //   this.totalScore.value -= val?.score ?? 0;

    
    // this.totalScore =
  }
}
