import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MedicationService {
  constructor(private http: HttpClient) {}

  getMedication(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + "core/medication/", options);
  }

  getOtherMedication(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + "core/medication/filter/?q=&other=True",
      options
    );
  }

  getMedicationId(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/medication/${id}/`,
      options
    );
  }

  getSchedule(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/schedule-medication/schedules/${id}/`,
      options
    );
  }

  getMedicationFilter(search, page): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),

      params: new HttpParams().set("q", search).set("page", page),
    };

    return this.http.get<any>(
      AppConfig.path + `core/medication/filter/`,
      options
    );
  }

  insertMedicationPatient(params, service_id = null): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + `/core/medication-patient/create/${service_id ? '?service=' + service_id : ''}`,
      params,
      { headers }
    );
  }

  insertSchedule(params, patient_id): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + `/core/schedule-medication/add/byDoctor/${patient_id}/`,
      params,
      { headers }
    );
  }

  newMedication(
    name,
    description,
    observation,
    commercial_name
  ): Observable<any> {
    let body = new HttpParams()
      .append("name", name)
      .append("description", description)
      .append("commercial_name", commercial_name);

    if (observation != null) {
      body = body.append("observation", observation);
    }

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + "core/medication/",
      body.toString(),
      { headers }
    );
  }

  editMedication(
    id,
    name,
    description,
    observation,
    commercial_name
  ): Observable<any> {
    let body = new HttpParams()
      .append("description", description)
      .append("name", name)
      .append("commercial_name", commercial_name);

    if (observation != null) {
      body = body.append("observation", observation);
    }

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.patch<any>(
      AppConfig.path + `core/medication/${id}/`,
      body.toString(),
      { headers }
    );
  }

  deleteMedication(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.delete<any>(
      AppConfig.path + `core/medication/${id}/`,
      options
    );
  }
}
