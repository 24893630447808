import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.css']
})

export class LandingComponent implements OnInit {

    constructor(
        private router: Router
    ) {
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                var list_url = this.router.url.split('/')
                var tam = list_url.length
                this.url_page = list_url[tam - 1];

                this.nav_white = false;
                this.nav_transparent_white = false;
                this.nav_transparent_purple = false;
                this.nav_purple = false;
               
                if(this.url_page == '' ) {
                    this.nav_purple = true;
                }
                if(this.url_page == 'contact-us' ) {
                    this.nav_transparent_white = true;
                }
                if (this.url_page == 'patient' || 
                    this.url_page == 'homecare' || 
                    this.url_page == 'plans' || 
                    this.url_page == 'partners'
                ) {
                    this.nav_transparent_purple = true
                }
                if(this.url_page == 'about' || this.url_page == 'doctor') {
                    this.nav_white = true;
                }

                window.scrollTo(0,0)
            }
        });
    }

    url_page: string = '';
    nav_purple: boolean = true;
    nav_transparent_white: boolean = false;
    nav_transparent_purple: boolean = false;
    nav_white: boolean = false;

    ngOnInit(): void {

        
    }

}
