import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProcedureService {
  constructor(private http: HttpClient) {}

  getProcedures(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + `core/procedure/all/`, options);
  }

  createProcedure(body): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/procedure/create/`,
      body,
      options
    );
  }

  editProcedure(body, id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.patch<any>(
      AppConfig.path + `core/procedure/edit/${id}/`,
      body,
      options
    );
  }

  deleteProcedures(id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.delete<any>(
      AppConfig.path + `core/procedure/delete/${id}/`,
      options
    );
  }
}
