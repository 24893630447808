import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  getReportDay(start, end): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    const company = localStorage.getItem("company");
    const today = new Date().toISOString().split("T")[0];

    return this.http.get<any>(
      AppConfig.path +
        `core/company/report_day/${company}?start=${start}&end=${end}`,
      options
    );
  }
  getReportDayForms(start, end, form): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    const company = localStorage.getItem("company");
    const today = new Date().toISOString().split("T")[0];

    return this.http.get<any>(
      AppConfig.path +
        `core/company/report_day_forms/${company}/?start=${start}&end=${end}&form=${form}`,
      options
    );
  }
  getPatientsForCorePlan(id, filter): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path +
        `core/core-plan/patients/${id}/` +
        (filter ? "?actived=" + filter : ""),
      options
    );
  }

  getPatientsSimple(filter=true) {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path +
        `core/doctor/patients-simple/` +
        (filter ? "?actived=" + filter : ""),
      options
    );
  }

  getCompanys(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + `core/company/`, options);
  }

  getForms(id = null): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    const company = localStorage.getItem("company");

    return this.http.get<any>(
      AppConfig.path +
        `core/form/by-company-id/${company}/` +
        (id ? "?id=" + id : ""),
      options
    );
  }

  getFormsDashboard(id = null): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    const company = localStorage.getItem("company");

    return this.http.get<any>(
      AppConfig.path +
        `core/form/by-company-id-dashboard/${company}/` +
        (id ? "?id=" + id : ""),
      options
    );
  }

  getScoreAnswers(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    const company = localStorage.getItem("company");

    return this.http.get<any>(
      AppConfig.path + `core/score-answers/all/${company}/`,
      options
    );
  }

  getCorePlans(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    const company = localStorage.getItem("company");

    return this.http.get<any>(
      AppConfig.path + `core/core-plan/by-company/${company}/`,
      options
    );
  }

  updateFormPosition(data): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + "core/form/update-positions/",
      data,
      { headers }
    );
  }

  newForm(form): Observable<any> {
    let body = new HttpParams();

    for (var key in form) {
      if (form[key] != null) body = body.append(key, form[key]);
    }

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + "core/company-form/",
      body.toString(),
      { headers }
    );
  }

  getAnswerFormPatient(
    patient_id,
    date_from,
    date_to,
    fields
  ): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
      params: new HttpParams()
        .set("date_to", date_to)
        .set("date_from", date_from)
        .set("fields", fields),
    };

    return this.http.get<any>(
      AppConfig.path + `core/form/answers/patient/${patient_id}/`,
      options
    );
  }

  getPatientAnswersPDF(id, date_from, date_to, fields): Observable<any> {
    const options = {
      headers: new HttpHeaders({}).set(
        "Authorization",
        `token ${localStorage.getItem("token")}`
      ),
      params: new HttpParams()
        .set("date_to", date_to)
        .set("date_from", date_from)
        .set("fields", fields),
    };

    return this.http.get<any>(
      AppConfig.path + `core/form/answers/patient/pdf/${id}/`,
      options
    );
  }

  getPatientAnswersCSV(id, date_from, date_to, fields): Observable<any> {
    const options = {
      headers: new HttpHeaders({}).set(
        "Authorization",
        `token ${localStorage.getItem("token")}`
      ),
      params: new HttpParams()
        .set("date_to", date_to)
        .set("date_from", date_from)
        .set("fields", fields),
    };

    return this.http.get<any>(
      AppConfig.path + `core/form/answers/patient/csv/${id}/`,
      options
    );
  }

  getAnswerFormCompany(form_id, date_from, date_to): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
      params: new HttpParams()
        .set("date_to", date_to)
        .set("date_from", date_from),
    };

    return this.http.get<any>(
      AppConfig.path + `core/form/answers-company-form/${form_id}/`,
      options
    );
  }

  getAnswerFormCompanyFilter(form_id, body): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
 
    };

    return this.http.post<any>(
      AppConfig.path + `core/form/answers-company-form-filter/${form_id}/`,
      body,
      options
    );
  }

  getAnswerFormPatientByFormId(answer_id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/form/answers-id/${answer_id}/`,
      options
    );
  }

  deleteFormAnswerById(answer_id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.delete<any>(
      AppConfig.path + `core/form/answers/${answer_id}/`,
      options
    );
  }

  getFormsFromCompany(patient_id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/form/by-company/patient/${patient_id}/`,
      options
    );
  }

  getFormsById(form_id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/form/by-id/${form_id}/`,
      options
    );
  }

  saveAnswers(patient_id, body, service_id = null): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + `core/form/answer/patient/${patient_id}/${service_id ? '?service=' + service_id : ''}`,
      body,
      { headers }
    );
  }

  editFormAnswer(form_answer_id, body, service_id= null): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.patch<any>(
      AppConfig.path + `core/form/answer/edit/${form_answer_id}/${service_id ? '?service=' + service_id : ''}`,
      body,
      { headers }
    );
  }


  

  createScoreAnswers(body): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    const company = localStorage.getItem("company");
    return this.http.post<any>(
      AppConfig.path + `core/score-answers/create/${company}/`,
      body,
      {
        headers,
      }
    );
  }

  editScoreAnswers(body, id): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.patch<any>(
      AppConfig.path + `core/score-answers/edit/${id}/`,
      body,
      {
        headers,
      }
    );
  }

  vinculateFormToAnswer(body, id): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + `core/score-answers/vinculate/forms/${id}/`,
      body,
      {
        headers,
      }
    );
  }

  createForm(body): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);
    const company = localStorage.getItem("company");
    return this.http.post<any>(
      AppConfig.path + `core/form/create-form/?company=${company}`,
      body,
      {
        headers,
      }
    );
  }
  createCorePlan(body): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + `core/core-plan/create/`,
      body,
      {
        headers,
      }
    );
  }

  createPatientCorePlan(body): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + `core/core-plan/patient/vinculate/`,
      body,
      {
        headers,
      }
    );
  }

  closeCorePlanPatient(body): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + `core/core-plan/patient/close/`,
      body,
      {
        headers,
      }
    );
  }

  updatePatientCorePlan(body, id): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.patch<any>(
      AppConfig.path + `core/core-plan/patient/update/${id}/`,
      body,
      {
        headers,
      }
    );
  }

  updateCorePlan(body, id): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.patch<any>(
      AppConfig.path + `core/core-plan/update/${id}/`,
      body,
      {
        headers,
      }
    );
  }

  editForm(form, id): Observable<any> {
    let body = new HttpParams();

    for (var key in form) {
      if (form[key] != null) body = body.append(key, form[key]);
    }

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.put<any>(
      AppConfig.path + `core/company-form/${id}/`,
      body.toString(),
      { headers }
    );
  }

  deleteForm(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.delete<any>(
      AppConfig.path + `core/form/delete-form/${id}/`,
      options
    );
  }

  deleteScoreAnswers(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.delete<any>(
      AppConfig.path + `core/score-answers/remove/${id}/`,
      options
    );
  }

  deleteCorePlan(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.delete<any>(
      AppConfig.path + `core/core-plan/delete/${id}/`,
      options
    );
  }

  deleteCorePlanPatient(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.delete<any>(
      AppConfig.path + `core/core-plan/patient/delete/${id}/`,
      options
    );
  }

  getAnswerForm(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/company-form/answers/${id}/`,
      options
    );
  }

  updateFormDoctors(body, id) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.put<any>(
      AppConfig.path + `core/form/set-doctors/${id}/`,
      body,
      { headers }
    );
  }

  getUsersFromAllCompanies() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.get<any>(
      AppConfig.path + `core/company/number-of-patients/`,
      { headers }
    );
  }

  getDoctorsFromAllCompanies() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.get<any>(
      AppConfig.path + `core/company/number-of-doctors/`,
      { headers }
    );
  }

  getCompanySettings(id) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.get<any>(
      AppConfig.path + `core/company-settings/configs/${id}/`,
      { headers }
    );
  }

  getUsersAndDoctorsFromAllCompanies() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.get<any>(
      AppConfig.path + `core/company/number-of-patients-doctors/`,
      { headers }
    );
  }

  createNewCompany(infos) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    // .set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + "core/company/create-new-company/",
      infos,
      { headers }
    );
  }

  getAllDoctorsFromCompany() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.get<any>(
      AppConfig.path + `core/company/all-doctors/`,
      { headers }
    );
  }


}
