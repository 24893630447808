import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../service/user.service";
import { Md5 } from "md5-typescript";
import { ModalDoctorSelectCompanyComponent } from "../modals/modal-doctor-select-company/modal-doctor-select-company.component";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalRecoveryComponent } from "../modals/modal-recovery/modal-recovery.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  constructor(
    private userService: UserService,
    public router: Router,

    private modalService: MDBModalService
  ) {}

  modalRef: MDBModalRef;
  loading: boolean = false;
  cpf: string;
  password: string;

  mask_cpf;
  checkInput: boolean = false;

  error_message: string;

  ngOnInit(): void {
    localStorage.clear();
  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  checkLogin(cpf) {
    var check = this.isNumber(cpf.substring(0, 2));
    if (check) {
      this.mask_cpf = "000.000.000-00";
    } else {
      this.mask_cpf = null;
    }
  }

  login() {
    this.error_message = "";

    if (this.cpf == null || this.cpf == "") {
      this.error_message = "Por favor, insira o CPF.";
    } else if (this.password == null || this.password == "") {
      this.error_message = "Por favor, insira a senha.";
    } else {
      this.loading = true;

      if (this.cpf != "admin@admin.com") {
        //console.log(this.cpf);
        var passwordMD5 = Md5.init(this.password).toUpperCase();
      } else {
        var passwordMD5 = this.password;
      }

      this.userService.login(this.cpf, passwordMD5).subscribe(
        (data) => {
          localStorage.setItem("token", data.token);
          localStorage.setItem("admin", "false");
          localStorage.setItem("company", null);

          this.userService.getUser().subscribe((dataUser) => {
            if (dataUser.user.is_admin) {
              localStorage.setItem("adminMedical", "true");
            } else if (dataUser.company_user) {
              localStorage.setItem("company", dataUser.company_user.company.id);
              localStorage.setItem(
                "company_settings",
                JSON.stringify(dataUser.company_settings)
              );
              localStorage.setItem('companyType', dataUser.company_settings.company.company_type);

              if (dataUser.company_user.isCompanyAdmin) {
                localStorage.setItem("admin", "true");
              } else {
                localStorage.setItem("admin", "false");
              }
            } else if (dataUser.doctor) {
              localStorage.setItem("doctor", dataUser.doctor.name);
            }

            if (dataUser.user.is_admin) {
              this.router.navigate(["home/admin"]);
            } else if (!dataUser.company_user && !dataUser.doctor) {
              this.router.navigate(["home/not-allowed"]);
            } else {
              if (
                dataUser.company_user &&
                dataUser.company_user.isCompanyAdmin
              ) {
                // this.router.navigate(["home/dashboard"]);
                this.openSelectCompany()
              } else {
                this.router.navigate(["home"]);
              }
            }
          });
        },
        (error) => {
          localStorage.clear();
          this.loading = false;
          this.error_message = "Usuário ou senha incorretos";
        }
      );
    }
  }

  openSelectCompany() {

    this.loading = false;
    this.modalRef = this.modalService.show(ModalDoctorSelectCompanyComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        btn_text: "Adicionar",
        is_new: true,
      },
    });
  }

  getDoctor() {
    this.userService.getDoctor().subscribe(
      (data) => {
        localStorage.setItem("doctor", data[0].name);
        this.router.navigate(["home"]);
      },
      (error) => {
        localStorage.clear();
        this.loading = false;
        this.error_message =
          "Você não possui permissão para acessar esta dashboard";
      }
    );
  }

  openRegister() {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate(["register"]));
  }

  openRecovery() {
    this.loading = false;
    this.modalRef = this.modalService.show(ModalRecoveryComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-sm modal-size",
      containerClass: "",
      animated: false,
      data: {
       
      },
    });
  }
}
