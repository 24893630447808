import { Component, OnInit } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Md5 } from "md5-typescript";
import { ModalChoiceComponent } from "src/app/modals/modal-choice/modal-choice.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { AdminService } from "src/app/service/admin.service";
import { CompanyService } from "src/app/service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";


@Component({
  selector: "app-scoreanswer-model-admin",
  templateUrl: "./scoreanswer-model-admin.component.html",
  styleUrls: ["./scoreanswer-model-admin.component.css"],
})
export class ScoreAnswerModelAdminComponent implements OnInit {
  
  modalRef: MDBModalRef;
  loading: boolean =false;
  data: any[]
  

  titles = ["id", "title", 'edit'];
  

  constructor(
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private adminService: AdminService,
  ) {}

  ngOnInit(): void {
    this.getAnswers();
  }

  deleteForm(form) {
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja deletar essa resposta modelo?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        console.log('Apagar')
        this.adminService.deleteScoreAnswersModel(form.id).subscribe((data) => {
          this.openMessage("Sucesso", "Resposta modelo deletada", false);
          this.getAnswers();
        },
        (error) => {
          this.error(error, "deletar");
        })
      }
    });
  }

  getAnswers() {
    this.loading = true;
    this.adminService.getScoreAnswersModel().subscribe((data) => {
      this.data = [...data];
      this.loading = false;      
    })
  }


  

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} a resposta.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  
}
