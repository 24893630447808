import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { PatientMedicationService } from '../../service/patient-medication.service';
import { MedicationService } from '../../service/medication.service';
import { ModalMessageComponent } from '../modal-message/modal-message.component';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-modal-new-patient-medication',
    templateUrl: './modal-new-patient-medication.component.html',
    styleUrls: ['./modal-new-patient-medication.component.css']
})

export class ModalNewPatientMedicationComponent implements OnInit {

    constructor(
        public modalRef: MDBModalRef, 
        private PatientMedicationService: PatientMedicationService,
        private medicationService: MedicationService,
        private modalService: MDBModalService,
        private datePipe: DatePipe,
    ) { }

	action: Subject<any> = new Subject();
    modalRef2: MDBModalRef;
    loading: boolean = false;

    title: string = "";
    btn_text: string;
    is_new: boolean = true;
    isPatient;

    search: string = '';
    textButton: string = 'Buscar';

    week={
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    }

    selected_medication;
    other_medication: string = "";
    medications = [];

    start_time: string = '';
    start_date = new Date();
    end_time: string = '';
    end_date;

    patientMedication = {
        patient: null,
        quantity_per_day: 1,
    }

    ngOnInit(): void {
        this.patientMedication.patient = this.isPatient.id;
    }

    verificarPesquisa(){
        this.textButton = 'Buscando...'
        if(!this.loading){
            this.loading = true;
            this.medications = [];
            this.getMedications(1);
        }
    }

    getMedications(page){
        this.medicationService.getMedicationFilter(this.search, page).subscribe( data=>{
            this.medications = this.medications.concat(data.results);

            if(this.medications.length == 0){
                var msg = "Por favor verifique as informações inseridas";
                this.openMessage(`Nenhum resultado encontrado`, msg, false);             
            }

            if(data.next != null && page < 5 && this.search != ""){
                this.getMedications(page + 1);
            }
            else{
                this.loading = false;
                this.textButton = 'Buscar';
            }

        },
        error=>{
            //console.log(error)
            this.loading = false;
            this.textButton = 'Buscar';
        })
    }

    getMedication(item){
        this.selected_medication = item;
    }
    
    checkPatientMedication(){

        if( this.selected_medication == null || this.selected_medication == "" ){
            this.openMessage("Por favor,", "selecione o medicamento.", false);
            return false
        }
        else if( this.patientMedication.quantity_per_day == null || this.patientMedication.quantity_per_day <= 0 ){
            this.openMessage("Por favor,", "insira a quantidade de vezes ao dia em que o paciente deve tomar o remédio.", false);
            return false
        }
        else if( this.start_date == null ){
            this.openMessage("Por favor,", "insira a data de início para começar tomar o remédio.", false);
            return false
        }
        else if( this.start_time == null || this.start_time == "" || this.start_time.length < 4 || 
            /^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/.test(this.start_time) == false ){
            this.openMessage("Por favor,", "insira um horário de início válido", false)
        }
        else if( this.end_date == null ){
            this.openMessage("Por favor,", "insira a data final para terminar o uso do remédio.", false);
            return false
        }
        else if( this.end_time == null || this.end_time == "" || this.end_time.length < 4 || 
            /^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/.test(this.end_time) == false ){
            this.openMessage("Por favor,", "insira um horário de fim válido", false)
        }
        else if(this.selected_medication.other && this.other_medication == ""){
            this.openMessage("Por favor,", "como você selecionou outro medicamento, insira o nome dele no campo 'outro'", false)
        }
        else if( 
            this.week.monday == false && this.week.tuesday == false && 
            this.week.wednesday == false && this.week.thursday == false &&
            this.week.friday == false && this.week.saturday == false &&
            this.week.sunday == false 
        ){
            this.openMessage("Por favor,", "marque pelo menos um dia na semana.", false);
            return false
        }
        else{
            let start_date;
            let end_date;

            start_date = this.start_date.setHours(parseInt(this.start_time.substr(0, 2)))
            start_date = this.start_date.setMinutes(parseInt(this.start_time.substr(2, 2)))
            start_date = this.datePipe.transform(start_date, "yyyy-MM-ddTHH:mm:ss")

            end_date = this.end_date.setHours(parseInt(this.end_time.substr(0, 2)))
            end_date = this.end_date.setMinutes(parseInt(this.end_time.substr(2, 2)))
            end_date = this.datePipe.transform(end_date, "yyyy-MM-ddTHH:mm:ss")

            let other = this.selected_medication.other ? this.selected_medication : "";

            this.openMessage("Por favor, aguarde", "", true)
            this.PatientMedicationService.newPatientMedication(start_date, parseInt(this.selected_medication.id),
                parseInt(this.patientMedication.patient), this.patientMedication.quantity_per_day, this.week, other, end_date).subscribe( data => {
                
                this.modalRef2.hide()
                this.openMessage("Adicionado!", "Medicamento adicionado com sucesso.", false)
                this.action.next('update');
                this.modalRef.hide()
            }, error => {
                this.modalRef2.hide()
                this.error(error, 'adicionar')
            })
        }
    }

    error(error, string){
        var msg = ""
        if(error.error != null && error.error.error != null && error.error.error.length < 50)
            msg = error.error.error;
        else
            msg = "Por favor verifique as informações inseridas"
        this.openMessage(`Erro, não foi possível ${string} o medicamento.`, msg, false)
    }

    openMessage(title, text, bgclick: boolean){
        this.modalRef2 = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }

}
