import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  constructor(private http: HttpClient) {}

  getForms(company_id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path +
        `core/form/filter-admin/${
          company_id ? "?company_id=" + company_id : ""
        }`,
      options
    );
  }

  getScoreAnswers(company_id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path +
        `core/score-answers/all/admin/${
          company_id ? "?company_id=" + company_id : ""
        }`,
      options
    );
  }

  updateFormTitles(body, form_id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/form/update-titles/${form_id}/`,
      body,
      options
    );
  }

  getFormsModel(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + `core/form-model/all/`, options);
  }

  deleteFormModel(id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.delete<any>(
      AppConfig.path + `core/form-model/delete/${id}/`,
      options
    );
  }

  getScoreAnswersModel(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + `core/score-answers/model/all/`, options);
  }

  deleteScoreAnswersModel(id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.delete<any>(
      AppConfig.path + `core/score-answers/model/remove/${id}/`,
      options
    );
  }

  makeModel(id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/form-model/copy-form/${id}/`,
      {},
      options
    );
  }

  makeModelScoreAnswer(id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/score-answers/model/copy/${id}/`,
      {},
      options
    );
  }

  getDiagnosis(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + `core/diagnosis/all/`, options);
  }

  addDiagnostic(alternativeId, diagnosis): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/diagnosis/alternative/add/`,
      { alternativeId, diagnosis },
      options
    );
  }

  createIndicator(body): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/diagnosis/indicator/add/`,
      body,
      options
    );
  }

  createNoc(body): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/diagnosis/noc/add/`,
      body,
      options
    );
  }

  createNic(body): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/diagnosis/nic/add/`,
      body,
      options
    );
  }

  createNicNoc(body, isNic) {
    return isNic ? this.createNic(body) : this.createNoc(body);
  }

  createClass(body): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/diagnosis/class/add/`,
      body,
      options
    );
  }

  createDomain(body): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/diagnosis/domain/add/`,
      body,
      options
    );
  }

  createDiagnostic(body): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/diagnosis/create/`,
      body,
      options
    );
  }

  updateDiagnostic(body, id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.patch<any>(
      AppConfig.path + `core/diagnosis/update/${id}/`,
      body,
      options
    );
  }

  removeDiagnosis(id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.delete<any>(
      AppConfig.path + `core/diagnosis/delete/${id}/`,
      options
    );
  }

  getById(id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/diagnosis/id/${id}/`,
      options
    );
  }

  getAllIndicators(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/diagnosis/indicator/all/`,
      options
    );
  }
  getAllNocs(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/diagnosis/noc/all/`,
      options
    );
  }

  getAllNics(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/diagnosis/nic/all/`,
      options
    );
  }

  getAllClasses(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/diagnosis/class/all/`,
      options
    );
  }

  getAllDomains(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/diagnosis/domain/all/`,
      options
    );
  }

  getAllCompanies(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + `core/company/all/`, options);
  }
  getAllCompaniesMembers(companies): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/company/members/?companies=${companies}`,
      options
    );
  }

  sendNotificationToUsers(body): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/company/send-notification/`,
      body,
      options
    );
  }

  updateCompanyPermissions(body, id) {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };
    return this.http.patch<any>(
      AppConfig.path + `core/company-settings/update-permissions/${id}/`,
      body,
      options
    );
  }
}
