export class AppConfig {

	public static get path(): string { 
        // return "http://medicalangel-api-develop-env.eba-mkyutuwn.us-east-1.elasticbeanstalk.com/"
        // return "http://localhost:8000/"
        // return "http://192.168.1.5:8000/";
        return "https://api.medicalangel.com.br/";
    }

}
 