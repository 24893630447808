import { Component, OnInit } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Md5 } from "md5-typescript";
import { ModalChoiceComponent } from "src/app/modals/modal-choice/modal-choice.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { AdminService } from "src/app/service/admin.service";
import { CompanyService } from "src/app/service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";

@Component({
  selector: "app-notifications-admin",
  templateUrl: "./notifications-admin.component.html",
  styleUrls: ["./notifications-admin.component.css"],
})
export class NotificationsAdminComponent implements OnInit {
  modalRef: MDBModalRef;
  loading: boolean = false;
  companiesFull = [];
  companies = [];
  selected_companies = null;

  doctorsFull = [];
  doctors = [];
  selected_doctors = null;

  patientsFull = [];
  patients = [];
  selected_patients = null;

  title: string;
  message: string;

  loadingSending: boolean = false;
  constructor(
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.getCompanies();
  }

  add() {
    if (this.loadingSending) return;
    if (!this.title || !this.title.trim().length) {
      this.openMessage(
        `Erro, não foi possível enviar a notificação.`,
        "Por favor, preencha o título.",
        false
      );
      return;
    }
    if (!this.message || !this.message.trim().length) {
      this.openMessage(
        `Erro, não foi possível enviar a notificação.`,
        "Por favor, preencha a mensagem.",
        false
      );
      return;
    }
    this.loadingSending = true;
    let ids = [];
    if (this.selected_doctors && this.selected_doctors.length)
      ids = ids.concat(this.selected_doctors);
    if (this.selected_patients && this.selected_patients.length)
      ids = ids.concat(this.selected_patients);
    
    const body = {
      ids,
      title: this.title,
      message: this.message,
    };
    this.adminService.sendNotificationToUsers(body).subscribe(
      (data) => {
        this.openMessage(
          "Sucesso",
          `Notificação enviada com sucesso para ${ids.length} usuários`,
          false
        );
        this.loadingSending = false;
        this.title = '';
        this.message = ''
      },
      (error) => {
        this.loadingSending = false;
        this.error(error, "mensagem");
      }
    );

    // this.modalRef = this.modalService.show(ModalDiagnosisomponent, {
    //   backdrop: true,
    //   keyboard: true,
    //   focus: true,
    //   show: true,
    //   ignoreBackdropClick: true,
    //   class: "modal-dialog-centered modal-dialog modal-xl modal-size modal-xl",
    //   containerClass: "",
    //   animated: false,
    //   data: {
    //     title: (!diagnosis ? 'Cadastrar ' : "Editar ") + 'diagnóstico',
    //     diagnosis
    //   },
    // });
    // this.modalRef.content.action.subscribe((result: any) => {
    //   if (result) {
    //     this.getDiagnosis();
    //   }
    // });
  }

  findMembers(event) {
    if (event || !this.selected_companies || !this.selected_companies.length)
      return;
    this.loading = true;
    this.adminService
      .getAllCompaniesMembers(this.selected_companies)
      .subscribe((data) => {
        this.doctorsFull = [...data.doctors];
        this.patientsFull = [...data.patients];
        this.selectSearchDoctor("");
        this.selectSearchPatient("");
        this.loading = false;
      });
  }

  getCompanies() {
    this.loading = true;
    this.adminService.getAllCompanies().subscribe((data) => {
      this.companiesFull = [...data];
      this.selectSearchCompany("");
      this.loading = false;
    });
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível ${string} a mensagem.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  selectSearchCompany(value: string) {
    this.companies = [];
    let filter = value.toLowerCase();
    for (let i = 0; i < this.companiesFull.length; i++) {
      let option = this.companiesFull[i];
      if (option.name.toLowerCase().indexOf(filter) >= 0) {
        this.companies.push(option);
      }
    }
  }
  selectSearchDoctor(value: string) {
    this.doctors = [];
    let filter = value.toLowerCase();
    for (let i = 0; i < this.doctorsFull.length; i++) {
      let option = this.doctorsFull[i];
      if (option.name.toLowerCase().indexOf(filter) >= 0) {
        this.doctors.push(option);
      }
    }
  }

  accentFold(inStr) {
    return inStr.replace(
      /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g, 
      function (str, a, c, e, i, n, o, s, u, y, ae) {
        if (a) return 'a';
        if (c) return 'c';
        if (e) return 'e';
        if (i) return 'i';
        if (n) return 'n';
        if (o) return 'o';
        if (s) return 's';
        if (u) return 'u';
        if (y) return 'y';
        if (ae) return 'ae';
      }
    );
  }


  selectSearchPatient(value: string) {
    this.patients = [];
    let filter = this.accentFold(value.toLowerCase());
    for (let i = 0; i < this.patientsFull.length; i++) {
      let option = this.patientsFull[i];
      if (this.accentFold(option.name.toLowerCase()).indexOf(filter) >= 0) {
        this.patients.push(option);
      }
    }
  }

  selectAllCompanies() {
    if (
      this.selected_companies &&
      this.selected_companies.length === this.companies.length
    ) {
      this.selected_companies = [];
    } else {
      this.selected_companies = this.companies.map((c) => c.id);
    }
  }

  selectAllDoctors() {
    if (
      this.selected_doctors &&
      this.selected_doctors.length === this.doctors.length
    ) {
      this.selected_doctors = [];
    } else {
      this.selected_doctors = this.doctors.map((c) => c.id);
    }
  }

  selectAllPatients() {
    if (
      this.selected_patients &&
      this.selected_patients.length === this.patients.length
    ) {
      this.selected_patients = [];
    } else {
      this.selected_patients = this.patients.map((c) => c.id);
    }
  }
}
