import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { DoctorService } from "../../service/doctor.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import { Router } from "@angular/router";
import { UserService } from "src/app/service/user.service";

@Component({
  selector: "modal-doctor-vinculate",
  templateUrl: "./modal-doctor-vinculate.component.html",
  styleUrls: ["./modal-doctor-vinculate.component.css"],
})
export class ModalDoctorVinculateComponent {
  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private doctorService: DoctorService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private router: Router,
    private userService: UserService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";
  btn_text: string;
  is_new: boolean = true;

  doctor_clone;

  email = null;
  companyId = null;
  validator = new ValidatorUtils();
  loading = false;

  ngOnInit() {
    this.companyId = parseInt(localStorage.getItem("company"));
  }

  checkDoctor() {
    if (this.validator.isEmptyOrNull(this.email)) {
      this.openMessage("Por favor,", "insira o e-mail do médico.", false);
      return false;
    } else {
      this.send();
    }
  }

  send() {
    this.loading = true;
    this.doctorService.vinculateDoctor(this.email, this.companyId).subscribe(
      (data) => {
        this.modalRef.hide();
        this.openMessage("Criado!", "Convite enviado com sucesso!.", false);
        this.action.next("update");
        this.loading = false;
      },
      (error) => {
        this.modalRef.hide();
        this.error(error, "criar");
        this.loading = false;
      }
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} o médico.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
