import { DatePipe } from "@angular/common";
import { Component, Input, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalAddExamComponent } from "src/app/modals/modal-add-exam/modal-add-exam.component";
import { UserService } from "src/app/service/user.service";

@Component({
  selector: "app-exames",
  templateUrl: "./exames.component.html",
  styleUrls: ["./exames.component.css"],
})
export class ExamesComponent {
  constructor(
    private userService: UserService,
    private datePipe: DatePipe,
    private router: Router,
    private modalService: MDBModalService
  ) {}

  modalRef: MDBModalRef;

  @Input() patient_id: number;
  @Input() history: boolean;

  is_loaded: boolean = false;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  checkbox_message: boolean = true;
  checkbox_pressure: boolean = true;
  checkbox_glucose: boolean = true;
  checkbox_temperature: boolean = true;
  checkbox_heartbeat: boolean = true;
  checkbox_oxygenation: boolean = true;

  loadingTable: boolean = true;
  displayedColumns: string[] = [
    "date_time",
    "title",
    "observation",
    "type_exam_obj",
    "document",
  ];
  dataSource = [];

  selected_columns = [
    "date_time",
    "title",
    "observation",
    "type_exam_obj",
    "document",
  ];

  aux_info = {};
  keys = [
    "date_time",
    "title",
    "observation",
    "type_exam_obj",
    "file",
    "image",
  ];

  ngOnInit() {
    if (localStorage.getItem("token") == null) {
      this.router.navigate([""]);
    } else {
      this.range.setValue({ start: new Date(), end: new Date() });
      this.getExams(1);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.patient_id = changes["patient_id"].currentValue;
    this.getExams(1);
  }

  tamTable() {
    var styles = {};
    var list = this.selected_columns;

    var conta = list.length * 180 + 100;
    var tamanho = conta.toString() + "px";

    styles["min-width"] = tamanho;
    return styles;
  }

  clear() {
    this.dataSource = [];
    this.aux_info = {};
  }

  async getExams(page) {
    this.loadingTable = true;
    this.is_loaded = false;

    if (page == 1) this.clear();

    let date = this.datePipe.transform(this.range.value.start, "yyyy-MM-dd");
    let dateEnd = this.datePipe.transform(this.range.value.end, "yyyy-MM-dd");
    let count = 0;

    if (date && dateEnd) {
      this.userService
        .getExams(page, dateEnd, date, this.patient_id)
        .subscribe((data) => {
          data.forEach((element) => {
            const key_time = this.datePipe.transform(
              new Date(element.date_time),
              "dd/MM/yyyy HH:mm"
            );

            if (this.aux_info[key_time] == null) {
              this.aux_info[key_time] = {
                blood_pressure_1: [],
                blood_pressure_2: [],
                glucose: [],
                temperature: [],
                heartbeat: [],
                oxygenation: [],
                message: [],
                date_time: "",
                title: [],
                observation: [],
                type_exam_obj: [],
                file: [],
                image: [],
              };
            }

            this.keys.forEach((key) => {
              if (key == "date_time") {
                this.aux_info[key_time][key] = new Date(element[key]);
              } else if (
                element[key] != null &&
                element[key] != "" &&
                !this.aux_info[key_time][key].includes(element[key])
              ) {
                this.aux_info[key_time][key].push(element[key]);
              }
            });

            count++;
            if (count == data.length) {
              if (data.next == null) this.finish();
              else {
                this.getExams(page + 1);
              }
            }
          });

          if (data.length <= 0) {
            this.is_loaded = true;
            this.loadingTable = false;
          }
        });
    }
  }

  finish() {
    for (var key in this.aux_info) {
      this.dataSource.push(this.aux_info[key]);
    }

    this.dataSource.sort(
      (a, b) => a.date_time.getTime() - b.date_time.getTime()
    );

    this.is_loaded = true;
    this.loadingTable = false;
    //console.log(this.dataSource);
  }

  openAddExam() {
    this.modalRef = this.modalService.show(ModalAddExamComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: "Adicionar exame",
        btn_text: "Adicionar",
        is_new: true,
        patient_id: this.patient_id,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getExams(1);
      }
    });
  }
}
