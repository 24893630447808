import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { UserService } from "src/app/service/user.service";
import { ModalMessageComponent } from "../modal-message/modal-message.component";

@Component({
  selector: "modal-recovery",
  templateUrl: "./modal-recovery.component.html",
  styleUrls: ["./modal-recovery.component.css"],
})
export class ModalRecoveryComponent {
  constructor(
    public modalRef: MDBModalRef,
    public userService: UserService,
    private modalService: MDBModalService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;
  cpf: string;
  loading: boolean;
  ngOnInit() {}

  recovery() {
    if (!this.cpf || !this.cpf.length) {
      this.openMessage("Erro", "Por favor insira um CPF.", false);
      return;
    }
    this.loading = true;

    this.userService.recoveryPassword({ cpf: this.cpf }).subscribe(
      (data) => {
        this.loading = false;
        this.openMessage(
          "Salvo!",
          "As instruções de recuperação de senha foram enviadas para o e-mail de cadastro.",
          false
        );
        this.modalRef.hide();
      },
      (err) => {
        this.loading = false;
        this.error(err, "editar");
        this.modalRef2.hide();
      }
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else if (error.error && error.error.detail) msg = error.error.detail;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível recuperar a senha.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "modal-lg",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
