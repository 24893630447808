import { DatePipe } from "@angular/common";
import { Component, ViewChild } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { DoctorService } from "../../service/doctor.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";

const formatDate = (date: string) => {
  if (!date) {
    return "Data não informada";
  }
  const d = new Date(date + "+00:00");
  const datestring =
    ("0" + d.getDate()).slice(-2) +
    "/" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "/" +
    d.getFullYear() +
    " " +
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2);

  return datestring;
};



@Component({
  selector: "modal-doctor-checkin",
  templateUrl: "./modal-doctor-checkin.component.html",
  styleUrls: ["./modal-doctor-checkin.component.css"],
})
export class ModalDoctorCheckinComponent {
  constructor(
    public modalRef: MDBModalRef,
    private router: Router,
    private modalService: MDBModalService,
    private doctorService: DoctorService,
    private datePipe: DatePipe
  ) {}
  locationMaxDist = JSON.parse(localStorage.getItem('company_settings'))?.check_in_out_max_distance_in_meters ?? 1000
  data_source = new MatTableDataSource<any>();

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";

  doctor_id: number = 0;
  doctor_name: string = "";
  is_loaded: boolean = true;

  confirm_password: string;

  validator = new ValidatorUtils();

  associations = [];
  specialties = [];
  settings = JSON.parse(localStorage.getItem("company_settings"));
  displayedColumns: string[] = [
    "checkin_data",
    "checkin_location",
    "checkout_data",
    "checkout_location",
  ];

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngOnInit() {
    this.title = `${this.title} / ${this.doctor_name}`;
    
    if (this.settings.company.company_type !== "ILPI")
      this.displayedColumns.push("patient");

    this.is_loaded = true;
    this.data_source.data = [];
    this.doctorService.getTimeControl(this.doctor_id).subscribe((data) => {
      this.data_source.data = data.map((time) => {
        time.checkin_data = formatDate(time.checkin_data);
        time.checkout_date = formatDate(time.checkout_date);
        const has_patient_address =
          time.patient &&
          time.patient.street &&
          time.patient.number &&
          time.patient.neighborhood &&
          time.patient.city &&
          time.patient.state &&
          time.patient.cep;
        const has_company_address =
          this.settings.company.street &&
          this.settings.company.number &&
          this.settings.company.neighborhood &&
          this.settings.company.city &&
          this.settings.company.state;

        if (time.checkin_latitude !== 0 && time.checkin_longitude !== 0) {
          if (has_patient_address) {
            const patient_address = `${time.patient.street},${time.patient.number}-${time.patient.neighborhood},${time.patient.city}-${time.patient.state},${time.patient.cep}`;

            time.checkin_location_link = `https://www.google.com.br/maps/dir/${time.checkin_latitude},${time.checkin_longitude}/${patient_address}`;
          } else if (has_company_address) {
            const company_address = `${this.settings.company.street},${this.settings.company.number}-${this.settings.company.neighborhood},${this.settings.company.city}-${this.settings.company.state}`;
            time.checkin_location_link = `https://www.google.com.br/maps/dir/${time.checkin_latitude},${time.checkin_longitude}/${company_address}`;
          } else {
            time.checkin_location_link = `https://www.google.com.br/maps/place/${time.checkin_latitude},${time.checkin_longitude}`;
          }
          time.checkin_latitude = Math.trunc(time.checkin_latitude);
          time.checkin_longitude = Math.trunc(time.checkin_longitude);
          time.checkin_location = `${time.checkin_latitude}/${time.checkin_longitude}`;
        } else {
          time.checkin_location_link = ``;
          time.checkin_location = `indefinido`;
        }

        if (time.checkout_latitude !== 0 && time.checkout_longitude !== 0) {
          if (has_patient_address) {
            const patient_address = `${time.patient.street},${time.patient.number}-${time.patient.neighborhood},${time.patient.city}-${time.patient.state},${time.patient.cep}`;
            time.checkout_location_link = `https://www.google.com.br/maps/dir/${time.checkout_latitude},${time.checkout_longitude}/${patient_address}`;
          } else if (has_company_address) {
            const company_address = `${this.settings.company.street},${this.settings.company.number}-${this.settings.company.neighborhood},${this.settings.company.city}-${this.settings.company.state}`;
            time.checkout_location_link = `https://www.google.com.br/maps/dir/${time.checkout_latitude},${time.checkout_longitude}/${company_address}`;
          } else {
            time.checkout_location_link = `https://www.google.com.br/maps/place/${time.checkout_latitude},${time.checkout_longitude}`;
          }
          time.checkout_latitude = Math.trunc(time.checkout_latitude);
          time.checkout_longitude = Math.trunc(time.checkout_longitude);
          time.checkout_location = `${time.checkout_latitude}/${time.checkout_longitude}`;
        } else {
          time.checkout_location_link = ``;
          time.checkout_location = `indefinido`;
        }

        time.patient_label = "";
        if (time.patient && time.patient.name) {
          time.patient_label = `${time.patient.id} - ${time.patient.name}`;
        }

        return time;
      });
      this.data_source.sort = this.sort;
      this.is_loaded = false;
    });
  }

  openPatient(id) {
    this.modalRef.hide();
    this.router.navigate([`/home/patient/${id}`]);
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} o médico.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  getDist(distance) {
    if(distance) {
      return ` (${distance}m)`
    } 
    return ''

  }
}
