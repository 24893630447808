import { Component, OnInit } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Md5 } from "md5-typescript";
import { Subject } from "rxjs";
import { ModalChoiceComponent } from "src/app/modals/modal-choice/modal-choice.component";
import { ModalFormCreateQuestionComponent } from "src/app/modals/modal-form-create-question/modal-form-create-question.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { AdminService } from "src/app/service/admin.service";
import { CompanyService } from "src/app/service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { ModalFormPermissionsDiagnosisInformationComponent } from "../modal-form-permissions-diagnosis-information/modal-form-permissions-diagnosis-information.component";

@Component({
  selector: "app-modal-form-permissions-diagnosis-add",
  templateUrl: "./modal-form-permissions-diagnosis-add.component.html",
  styleUrls: ["./modal-form-permissions-diagnosis-add.component.css"],
})
export class ModalFormPermissionsDiagnosisAddComponent implements OnInit {
  modalRef2: MDBModalRef;
  loading: boolean = false;

  action: Subject<any> = new Subject();

  title: string = "";
  btn_text: string;
  alternative: any;
  diagnosis: any[] = [];
  titles = ["code", "name", "domain", "class", "edit"];
  selected_diagnosis: any[] = [];
  selected_diagnosis_in_prod: any[] = [];
  constructor(
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private adminService: AdminService,
    public modalRef: MDBModalRef
  ) {}

  ngOnInit(): void {
    this.selected_diagnosis_in_prod = [...this.selected_diagnosis];
    this.loadDiagnosis();
  }

  loadDiagnosis() {
    this.loading = true;
    this.adminService.getDiagnosis().subscribe((data) => {
      this.diagnosis = data;
      this.loading = false;
      
      
    });
  }

  save() {
    const json = {};
    this.adminService.createDiagnostic(json).subscribe(
      (data) => {
        this.openMessage("Sucesso", "Diagnóstico criado", false);
        this.action.next(true);
        this.modalRef.hide();
      },
      (error) => {
        this.error(error, "criar");
      }
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível ${string} o diagnóstico.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  add() {
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text:
          !this.selected_diagnosis_in_prod || this.selected_diagnosis_in_prod.length === 0
            ? `Tem certeza que deseja deseja a pergunta sem nenhum diagnóstico?`
            : `Tem certeza que deseja vincular os diagnóstico no formulário?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      
      if (result == "yes") {
        this.adminService
          .addDiagnostic(this.alternative.id, this.selected_diagnosis_in_prod.map(d => d.id))
          .subscribe(
            (data) => {
              this.selected_diagnosis = this.selected_diagnosis_in_prod
              this.openMessage("Sucesso", "Diagnóstico atualizado", false);
              this.action.next(true);
            },
            (error) => {
              this.error(error, "inserir");
            }
          );
      }
    });
  }

  info(diagnosis) {
    this.modalRef2 = this.modalService.show(
      ModalFormPermissionsDiagnosisInformationComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: true,
        ignoreBackdropClick: true,
        class:
          "modal-dialog-centered modal-dialog modal-xl modal-size modal-xl",
        containerClass: "",
        animated: false,
        data: {
          diagnosis,
        },
      }
    );
  }

  removeSelected(id) {
    this.selected_diagnosis_in_prod.splice(id, 1);
  }

  insert(dia){
    this.selected_diagnosis_in_prod.push(dia);
  }
}
