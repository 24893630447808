import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { CompanyService } from "../../service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "modal-form-fill",
  templateUrl: "./modal-form-fill.component.html",
  styleUrls: ["./modal-form-fill.component.css"],
})
export class ModalFormFillComponent {
  url: string = "https://www.mmlpqtpkasjdashdjahd.com";
  urlSafe: SafeResourceUrl;
  constructor(public modalRef: MDBModalRef, public sanitizer: DomSanitizer) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";

  form_clone;
  is_new: boolean;

  form = {
    id: null,
    title: null,
    link: null,
    created_at: null,
    updated_at: null,
    company_id: null,
    link_sheet: null,
  };

  validator = new ValidatorUtils();

  ngOnInit() {
    if (this.form_clone != null && !this.is_new) {
      for (var key in this.form) {
        this.form[key] = this.form_clone[key];
      }
    }
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.form.link + "?embedded=true"
    );
  }
}
