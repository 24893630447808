import { Component, OnInit } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Md5 } from "md5-typescript";
import { Subject } from "rxjs";
import { ModalChoiceComponent } from "src/app/modals/modal-choice/modal-choice.component";
import { ModalFormCreateQuestionComponent } from "src/app/modals/modal-form-create-question/modal-form-create-question.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { AdminService } from "src/app/service/admin.service";
import { CompanyService } from "src/app/service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";

@Component({
  selector: "app-modal-diagnosis-domain",
  templateUrl: "./modal-diagnosis-domain.component.html",
  styleUrls: ["./modal-diagnosis-domain.component.css"],
})
export class ModalDiagnosisDomainComponent implements OnInit {
  modalRef2: MDBModalRef;
  loading: boolean = false;

  action: Subject<any> = new Subject();

  title: string = "";

  validator = new ValidatorUtils();
  name: string = "";
  constructor(
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private adminService: AdminService,
    public modalRef: MDBModalRef
  ) {}

  ngOnInit(): void {
  }


  save() {
    if (this.validator.isEmptyOrNull(this.name)) {
      this.openMessage("Por favor,", "informe o nome do domínio.", false);
      return false;
    }


    const json = {
      name: this.name,
    };

    this.adminService.createDomain(json).subscribe(
      (data) => {
        this.openMessage("Sucesso", "Domínio cadastrado", false);
        this.action.next(true);
        this.modalRef.hide();
      },
      (error) => {
        this.error(error, "cadastrar");
      }
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível ${string} o domínio.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

}
