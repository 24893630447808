import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { UserService } from "src/app/service/user.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";

@Component({
  selector: "modal-add-record",
  templateUrl: "./modal-add-record.component.html",
  styleUrls: ["./modal-add-record.component.css"],
})
export class ModalAddRecordComponent {
  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private userService: UserService,
    private companyService: CompanyService,
    private datePipe: DatePipe
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;
  patient_id;
  title: string = "";
  btn_text: string;
  is_new: boolean = true;

  dateRecord: string = "";
  hourRecord: string = "";
  observation: string = "";

  dia: string;
  sis: string;
  bpm: string;
  temp: string;
  glucose: string;
  saturation: string;
  weight: string;
  respiratoryFrequency: string;
  validator = new ValidatorUtils();

  ngOnInit() {
  }

  getPatientForm() {
    const aux = this.dateRecord.split('/');
    const time = `${aux[2]}-${aux[1]}-${aux[0]}T${this.hourRecord}:00`;
    let body = {
        type_data: 'Manual',
        receipt_time: time,
        message: this.observation,
        heartbeat: this.bpm,
        blood_pressure_1: this.sis,
        blood_pressure_2: this.dia,
        temperature: this.temp,
        oxygenation: this.saturation,
        glucose: this.glucose,
        weight: this.weight,
        respiratoryFrequency: this.respiratoryFrequency
      };
      if(body.message == null || body.message.length === 0) {
        delete body.message
      }
      if(body.heartbeat == null || body.heartbeat.length === 0) {
        delete body.heartbeat
      }
      if(body.blood_pressure_1 == null || body.blood_pressure_1.length === 0) {
        delete body.blood_pressure_1
      }
      if(body.blood_pressure_2 == null || body.blood_pressure_2.length === 0) {
        delete body.blood_pressure_2
      }
      if(body.temperature == null || body.temperature.length === 0) {
        delete body.temperature
      }
      if(body.oxygenation == null || body.oxygenation.length === 0) {
        delete body.oxygenation
      }
      if(body.glucose == null || body.glucose.length === 0) {
        delete body.glucose
      }
      if(body.weight == null || body.weight.length === 0) {
        delete body.weight
      }
      if(body.respiratoryFrequency == null || body.respiratoryFrequency.length === 0) {
        delete body.respiratoryFrequency
      }
    return body;
  }

  insertRecord() {
    this.openMessage("Por favor, aguarde", "", true);
    var body = this.getPatientForm();
    this.userService.createRecord(body, this.patient_id).subscribe(
      (data) => {
        this.modalRef2.hide();
        this.openMessage(
          "Sucesso!",
          "Registro criado",
          false
        );
        this.action.next("update");
        this.modalRef.hide();
      },
      (error) => {
        this.modalRef2.hide();
        this.error(error, "criar");
      }
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi adicionar a medição.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "modal-lg",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  createRecord() {
    if (this.validator.isEmptyOrNull(this.dateRecord)) {
      this.openMessage("Por favor,", "insira a data da medição.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.hourRecord)) {
      this.openMessage("Por favor,", "insira a hora da medição.", false);
      return false;
    } else {
      var day = Number(this.dateRecord.substring(0, 2));
      var month = Number(this.dateRecord.substring(3, 5));
      var year  = Number(this.dateRecord.substring(6, 10));

      if (day > 31 || day < 1 || month > 12 || month < 1 || year  < 1900) {
        this.openMessage(
          "Por favor,",
          "insira uma data de medição válida",
          false
        );
        return false;
      }
      var hora = Number(this.hourRecord.substring(0, 2));
      var minuto = Number(this.hourRecord.substring(3, 5));
      if (hora >= 24 || hora < 0 || minuto > 59 || minuto < 0) {
        this.openMessage(
          "Por favor,",
          "insira uma hora de medição válida",
          false
        );
        return false;
      }
    }
    this.insertRecord()
  }
}
