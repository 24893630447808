import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { UserService } from "src/app/service/user.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import { DoctorService } from "src/app/service/doctor.service";

@Component({
  selector: "modal-patient-responsable-detail",
  templateUrl: "./modal-patient-responsable-detail.component.html",
  styleUrls: ["./modal-patient-responsable-detail.component.css"],
})
export class ModalPatientResponsableDetailComponent {
  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private userService: UserService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private doctorService: DoctorService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";
  btn_text: string;
  is_new: boolean = true;

  responsable_clone;
  patient_id;
  responsable = {
    id: null,
    name: null,
    phone: null,
    cell_phone: null,
    is_sms_notification: false,
    is_email_notification: false,
    is_whatsapp_notification: false,
    is_whatsapp: false,
    email: null,
    kindred: null,
    cpf: null,
  };

  validator = new ValidatorUtils();

  ngOnInit() {
    if (this.responsable_clone != null && !this.is_new) {
      for (var key in this.responsable) {
        this.responsable[key] = this.responsable_clone[key];
      }
    }
  }

  checkResponsable() {
    if (this.validator.isEmptyOrNull(this.responsable.name)) {
      this.openMessage("Por favor,", "insira o nome do responsável.", false);
      return false;
    }

    if (!this.responsable.kindred || !this.responsable.kindred.length) {
      this.openMessage(
        "Por favor,",
        "Você deve informar o grau de parentesco.",
        false
      );
      return;
    }
    if (
      this.responsable.phone &&
      this.responsable.phone.length &&
      this.responsable.phone.length < 14
    ) {
      this.openMessage(
        "Por favor,",
        "Informe um número de telefone válido.",
        false
      );
      return;
    }
    if (
      this.responsable.cell_phone &&
      this.responsable.cell_phone.length &&
      this.responsable.cell_phone.length < 14
    ) {
      this.openMessage(
        "Por favor,",
        "Informe um número de celular válido.",
        false
      );
      return;
    }
    if (
      this.responsable.is_whatsapp &&
      (!this.responsable.cell_phone || !this.responsable.cell_phone.length)
    ) {
      this.openMessage(
        "Por favor,",
        "Para escolher a opção de número ter Whatsapp, você deve informar o telefone celular.",
        false
      );
      return;
    }

    // if (
    //   !this.responsable.is_email_notification &&
    //   !this.responsable.is_sms_notification &&
    //   !this.responsable.is_whatsapp_notification
    // ) {
    //   this.openMessage(
    //     "Por favor,",
    //     "Você deve escolher pelo menos uma forma de notificação!",
    //     false
    //   );
    //   return;
    // }

    if (
      this.responsable.is_email_notification &&
      (!this.responsable.email || !this.responsable.email.length)
    ) {
      this.openMessage(
        "Por favor,",
        "Para receber notificações por e-mail, você informar qual é o e-mail!",
        false
      );
      return;
    }

    if (
      this.responsable.is_sms_notification &&
      (!this.responsable.cell_phone || !this.responsable.cell_phone.length)
    ) {
      this.openMessage(
        "Por favor,",
        "Para receber notificações por SMS, você informar qual é o número do celular!",
        false
      );
      return;
    }

    if (
      this.responsable.is_whatsapp_notification &&
      !this.responsable.is_whatsapp
    ) {
      this.openMessage(
        "Por favor,",
        "Para receber notificações por Whatsapp, você deve selecionar que o telefone celular é Whatsapp!",
        false
      );
      return;
    }

    if (
      this.responsable.is_whatsapp_notification &&
      (!this.responsable.cell_phone || !this.responsable.cell_phone.length)
    ) {
      this.openMessage(
        "Por favor,",
        "Para receber notificações por Whatsapp, você informar qual é o número do celular!",
        false
      );
      return;
    }

    if (this.is_new) this.createResponsible();
    else this.editResponsable();
  }

  createResponsible() {
    this.openMessage("Por favor, aguarde", "", true);

    this.userService.createResponsable(this.patient_id, this.responsable).subscribe(
      (data) => {
        this.modalRef2.hide();
        this.openMessage(
          "Criado com sucesso!",
          "Responsável adicionado com sucesso",
          // "Faça a validação do usuário que foi enviada por e-mail.",
          false
        );
        this.action.next("update");
        this.modalRef.hide();
      },
      (error) => {
        //console.log(error.error);
        if (error && error.error) {
          if (error.error.email) {
            this.modalRef2.hide();
            const msg = error.error.email[0].replace("patient", "Paciente");
            this.errorBack(msg, "criar");
          } else if (error.error.company) {
            this.modalRef2.hide();
            this.errorBack(
              "Informe a empresa que o paciente está vinculado",
              "criar"
            );
          } else if (error.error.cpf) {
            const msg = error.error.cpf[0].replace("user", "Usuário");
            this.modalRef2.hide();
            this.errorBack(msg, "criar");
          } else {
            this.error(error, "criar");
          }
        } else {
          this.error(error, "criar");
        }

        this.modalRef2.hide();
      }
    );
  }

  editResponsable() {
    this.openMessage("Por favor, aguarde", "", true);

    this.userService
      .editResponsable(this.responsable.id, this.responsable)
      .subscribe(
        (data) => {
          this.modalRef2.hide();
          this.openMessage("Salvo!", "Responsável editado com sucesso.", false);
          this.action.next("update");
          this.modalRef.hide();
        },
        (error) => {
          this.modalRef2.hide();
          this.error(error, "editar");
        }
      );
  }

  errorBack(error, string) {
    var msg = error;

    this.openMessage(
      `Erro, não foi possível ${string} o paciente.`,
      msg,
      false
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível ${string} o responsável do paciente.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "modal-lg",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
