import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { UserService } from "src/app/service/user.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import { MedicationService } from "src/app/service/medication.service";

const ENDDATE = `01/01/2300`;
@Component({
  selector: "modal-add-medication",
  templateUrl: "./modal-add-medication.component.html",
  styleUrls: ["./modal-add-medication.component.css"],
})
export class ModalAddMedicationComponent {
  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private medicationService: MedicationService,
    private companyService: CompanyService,
    private datePipe: DatePipe
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;
  patient_id;
  title: string = "";
  btn_text: string;
  is_new: boolean = true;

  dateInit: string;
  dateEnd: string;
  name: string;
  hour: string;
  observation: string;
  hours: string[];
  dosage: string;
  validator = new ValidatorUtils();

  checkbox_seg: boolean = true;
  checkbox_ter: boolean = true;
  checkbox_qua: boolean = true;
  checkbox_qui: boolean = true;
  checkbox_sex: boolean = true;
  checkbox_sab: boolean = true;
  checkbox_dom: boolean = true;

  selected_columns = ["seg", "ter", "qua", "qui", "sex", "sab", "dom"];

  ngOnInit() {
    //console.log(this.patient_id);
  }

  insertMedication() {
    //console.log('entramos')
    this.openMessage("Por favor, aguarde", "", true);
    // var body = this.getPatientForm();

    const days = [];
    const daysLess = ["seg", "ter", "qua", "qui", "sex", "sab", "dom"];
    for (let i = 0; i < 7; i++) {
      if (this.selected_columns.includes(daysLess[i])) {
        days.push(i);
      }
    }
    const init = this.dateInit.split("/");
    const end = this.dateEnd ? this.dateEnd.split("/") : ENDDATE.split("/");
    const body = {
      name: this.name,
      start_time: `${init[2]}-${init[1]}-${init[0]}T00:00:00`,
      end_time: this.dateEnd ? `${end[2]}-${end[1]}-${end[0]}T00:00:00` : null,
      is_continuous: this.dateEnd ? false : true,
      observation: this.observation,
      dosage: this.dosage,
      patient: this.patient_id,
      times: this.hours,
      days,
    };

    this.medicationService.insertMedicationPatient(body).subscribe(
      (res) => {
        if (!res || !res.id) {
          this.modalRef2.hide();
          this.error({ error: null }, "criar");
          return;
        }

        const schedule = [];

        this.medicationService
          .insertSchedule(schedule, this.patient_id)
          .subscribe(
            (ans) => {
              this.modalRef2.hide();
              this.openMessage("Sucesso!", "Medicamento criado", false);
              this.action.next("update");
              this.modalRef.hide();
            },
            (err) => {
              this.modalRef2.hide();
              this.error(err, "criar");
            }
          );
      },
      (err) => {
        this.modalRef2.hide();
        this.error(err, "criar");
      }
    );

    // let res = await requestAdapter.get(
    //   '/core/medication/filter/?q=&other=True',
    // );
    // if (!res.data || !res.data.length) {
    //   throw new Error();
    // }
    // this.userService.createRecord(body, this.patient_id).subscribe(
    //   (data) => {
    //     this.modalRef2.hide();
    //     this.openMessage("Sucesso!", "Medicamento cadastrado", false);
    //     this.action.next("update");
    //     this.modalRef.hide();
    //   },
    //   (error) => {
    //     this.modalRef2.hide();
    //     this.error(error, "criar");
    //   }
    // );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi adicionar a medição.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "modal-lg",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  validateDate(date: string) {
    if (!date || date.length !== 10) {
      this.openMessage(
        "Por favor,",
        "insira uma data de medição válida",
        false
      );
      return false;
    }
    var day = Number(date.substring(0, 2));
    var month = Number(date.substring(3, 5));
    var year = Number(date.substring(6, 10));

    if (day > 31 || day < 1 || month > 12 || month < 1 || year < 1900) {
      this.openMessage(
        "Por favor,",
        "insira uma data de medição válida",
        false
      );
      return false;
    }
    return true;
  }

  createMedication() {
    if (this.validator.isEmptyOrNull(this.dateInit)) {
      this.openMessage(
        "Por favor,",
        "Informe a data de início do medicamento.",
        false
      );
      return false;
    } else if (this.validator.isEmptyOrNull(this.name)) {
      this.openMessage("Por favor,", "Informe o nome do medicamento.", false);
      return false;
    }
    if (!this.hours || !this.hours.length) {
      this.openMessage(
        "Por favor,",
        "Informe pelo menos um horário para tomar o medicamento.",
        false
      );
      return false;
    } else {
      if (
        !this.validateDate(this.dateInit) ||
        (this.dateEnd && !this.validateDate(this.dateEnd))
      ) {
        return false;
      }
    }
    if (!this.selected_columns || this.selected_columns.length === 0) {
      this.openMessage(
        "Por favor,",
        "Informe pelo menos um dia da semana que irá tomar o medicamento.",
        false
      );
      return false;
    }

    this.insertMedication();
  }

  validateHour(hour) {
    if (!hour || hour.length !== 5) {
      this.openMessage("Por favor,", "insira uma hora válida", false);
      return false;
    }
    var hora = Number(hour.substring(0, 2));
    var minuto = Number(hour.substring(3, 5));
    if (hora >= 24 || hora < 0 || minuto > 59 || minuto < 0) {
      this.openMessage("Por favor,", "insira uma hora válida", false);
      return false;
    }
    return true;
  }

  addNewHour() {
    //console.log(this.hour);
    if (!this.hours) {
      this.hours = [];
    }
    if (this.validateHour(this.hour)) {
      this.hours.push(this.hour);
      this.hour = "";
    }
  }

  deleteHour(index) {
    this.hours.splice(index, 1);
  }
}
