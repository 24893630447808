import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class PatientMedicationService {

    constructor(private http : HttpClient) { }

    getPatientMedication() : Observable<any>{
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
        }

        return this.http.get<any>(AppConfig.path + "core/patient-medication/", options)
    }

    getPatientMedicationId(id) : Observable<any>{
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
        }

        return this.http.get<any>(AppConfig.path + `core/patient-medication/${id}/`, options)
    }

    getSpecificPatientId(id) : Observable<any>{
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
          
        };  

        return this.http.get<any>(AppConfig.path + `core/medication-patient/all/patient/${id}/`, options)
    }

    newPatientMedication(start_time, medication, patient, quantity_per_day, week, other, end_time) : Observable<any> {
        let body = new HttpParams()
            .append('start_time', start_time)
            .append('end_time', end_time)
            .append('medication', medication)
            .append('patient', patient)
            .append('quantity_per_day', quantity_per_day)

        for(var key in week){
            if(week[key]){
                body = body.append(key, week[key])
            }
        }

        if(other != null && other != ""){
            body = body.append('other_medicament', other)
        }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + "core/patient-medication/", body.toString(), { headers })
    }

    editPatientMedication(id, sunday) : Observable<any> {
        let body = new HttpParams()
            .append('sunday', sunday)
            
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/patient-medication/${id}/`, body.toString(), { headers })
    }

    deletePatientMedication(id) : Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
        }

        return this.http.delete<any>(AppConfig.path + `core/patient-medication/${id}/`, options)
    }

}