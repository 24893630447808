import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../service/user.service';
import { Md5 } from "md5-typescript";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {
    try {
      this.route.queryParams.subscribe(params => {
        this.cpf = params.cpf;
        this.hash = params.hash;
      });
    } catch {
      this.router.navigate([''])
    }
  }

  hash: string = "";
  cpf: string = "";
  password: string = "";
  confirm_password: string = "";
  text_button: string = "ENVIAR";
  error_msg: string = "";
  loading: boolean = false;

  ngOnInit(): void {
    
  }

  forgotPassword() {
    this.error_msg = "";

    if (this.text_button == 'ENVIANDO' || this.text_button == "SENHA REDEFINIDA COM SUCESSO!")
      return
    else if (this.text_button == "ENVIAR") {

      if (this.password == null || this.password == '') {
        this.error_msg = "Insira a senha"
      }
      else if (this.confirm_password == null || this.confirm_password == '') {
        this.error_msg = "Confirme a senha"
      }
      else if (this.password != this.confirm_password) {
        this.error_msg = "As senhas devem ser iguais"
      }
      else {

        this.text_button = "ENVIANDO";
        this.loading = true;
        var passwordMD5 = Md5.init(this.password).toUpperCase()

        this.userService.changeForgottenPassword(passwordMD5, this.cpf, this.hash).subscribe( data => {
          this.text_button = "SENHA REDEFINIDA COM SUCESSO!";
          this.password = "";
          this.confirm_password = "";
          this.cpf = "";
          this.hash = "";
          this.loading = false;
        }, error => {
          this.loading = false;
          this.text_button = "ENVIAR"
          this.error_msg = "Não foi possível redefinir sua senha"
        })

      }

    }
    
  }

}
