import { Component, ViewChild } from "@angular/core";
import { DashboardService } from "../service/dashboard.service";
import { Chart } from "chart.js";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DatePipe } from "@angular/common";
import { CompanyService } from "../service/company.service";
import { FormControl, FormGroup } from "@angular/forms";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent {
  constructor(
    private dashboardService: DashboardService,
    private datePipe: DatePipe,
    private companyService: CompanyService
  ) {}

  loadingPatients = false;
  patientsData: any = [];
  patientGenderChart: any;
  patientAgeChart: any;

  loadingDoctors = false;
  doctorsData: any = [];
  doctorGenderChart: any;
  doctorSpecialityChart: any;

  loadingForms = false;
  formsData: any = [];
  filterPatient = true;
  is_loaded: boolean = false;

  day: Date = new Date();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatSort, { static: false }) sortDoctor: MatSort;

  forms: any[] = [];
  selected_form: any;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  answers: any[] = [];
  chartAnswers: {};
  company_type = localStorage.getItem("companyType");
  loadingClinic = false;
  clinic_data:any = {}
  

  clinicGenderChart: any;
  clinicAgeChart: any;

  clinicProcedureChart: any;
  clinicServicesChart: any;

  ngOnInit() {
    if (this.company_type === "CLINIC") {

      this.range.setValue({ end: new Date(), start: new Date(new Date().setDate(new Date().getDate() - 7))});
      this.loadClinic();
    } else {
      this.loadPatient();
      this.loadDoctor();
      // this.loadForms();
      this.getForms();
      this.range.setValue({ start: new Date(), end: new Date() });
    }

    
  }

  loadClinic() {
    if (
      !this.datePipe.transform(this.range.value.start, "yyyy-MM-dd") ||
      !this.datePipe.transform(this.range.value.end, "yyyy-MM-dd")
    )
      return;

    this.loadingClinic = true;
    let start = this.datePipe.transform(this.range.value.start, "yyyy-MM-dd") + " 00:00:00";
    let end = this.datePipe.transform(this.range.value.end, "yyyy-MM-dd") + " 23:59:59";
    this.dashboardService.getDataForClinic(start, end).subscribe((data) => {
      // console.log(data);
      this.clinic_data = data;

      let label = ["Masculino", "Feminino", "Outro", "Não informado"];
      let values = [0, 0, 0, 0];
      for (const data in this.clinic_data.gender) {
        if (data === "MALE" || data === "MASCULINO")
          values[0]++;
        else if (data === "FEMALE" || data === "FEMININO")
          values[1]++;
        else if (data === "OTHER") values[2]++;
        else values[3]++;
      }

      let colors = ["blue", "red", "orange", "green"];

      if (this.clinicGenderChart) {
        this.clinicGenderChart.destroy();
      }
      this.clinicGenderChart = this.createPieChart(
        "clinicGender",
        label,
        values,
        colors
      );



      const aux = [];
      for (const auxAge in this.clinic_data.ages) {
        aux.push({"total": this.clinic_data.ages[auxAge], age: auxAge});
      }

      aux.sort((a, b) => a.age - b.age);

      this.createChartLine(
        this.clinicAgeChart,
        "clinicAge",
        aux.map((aux) => aux.age),
        aux.map((aux) => aux.total),
        colors
      );


      var dynamicColors = function () {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
      };


      if (this.clinicProcedureChart) {
        this.clinicProcedureChart.destroy();
      }

      label = []
      values = []
      colors = []

      for(const p in this.clinic_data.procedures) {
        label.push(p)
        values.push(this.clinic_data.procedures[p])
        colors.push(dynamicColors())
      }
      this.clinicProcedureChart = this.createPieChart(
        "clinicProcedure",
        label,
        values,
        colors
      );

      label = []
      values = []
      colors = []

      for(const p in this.clinic_data.services) {
        label.push(p)
        values.push(this.clinic_data.services[p])
        colors.push(dynamicColors())
      }
      this.clinicServicesChart = this.createPieChart(
        "clinicService",
        label,
        values,
        colors
      );


      this.loadingClinic = false;
    })
  }

  loadPatient() {
    this.loadingPatients = true;
    this.dashboardService
      .getDataForPatients(this.filterPatient)
      .subscribe((data) => {
        this.patientsData = data;

        const label = ["Masculino", "Feminino", "Outro", "Não informado"];
        const values = [0, 0, 0, 0];
        for (const data of this.patientsData.patients) {
          if (data.gender === "MALE" || data.gender === "MASCULINO")
            values[0]++;
          else if (data.gender === "FEMALE" || data.gender === "FEMININO")
            values[1]++;
          else if (data.gender === "OTHER") values[2]++;
          else values[3]++;
        }

        const colors = ["blue", "red", "orange", "green"];

        if (this.patientGenderChart) {
          this.patientGenderChart.destroy();
        }
        this.patientGenderChart = this.createChart(
          "patientGender",
          label,
          values,
          colors
        );

        const auxAges = {};
        for (const data of this.patientsData.patients) {
          if (!auxAges[data.age])
            auxAges[data.age] = { total: 0, age: data.age };
          auxAges[data.age].total++;
        }

        const aux = [];
        for (const auxAge in auxAges) {
          aux.push(auxAges[auxAge]);
        }

        aux.sort((a, b) => a.age - b.age);

        this.createChartLine(
          this.patientAgeChart,
          "patientAge",
          aux.map((aux) => aux.age),
          aux.map((aux) => aux.total),
          colors
        );

        this.loadingPatients = false;
      });
  }

  loadDoctor() {
    this.loadingDoctors = true;
    this.dashboardService.getDataForDoctors().subscribe((data) => {
      this.doctorsData = data;

      var dynamicColors = function () {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
      };

      const auxLData = {};
      for (const data of this.doctorsData.doctors) {
        if (!auxLData[data.specialty])
          auxLData[data.specialty] = { total: 0, specialty: data.specialty };
        auxLData[data.specialty].total++;
      }

      const dataFinal = [];
      for (const i in auxLData) dataFinal.push(auxLData[i]);

      let colors = [];
      for (let i = 0; i < dataFinal.length; i++) colors.push(dynamicColors());
      if (this.doctorSpecialityChart) {
        this.doctorSpecialityChart.destroy();
      }
      this.doctorSpecialityChart = this.createChart(
        "doctorSpeciality",
        dataFinal.map((d) => d.specialty),
        dataFinal.map((d) => d.total),
        colors
      );

      const label = ["Masculino", "Feminino", "Outro", "Não informado"];
      const values = [0, 0, 0, 0];
      for (const data of this.doctorsData.doctors) {
        if (data.gender === "MALE" || data.gender === "MASCULINO") values[0]++;
        else if (data.gender === "FEMALE" || data.gender === "FEMININO")
          values[1]++;
        else if (data.gender === "OTHER") values[2]++;
        else values[3]++;
      }

      colors = ["blue", "red", "orange", "green"];
      if (this.doctorGenderChart) {
        this.doctorGenderChart.destroy();
      }
      this.doctorGenderChart = this.createChart(
        "doctorGender",
        label,
        values,
        colors
      );

      // const auxAges = {};
      // for (const data of this.doctorsData.doctors) {
      //   if (!auxAges[data.age]) auxAges[data.age] = { total: 0, age: data.age };
      //   auxAges[data.age].total++;
      // }

      // const aux = [];
      // for (const auxAge in auxAges) {
      //   aux.push(auxAges[auxAge]);
      // }

      // aux.sort((a, b) => a.age - b.age);

      // this.createChartLine(
      //   this.doctorGenderChart,
      //   "doctorGender",
      //   aux.map((aux) => aux.age),
      //   aux.map((aux) => aux.total),
      //   colors
      // );
      this.loadingDoctors = false;
    });
  }

  loadForms() {
    let start = this.datePipe.transform(this.day, "yyyy-MM-dd") + " 00:00:00";
    let end = this.datePipe.transform(this.day, "yyyy-MM-dd") + " 23:59:59";
    this.loadingForms = true;
    this.dashboardService.getDataForCorePlan(start, end).subscribe((data) => {
      this.formsData = data;
      this.formsData.data_source = new MatTableDataSource<any>([]);
      this.formsData.data_source.data = data.formsToday;
      this.formsData.data_source.sort = this.sort;

      this.formsData.data_source_doctors = new MatTableDataSource<any>([]);
      this.formsData.data_source_doctors.data = data.formsTodayDoctor;
      this.formsData.data_source_doctors.sort = this.sort;

      // console.log(data);

      this.loadingForms = false;
    });
  }

  createPieChart(id, labels, data, backgroundColor) {
    return new Chart(id, {
      type: "pie",
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor,
          },
        ],
      },
      // legend: {
      //   display: true,
      // },
      options: {
        responsive: true,
        legend: {
          display: true,
        },
        // scales: {
        //   yAxes: [
        //     {
        //       ticks: {
        //         beginAtZero: true,
        //         callback: function (value) {
        //           if (value % 1 === 0) {
        //             return value;
        //           }
        //         },
        //       },
        //     },
        //   ],
        // },
        plugins: {
          legend: {
            display: true,
          },
          title: {
            display: true,
            text: "Chart.js Pie Chart",
          },
        },
      },
    });
  }

  createChart(id, labels, data, backgroundColor) {
    return new Chart(id, {
      type: "bar",
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor,
          },
        ],
      },
      legend: {
        display: false,
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
              },
            },
          ],
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: "Chart.js Pie Chart",
          },
        },
      },
    });
  }

  createChartLine(chart, id, labels, data, backgroundColor) {
    chart = new Chart(id, {
      type: "bar",
      data: {
        labels,
        datasets: [
          {
            data,
            borderColor: "blue",
            borderWidth: 2,
            hoverBackgroundColor: "blue",
            hoverBorderColor: "blue",
            fill: false,
            showLine: true,
          },
        ],
      },
      legend: {
        display: false,
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
              },
            },
          ],
        },
      },
    });
  }

  changeFilter(filter) {
    this.filterPatient = filter;
    this.loadPatient();
  }

  getStyle(cur) {
    if (this.filterPatient === cur) return "bold";
    return "normal";
  }

  getPercent(value, total) {
    if (!total) {
      return 0;
    }
    return Math.round((value * 100.0) / total);
  }

  getFilled(element) {
    return element.core_plan.filter((aux) => aux.filled === aux.totalForms)
      .length;
  }

  getTotalForms(element) {
    return element.core_plan.reduce(
      (acc, element) => acc + element.totalForms,
      0
    );
  }

  getForms() {
    this.is_loaded = false;
    this.companyService.getFormsDashboard().subscribe((data) => {
      this.forms = data;
      this.is_loaded = true;
    });
  }

  dynamicColors() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
  }
  async getAnswers() {
    let date =
      this.datePipe.transform(this.range.value.start, "yyyy-MM-dd") +
      " 00:00:00";
    let dateEnd =
      this.datePipe.transform(this.range.value.end, "yyyy-MM-dd") + " 23:59:59";
    if (
      !date ||
      !dateEnd ||
      !this.datePipe.transform(this.range.value.start, "yyyy-MM-dd") ||
      !this.datePipe.transform(this.range.value.end, "yyyy-MM-dd") ||
      !this.selected_form
    )
      return;
    this.is_loaded = false;
    this.dashboardService
      .getDataForFormsAnswer(date, dateEnd, this.selected_form)
      .subscribe(async (data) => {
        const questionsById = {};
        for (const q of data) {
          if (!questionsById[q.form_question.id]) {
            questionsById[q.form_question.id] = {
              title: q.form_question.title,
              id: q.form_question.id,
              answers: [],
              is_date: q.form_question.is_date,
              is_frequency: q.form_question.is_frequency,
              is_image: q.form_question.is_image,
              is_list: q.form_question.is_list,

              is_numeric: q.form_question.is_numeric,
              is_numeric_scale: q.form_question.is_numeric_scale,
              is_scale: q.form_question.is_scale,
              is_selectable: q.form_question.is_selectable,
            };
          }
          questionsById[q.form_question.id].answers.push(q.answer);
        }

        this.answers = Object.values(questionsById);
        this.chartAnswers = {};
        await new Promise((resolve) => setTimeout(resolve, 1000));
        for (const a of this.answers) {
          if (
            a.is_selectable ||
            a.is_numeric ||
            a.is_numeric_scale ||
            a.is_frequency ||
            a.is_list
          ) {
            const label = [...new Set(a.answers)];
            const values = [];
            const colors = [];
            for (const x of label) {
              values.push(a.answers.filter((a) => a === x).length);
              colors.push(this.dynamicColors());
            }

            if (this.chartAnswers[a.id]) {
              this.chartAnswers[a.id].destroy();
            }

            this.chartAnswers[a.id] = this.createChart(
              "answer-" + a.id,
              label,
              values,
              colors
            );
          }
        }

        this.is_loaded = true;
      });
  }
}
