import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { CompanyService } from "../../service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalFormCreateQuestionComponent } from "../modal-form-create-question/modal-form-create-question.component";
import { ModalChoiceComponent } from "../modal-choice/modal-choice.component";
import { ModalFormPermissionsComponent } from "../modal-form-permissions/modal-form-permissions.component";

@Component({
  selector: "modal-core-plan-close",
  templateUrl: "./modal-core-plan-close.component.html",
  styleUrls: ["./modal-core-plan-close.component.css"],
})
export class ModalCorePlanCloseComponent {
  url: string = "https://www.mmlpqtpkasjdashdjahd.com";
  urlSafe: SafeResourceUrl;
  constructor(
    public modalRef: MDBModalRef,
    public sanitizer: DomSanitizer,
    private modalService: MDBModalService,
    private companyService: CompanyService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";
  loading: boolean = false;
  reason: string;
  core_plan_patient: any;

  isEmpty(str) {
    if (!str || !str.length) {
      return true;
    }
    return false;
  }

  submit() {
    if (this.isEmpty(this.reason)) {
      this.openMessage(
        "Erro",
        "Você precisa informar o motivo do fechamento do plano de cuidado"
      );
      return;
    }

    const form = {
      reason: this.reason,
      id: this.core_plan_patient.id,
    };

    this.loading = true;

    this.companyService.closeCorePlanPatient(form).subscribe(
      (data) => {
        this.openMessage("Sucesso", "Plano de cuidado fechado com suceso!");
        this.action.next(true);
        this.modalRef.hide();
      },
      (error) => {
        let msg = "Ocorreu um erro, tente novamente";
        if (
          error.response &&
          error.response.data &&
          error.response.data.non_field_errors &&
          error.response.data.non_field_errors.length > 0
        ) {
          msg = error.response.data.non_field_errors[0];
        }
        this.openMessage("Erro", msg);

        this.loading = false;
      }
    );
  }

  openMessage(title, text, bgclick: boolean = false) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
