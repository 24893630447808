import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { UserService } from "src/app/service/user.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import { CalendarService } from "src/app/service/calendar.service";
import { ServiceService } from "src/app/service/service.service";

@Component({
  selector: "modal-select-service",
  templateUrl: "./modal-select-service.component.html",
  styleUrls: ["./modal-select-service.component.css"],
})
export class ModalSelectServiceTypeComponent {
  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private userService: UserService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private serviceService: ServiceService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;
  
  serviceType: string;
  validator = new ValidatorUtils();
  loadingService: boolean = false;
  servicesType: any[] = [];
  allServices: any[] = []
  ngOnInit() {
    this.serviceTypes();
  
  }


  serviceTypes() {
    this.loadingService = true;
    this.serviceService.getServiceTypes().subscribe(
      (data) => {
        
        this.servicesType = data;
        this.allServices = data
        this.loadingService = false;
      },
      (error) => {}
    );
  }

  accentFold(inStr) {
    return inStr.replace(
      /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
      function (str, a, c, e, i, n, o, s, u, y, ae) {
        if (a) return "a";
        if (c) return "c";
        if (e) return "e";
        if (i) return "i";
        if (n) return "n";
        if (o) return "o";
        if (s) return "s";
        if (u) return "u";
        if (y) return "y";
        if (ae) return "ae";
      }
    );
  }

  selectSearchPatient(value: string) {
    this.servicesType = [];
    let filter = this.accentFold(value.toLowerCase());
    for (let i = 0; i < this.allServices.length; i++) {
      let option = this.allServices[i];
      if (this.accentFold(option.name.toLowerCase()).indexOf(filter) >= 0) {
        this.servicesType.push(option);
      }
    }
  }

  insertRecord() {
    this.action.next(this.serviceType);
    this.modalRef.hide();
    
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi adicionar o registro.`, msg, false);
  }
 
  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "modal-lg",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  createRecord() {
    if (this.validator.isEmptyOrNull(this.serviceType)) {
      this.openMessage("Por favor,", "escolha o tipo de atendimento.", false);
      return false;
    }
    this.insertRecord();
  }
}
