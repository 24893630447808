import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../service/user.service";
import { Md5 } from "md5-typescript";
import { InformationsService } from "../service/informations.service";
import { ValidatorUtils } from "../utils/validator.utils";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { DoctorService } from "../service/doctor.service";

@Component({
  selector: "app-vinculate_doctor",
  templateUrl: "./vinculate_doctor.component.html",
  styleUrls: ["./vinculate_doctor.component.css"],
})
export class VinculateDoctorComponent implements OnInit {
  constructor(
    private userService: UserService,
    private router: Router,
    private informationsService: InformationsService,
    private modalService: MDBModalService,
    private doctorService: DoctorService,

    private route: ActivatedRoute
  ) {}

  loading = false;
  modalRef2: MDBModalRef;

  ngOnInit(): void {
    const doctor = this.route.snapshot.queryParamMap.get('doctor');
    const companyId = this.route.snapshot.queryParamMap.get('companyId');
    this.resolve(doctor, companyId)
  }

  resolve(doctor, companyId) {
    this.doctorService.vinculateDoctorConfirmation(doctor, companyId).subscribe(
      (data) => {
        this.openMessage("Sucesso!", "Vínculo atribuído com sucesso!.", false);
        this.router.navigate(["/"]);
      },
      (error) => {
        const detail = error?.error?.detail ?? "Erro ao se vincular na companhia"
        this.openMessage("Erro", detail, false);
        this.router.navigate(["/"]);
      }
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title,
        text,
      },
    });
  }
}
