import { DatePipe } from "@angular/common";
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChildren,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Chart } from "chart.js";
import { Subject } from "rxjs";
import { ModalFillFormComponent } from "src/app/modals/modal-fill-form/modal-fill-form.component";
import { ModalFormAnswerListComponent } from "src/app/modals/modal-form-answer-list/modal-form-answer-list.component";
import { ModalFormAnswerComponent } from "src/app/modals/modal-form-answer/modal-form-answer.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { ModalSelectFormComponent } from "src/app/modals/modal-select-form/modal-select-form.component";
import { CompanyService } from "src/app/service/company.service";
import { MedicationService } from "src/app/service/medication.service";
import { UserService } from "src/app/service/user.service";

@Component({
  selector: "app-formularios-all",
  templateUrl: "./formularios-all.component.html",
  styleUrls: ["./formularios-all.component.css"],
})
export class FormulariosAllComponent {
  constructor(
    private companyService: CompanyService,
    private medicationService: MedicationService,
    private modalService: MDBModalService,
    private datePipe: DatePipe,
    private userService: UserService
  ) {}

  action: Subject<any> = new Subject();
  msg_error: string = "";
  loading: boolean = false;
  loadingRecords: boolean = false;
  loadingDownload: boolean = false;
  modalRef: MDBModalRef;
  title: string = "";
  btn_text: string;
  is_new: boolean = true;
  @Input() patient_id: number;

  selectedForm = null;
  fieldsChecked = {};
  allForms = [];
  selectedMedication;
  listAnswer = [];
  tamlist: number = 0;
  medication;
  formatedForms = null;
  formTitles = null;
  dataSourcesTable = [];

  scoreScalesByFormId: any = {};

  ngOnInit(): void {
    this.getForms(this.patient_id);
  }

  getForms(patient) {
    this.loading = true;
    this.companyService.getFormsFromCompany(patient).subscribe((data) => {
      const aux = data.map((d) => {
        return {
          ...d,
          name: d.title,
          value: d.id,
        };
      });
      const auxSelect = ["sinal", ...data.map((d) => d.id)];
      const auxFields = { sinal: true };
      for (const d of data) auxFields[d.id] = true;
      this.allForms = aux;
      this.selectedForm = auxSelect;
      this.fieldsChecked = auxFields;
      this.loading = false;
    });
  }

  formatData(data) {
    if (data !== "Pergunta") {
      return data.split("-").reverse().join("/");
    }
    return data;
  }

  openAnswer(data) {
    this.companyService.getAnswerFormPatientByFormId(data.id).subscribe(
      (dataAnswers) => {
        //console.log("aaaaa");
        //console.log(dataAnswers);
        this.modalRef = this.modalService.show(ModalFormAnswerComponent, {
          backdrop: true,
          keyboard: true,
          focus: true,
          show: true,
          width: "100%",
          ignoreBackdropClick: true,
          class: "modal-dialog-centered modal-dialog modal-xl",
          StyleSheet: { width: "100% !important" },
          containerClass: "modal-answer",
          animated: false,
          data: {
            title: `Respostas do Formulário ${data.form.title}`,
            form_id: data.id,
            form_title: data.form.title,
            answers: dataAnswers,
          },
        });
      },
      (error) => {
        //console.log(error);
      }
    );
  }

  openAddAnswer() {
    
    const form = this.allForms.find((f) => f.value === this.selectedForm);
    if(!form) {
      this.openMessage("Erro", "Selecione um formulário para visualizar", true);
      return;
    }
    this.modalRef = this.modalService.show(ModalFormAnswerListComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-xl",
      containerClass: "",
      animated: false,
      data: {
        title: "Respostas do Formulário",
        form_id: form.id,
        form_title: form.title,
        form_questions: form.questions,
        form: form,
        from_patient: true, 
        patient_id: this.patient_id
      },
    });
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
