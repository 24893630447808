import { DatePipe } from "@angular/common";
import { Component, Input, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalAddRecordComponent } from "src/app/modals/modal-add-record/modal-add-record.component";
import { UserService } from "src/app/service/user.service";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

@Component({
  selector: "app-sinais-vitais",
  templateUrl: "./sinais-vitais.component.html",
  styleUrls: ["./sinais-vitais.component.css"],
})
export class SinaisVitaisComponent {
  filterDayInitial = null;

  constructor(
    private userService: UserService,
    private datePipe: DatePipe,
    private router: Router,

    private modalService: MDBModalService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      //console.log(params); // { order: "popular" }
      if (params.day) {
        this.filterDayInitial = params.day;
      }
    });
  }

  @Input() patient_id: number;
  @Input() history: boolean;

  is_loaded: boolean = false;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  checkbox_message: boolean = true;
  checkbox_pressure: boolean = true;
  checkbox_glucose: boolean = true;
  checkbox_temperature: boolean = true;
  checkbox_heartbeat: boolean = true;
  checkbox_oxygenation: boolean = true;
  checkbox_createdBy: boolean = true;
  checkbox_respiratoryFrequency: boolean = true;

  loadingTable: boolean = true;
  displayedColumns: string[] = [
    "time",
    "pas",
    "pad",
    "glucose",
    "temperature",
    "heart_rate",
    "saturation",
    "respiratoryFrequency",
    "createdBy",
    "comments",
  ];
  dataSource = [];

  selected_columns = [
    "pressure",
    "glucose",
    "temperature",
    "heartbeat",
    "oxygenation",
    "message",
    "createdBy",
    "hour",
    "respiratoryFrequency",
  ];

  aux_info = {};
  keys = [
    "blood_pressure_1",
    "blood_pressure_2",
    "glucose",
    "temperature",
    "heartbeat",
    "oxygenation",
    "message",
    "receipt_time",
    "respiratoryFrequency",
    "createdBy",
  ];

  modalRef: MDBModalRef;

  ngOnInit() {
    if (localStorage.getItem("token") == null) {
      this.router.navigate([""]);
    } else {
      if (this.filterDayInitial) {
        this.range.setValue({
          start: new Date(this.filterDayInitial),
          end: new Date(this.filterDayInitial),
        });
      } else {
        this.range.setValue({ start: new Date(), end: new Date() });
      }

      this.getPatientRecord(1);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.patient_id = changes["patient_id"].currentValue;
    this.getPatientRecord(1);
  }

  tamTable() {
    var styles = {};
    var list = this.selected_columns;

    var conta = list.length * 180 + 100;
    var tamanho = conta.toString() + "px";

    styles["min-width"] = tamanho;
    return styles;
  }

  clear() {
    this.dataSource = [];
    this.aux_info = {};
  }

  async getPatientRecord(page) {
    this.loadingTable = true;
    this.is_loaded = false;

    if (page == 1) this.clear();

    let date = this.datePipe.transform(this.range.value.start, "yyyy-MM-dd");
    let dateEnd = this.datePipe.transform(this.range.value.end, "yyyy-MM-dd");
    let count = 0;

    if (date && dateEnd) {
      this.userService
        .getPatientRecord(this.patient_id, page, date, dateEnd)
        .subscribe((data) => {
          data.forEach((element) => {
            const record = element.record;
            const notification = element.notification;
            const key_time = this.datePipe.transform(
              new Date(record.receipt_time),
              "dd/MM/yyyy HH:mm"
            );

            if (this.aux_info[key_time] == null) {
              this.aux_info[key_time] = {
                blood_pressure_1: [],
                blood_pressure_2: [],
                glucose: [],
                temperature: [],
                heartbeat: [],
                oxygenation: [],
                respiratoryFrequency: [],
                message: [],
                receipt_time: "",
                msg_notification: {
                  blood_pressure_1: "",
                  blood_pressure_2: "",
                  glucose: "",
                  temperature: "",
                  heartbeat: "",
                  oxygenation: "",
                  respiratoryFrequency: "",
                },
                has_notification: {
                  blood_pressure_1: [],
                  blood_pressure_2: [],
                  glucose: [],
                  temperature: [],
                  heartbeat: [],
                  oxygenation: [],
                  respiratoryFrequency: [],
                },
                createdBy: [],
              };
            }

            this.keys.forEach((key) => {
              if (key == "receipt_time") {
                this.aux_info[key_time][key] = new Date(record[key]);
              } else if (key === "createdBy") {
                if (element["doctor"]) {
                  this.aux_info[key_time][key].push(element.doctor.name);
                } else {
                  this.aux_info[key_time][key].push("Paciente");
                }
              } else if (record[key] != null && record[key] != "") {
                this.aux_info[key_time][key].push(record[key]);
              }
            });

            if (notification && notification.length > 0) {
              for (let i = 0; i < notification.length; i += 1) {
                const not = notification[i];
                this.aux_info[key_time].has_notification[
                  not.type_parameter
                ].push(not);
                if (
                  this.aux_info[key_time].msg_notification[
                    not.type_parameter
                  ] === ""
                ) {
                  this.aux_info[key_time].msg_notification[
                    not.type_parameter
                  ] += `
									Alertas Disparados
								`;
                } else {
                  this.aux_info[key_time].msg_notification[
                    not.type_parameter
                  ] += `
								`;
                }
                const not_date = this.datePipe.transform(
                  new Date(not.created_at),
                  "dd/MM/yyyy HH:mm"
                );
                this.aux_info[key_time].msg_notification[
                  not.type_parameter
                ] += `
								Data: ${not_date}
								Leitura: ${not.value}
								Minimo / Maximo: ${not.min} / ${not.max}
							`;
              }
            }

            count++;
            if (count == data.length) {
              if (data.next == null) this.finish();
              else {
                this.getPatientRecord(page + 1);
              }
            }
          });

          if (data.length <= 0) {
            this.is_loaded = true;
            this.loadingTable = false;
          }
        });
    }
  }

  finish() {
    for (var key in this.aux_info) {
      this.dataSource.push(this.aux_info[key]);
    }

    this.dataSource.sort((a, b) =>
      a.receipt_time.getTime() > b.receipt_time.getTime() ? -1 : 1
    );

    this.is_loaded = true;
    this.loadingTable = false;
  }

  openAddRecord() {
    this.modalRef = this.modalService.show(ModalAddRecordComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: "Adicionar medição",
        btn_text: "Adicionar",
        is_new: true,
        patient_id: this.patient_id,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getPatientRecord(1);
      }
    });
  }

  getColumnsSelected() {
    const data = [];
    if (this.checkbox_pressure) data.push("pressure");
    if (this.checkbox_glucose) data.push("glucose");
    if (this.checkbox_temperature) data.push("temperature");
    if (this.checkbox_heartbeat) data.push("heartbeat");
    if (this.checkbox_oxygenation) data.push("oxygenation");
    if (this.checkbox_respiratoryFrequency) data.push("respiratoryFrequency");
    if (this.checkbox_createdBy) data.push("createdBy");
    return data;
  }

  downloadTable() {
    // const doc = new jsPDF()
    // autoTable(doc, { html: '#tabela' })
    // doc.save('sinais-vitais.pdf')
    let date = this.datePipe.transform(this.range.value.start, "yyyy-MM-dd");
    let dateEnd = this.datePipe.transform(this.range.value.end, "yyyy-MM-dd");
    let count = 0;

    if (date && dateEnd) {
      this.userService
        .getPatientRecordPDF(this.patient_id, 1, date, dateEnd, this.getColumnsSelected())
        .subscribe(
          (data) => {
            console.log("dentroooo");
            const blob = new Blob([data], { type: "application/pdf" });

            var downloadURL = window.URL.createObjectURL(data);
            console.log(downloadURL);
            var link = document.createElement("a");
            link.href = downloadURL;
            link.download = "relatorio.pdf";
            link.click();
          },
          async (error) => {
            const data = error.error.text;
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(
              new Blob([data], { type: "application/pdf" })
            );

            // const contentDisposition = response.headers.get('content-disposition');
            // const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            downloadLink.download = "relatorio.pdf";
            downloadLink.click();
          }
        );
    }
  }

  downloadTableCSV() {
    // const doc = new jsPDF()
    // autoTable(doc, { html: '#tabela' })
    // doc.save('sinais-vitais.pdf')
    let date = this.datePipe.transform(this.range.value.start, "yyyy-MM-dd");
    let dateEnd = this.datePipe.transform(this.range.value.end, "yyyy-MM-dd");
    let count = 0;

    if (date && dateEnd) {
      this.userService
        .getPatientRecordCSV(this.patient_id, 1, date, dateEnd, this.getColumnsSelected())
        .subscribe(
          (data) => {
            const blob = new Blob([data], { type: "text/csv" });

            var downloadURL = window.URL.createObjectURL(data);
            console.log(downloadURL);
            var link = document.createElement("a");
            link.href = downloadURL;
            link.download = "relatorio.csv";
            link.click();
          },
          async (error) => {
            const data = error.error.text;
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(
              new Blob([data], { type: "text/csv" })
            );

            // const contentDisposition = response.headers.get('content-disposition');
            // const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            downloadLink.download = "relatorio.csv";
            downloadLink.click();
          }
        );
    }
  }
}
