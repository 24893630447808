import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AdminService } from "src/app/service/admin.service";

@Component({
  selector: "modal-form-model-detail",
  templateUrl: "./modal-form-model-detail.component.html",
  styleUrls: ["./modal-form-model-detail.component.css"],
})
export class ModalFormModelDetailComponent {
  data: any[] 

  titles = ["id", "title", 'edit'];
  
  constructor(
    public modalRef: MDBModalRef,
    public sanitizer: DomSanitizer,
    private modalService: MDBModalService,
    private adminService: AdminService
  ) {}

  loading: boolean = false;
  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;
  form: any = null;
  title: string = "";
  
  ngOnInit() {
    
  }

  getModels() {
   
  }
  

  submit() {
    this.modalRef.hide();
    this.action.next(this.form);
  }

}
