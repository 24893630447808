import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { CompanyService } from "../service/company.service";
import { MatSort } from "@angular/material/sort";
import { SofyaService } from "../service/sofya.service";

@Component({
  selector: "app-not-allowed",
  templateUrl: "./not-allowed.component.html",
  styleUrls: ["./not-allowed.component.css"],
})
export class NotAllowedComponent implements OnInit {
  loading: boolean = false;
  numberOfUseSofya: number;
  companiesUsers: []
  companiesDoctors: []
  loadingCompany: boolean = false;
  loadingDoctors: boolean = false;
  constructor(private sofyaService: SofyaService, private companyService: CompanyService
  ) {}

  company_type = localStorage.getItem('companyType')
  ngOnInit(): void {
    this.getUsers();
    this.getDoctors();
  }

  getUsers() {
    this.loadingCompany = true;
    this.companyService.getUsersFromAllCompanies().subscribe(data => {
      this.companiesUsers = data;
      this.loadingCompany = false;
    })    
  }

  getDoctors() {
    this.loadingDoctors = true;
    this.companyService.getDoctorsFromAllCompanies().subscribe(data => {
      this.companiesDoctors = data;
      this.loadingDoctors = false;
    })    
  }
}
