import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { CompanyService } from "../../service/company.service";
import { DoctorService } from "../../service/doctor.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalFormCreateQuestionComponent } from "../modal-form-create-question/modal-form-create-question.component";
import { ModalChoiceComponent } from "../modal-choice/modal-choice.component";

@Component({
  selector: "modal-form-permissions",
  templateUrl: "./modal-form-permissions.component.html",
  styleUrls: ["./modal-form-permissions.component.css"],
})
export class ModalFormPermissionsComponent {
  url: string = "https://www.mmlpqtpkasjdashdjahd.com";
  urlSafe: SafeResourceUrl;
  constructor(
    public modalRef: MDBModalRef,
    public sanitizer: DomSanitizer,
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private doctorService: DoctorService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";

  form_clone;
  is_new: boolean;
  loading: boolean = true;
  titleQuestion: string = "";
  activated: boolean = true;

  validator = new ValidatorUtils();
  doctors = [];
  selectedDoctors = [];
  access_to_patient = false;

  fileName = "";
  fileImage = null;
  file = null;
  captionTitle = "";
  questions = [];

  fileNameQuestion = {};
  fileImageQuestion = {};
  fileQuestion = {};
  captionTitleQuestion = {};

  ngOnInit() {
    for (const question of this.questions) {
      this.fileNameQuestion[question.id] = "";
      this.fileImageQuestion[question.id] = question.caption_image;
      this.fileQuestion[question.id] = null;
      this.captionTitleQuestion[question.id] = question.caption_text;
    }

    this.getDoctosByCompany();
  }

  isEmpty(str) {
    if (!str || !str.length) {
      return true;
    }
    return false;
  }

  submit() {
    // console.log(this.selectedDoctors);

    const questionsCaptions = [];
    for (const question of this.questions) {
      questionsCaptions.push({
        id: question.id,
        captionTitle: this.captionTitleQuestion[question.id],
        file: this.fileQuestion[question.id]
          ? this.fileQuestion[question.id]
          : this.fileImageQuestion[question.id]
          ? "issame"
          : this.fileImageQuestion[question.id],
      });
    }
    
    this.action.next({
      permissions: this.selectedDoctors,
      access_to_patient: this.access_to_patient,
      captionTitle: this.captionTitle,
      file: this.file ? this.file : this.fileImage ? "issame" : this.fileImage,
      questions: questionsCaptions
    });
    this.modalRef.hide();
  }

  openMessage(title, text, bgclick: boolean = false) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  getDoctosByCompany() {
    
    this.doctorService.getDoctors().subscribe(
      (data) => {
        this.doctors = data;
        this.loading = false;
      },
      (err) => {
        this.openMessage(
          "Erro",
          "Ocorreu um erro ao recuperar os profissionais vinculados a sua compania"
        );
        this.loading = false;
        this.modalRef.hide();
      }
    );
  }

  async onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      this.file = file;
      const reader = new FileReader();
      reader.onload = (e) => (this.fileImage = reader.result);

      reader.readAsDataURL(file);

      // const upload$ = this.http.post("/api/thumbnail-upload", formData);

      // upload$.subscribe();
    }
  }

  removeFile() {
    this.file = null;
    this.fileName = null;
    this.fileImage = null;
  }

  async onFileSelectedQuestion(event, question) {
    const file: File = event.target.files[0];
    if (file) {
      this.fileNameQuestion[question.id] = file.name;
      this.fileQuestion[question.id] = file;
      const reader = new FileReader();
      reader.onload = (e) =>
        (this.fileImageQuestion[question.id] = reader.result);

      reader.readAsDataURL(file);

      // const upload$ = this.http.post("/api/thumbnail-upload", formData);

      // upload$.subscribe();
    }
  }

  removeFileQuestion(question) {
    this.fileQuestion[question.id] = null;
    this.fileNameQuestion[question.id] = null;
    this.fileImageQuestion[question.id] = null;
  }

  canSeeDiagnosis() {
    const company_settings = localStorage.getItem('company_settings')
    return company_settings && JSON.parse(company_settings).access_to_diagnosis
  }
}
