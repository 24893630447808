import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { UserService } from "src/app/service/user.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import { CalendarService } from "src/app/service/calendar.service";
import { ProcedureService } from "src/app/service/procedure.service";
import { ServiceService } from "src/app/service/service.service";

@Component({
  selector: "modal-service-type-settings",
  templateUrl: "./modal-service-type-settings.component.html",
  styleUrls: ["./modal-service-type-settings.component.css"],
})
export class ModalServiceTypeSettingsComponent {
  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private userService: UserService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private serviceService: ServiceService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;
  title: string = "";
  btn_text: string;
  is_new: boolean = true;
  service: any;

  name: string;
  use_health_record: boolean = true;
  use_medication: boolean = true;
  use_exam: boolean = true;
  use_prescription: boolean = true;
  forms: any[];
  allForms: any[];
  validator = new ValidatorUtils();

  ngOnInit() {
    this.getForms();
    if (this.service) {
      this.startField(this.service);
    }
  }

  getForms() {
    this.companyService.getForms().subscribe((data) => {
      this.allForms = data;
    });
  }

  startField(ev) {
    this.name = ev.name;
    this.use_health_record = ev.use_health_record;
    this.use_medication = ev.use_medication;
    this.use_exam = ev.use_exam;
    this.use_prescription = ev.use_prescription;
    const f = [];
    for (const form of ev.forms) {
      f.push(form.id);
    }
    this.forms = f;
    // this.time = ev.time;
  }

  getForm() {
    return {
      name: this.name,
      use_health_record: this.use_health_record,
      use_medication: this.use_medication,
      use_exam: this.use_exam,
      use_prescription: this.use_prescription,
      forms: this.forms,
    };
  }

  insertRecord() {
    this.openMessage("Por favor, aguarde", "", true);
    var body = this.getForm();

    if (this.service) {
      this.serviceService.editServiceTypes(body, this.service.id).subscribe(
        (data) => {
          this.modalRef2.hide();
          this.openMessage("Sucesso!", "Tipo de atendimento editado", false);
          this.action.next(data);
          this.modalRef.hide();
        },
        (error) => {
          this.modalRef2.hide();
          this.error(error, "editar");
        }
      );
    } else {
      this.serviceService.createServiceTypes(body).subscribe(
        (data) => {
          this.modalRef2.hide();
          this.openMessage("Sucesso!", "Tipo de atendimento criado", false);
          this.action.next(data);
          this.modalRef.hide();
        },
        (error) => {
          this.modalRef2.hide();
          this.error(error, "criar");
        }
      );
    }
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi adicionar o tipo de atendimento.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "modal-lg",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  createRecord() {
    if (this.validator.isEmptyOrNull(this.name)) {
      this.openMessage(
        "Por favor,",
        "informe o nome do tipo de atendimento.",
        false
      );
      return false;
    }
    this.insertRecord();
  }
}
