import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SofyaService {
  constructor(private http: HttpClient) {}

  getUsage(date_from, date_to): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),

      params: new HttpParams()
      .set("page_size", "9999")
      .set("date_to", date_to)
      .set("date_from", date_from),
    };

    return this.http.get<any>(
      AppConfig.path +
        `core/sofya-data/get-number-usage/`,
      options
    );
  }

}
