import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormHomeService } from 'src/app/service/form-home.service';

@Component({
    selector: 'app-homecare',
    templateUrl: './homecare.component.html',
    styleUrls: ['./homecare.component.css']
})

export class HomecareComponent implements OnInit {

    @ViewChild("video", { static: false }) video;
    @ViewChild("register", { static: false }) register;

    constructor(
        private formHomeService: FormHomeService,
        private _snackBar: MatSnackBar
    ) { }

    loading: boolean = false;
    warningMap = new Map();

    name: string = '';
    email: string = '';
    phone: string = '';
    cnpj: string = '';
    message: string = '';

    error_message: string = '';
    
    ngOnInit(): void {
       
    }

    navigateTo(element: string) {
        this[element].nativeElement.scrollIntoView({ block: "start", behavior: "smooth" });
       
        if(element != 'top'){
            const yOffset = -135; 
            const position = document.getElementById(element);
            const y = position.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});  
        }
    }

    sendInfoForm() {
        this.formHomeService.sendForm('information', this.name, this.email, this.phone, null, null, this.cnpj, null, this.message).subscribe(data => {
            //console.log(data)

            this.name = '';
            this.email = '';
            this.phone = '';
            this.cnpj = '';
            this.message = '';

            this.loading = false;
            this.openSnackBar("Enviado!", "Fechar")
        },
            error => {
                this.loading = false;
                //console.log(error)
            })
    }

    sendForm() {
        this.error_message = ''
        this.warningMap = new Map();


        if (this.name == "" || this.name == null) {
            this.error_message = 'Por favor, insira o nome.'
        }
        else if (this.email == "" || this.email == null) {
            this.error_message = 'Por favor, insira o email.'
        }
        else if (this.phone == "" || this.phone == null) {
            this.error_message = 'Por favor, insira o telefone.'
        }
        else if (this.cnpj == "" || this.cnpj == null) {
            this.error_message = 'Por favor, insira o CNPJ.'
        }
        else if (this.message == "" || this.message == null) {
            this.error_message = 'Por favor, insira a mensagem.'
        }


        if (this.error_message == '' && !this.loading) {
            this.loading = true;
            this.sendInfoForm()
        }
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 4000,
        });
    }
    
}
