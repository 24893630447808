import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { CompanyService } from "../../service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalFormCreateQuestionComponent } from "../modal-form-create-question/modal-form-create-question.component";

@Component({
  selector: "modal-form-create-question-alternative",
  templateUrl: "./modal-form-create-question-alternative.component.html",
  styleUrls: ["./modal-form-create-question-alternative.component.css"],
})
export class ModalFormCreateQuestionAlternativeComponent {
  url: string = "https://www.mmlpqtpkasjdashdjahd.com";
  urlSafe: SafeResourceUrl;
  constructor(
    public modalRef3: MDBModalRef,
    public sanitizer: DomSanitizer,
    private modalService: MDBModalService
  ) {}

  action: Subject<any> = new Subject();
  modalRef4: MDBModalRef;

  title: string = "";

  form_clone;
  is_new: boolean;
  loading: boolean = false;
  titleQuestion: string;
  position: number;
  score: number;
  is_scale: boolean
  validator = new ValidatorUtils();

  ngOnInit() {}

  isEmpty(str) {
    if (!str || !str.length) {
      return true;
    }
    return false;
  }

  submit() {
    if (this.isEmpty(this.titleQuestion)) {
      this.openMessage(
        "Erro",
        "Você precisa informar um título para a alternativa"
      );
      return;
    }
    if (this.is_scale && (this.score === null || !Number.isInteger(this.score))) {
      this.openMessage(
        "Erro",
        "Você precisa informar a pontuação da alternativa"
      );
      return;
    }

    this.action.next({ title: this.titleQuestion, position: this.position, score: this.score });
    this.modalRef3.hide();
  }

  openMessage(title, text, bgclick: boolean = false) {
    this.modalRef4 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
