import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { UserService } from "src/app/service/user.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import { CalendarService } from "src/app/service/calendar.service";
import { ProcedureService } from "src/app/service/procedure.service";

@Component({
  selector: "modal-procedure-settings",
  templateUrl: "./modal-procedure-settings.component.html",
  styleUrls: ["./modal-procedure-settings.component.css"],
})
export class ModalProcedureSettingsComponent {
  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private userService: UserService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private procedureService: ProcedureService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;
  title: string = "";
  btn_text: string;
  is_new: boolean = true;
  procedure: any;

  name: string;
  time: number;
  color: string = "#ffffff";

  validator = new ValidatorUtils();

  ngOnInit() {
    if (this.procedure) {
      this.startField(this.procedure);
    }
  }

  startField(ev) {
    this.name = ev.name;
    this.color = ev.color;
    this.time = ev.time;
  }

  getForm() {
    return {
      name: this.name,
      time: Number(this.time),
      color: this.color,
    };
  }

  insertRecord() {
    this.openMessage("Por favor, aguarde", "", true);
    var body = this.getForm();

    if (this.procedure) {
      this.procedureService.editProcedure(body, this.procedure.id).subscribe(
        (data) => {
          this.modalRef2.hide();
          this.openMessage("Sucesso!", "Procedimento editado", false);
          this.action.next(data);
          this.modalRef.hide();
        },
        (error) => {
          this.modalRef2.hide();
          this.error(error, "editar");
        }
      );
    } else {
      this.procedureService.createProcedure(body).subscribe(
        (data) => {
          this.modalRef2.hide();
          this.openMessage("Sucesso!", "Procedimento criado", false);
          this.action.next(data);
          this.modalRef.hide();
        },
        (error) => {
          this.modalRef2.hide();
          this.error(error, "criar");
        }
      );
    }
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi adicionar o procedimento.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "modal-lg",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  createRecord() {
    
    if (this.validator.isEmptyOrNull(this.name)) {
      this.openMessage("Por favor,", "informe o nome do procedimento.", false);
      return false;
    } else if (
      this.validator.isEmptyOrNull(this.time + "") ||
      isNaN(this.time)
    ) {
      this.openMessage(
        "Por favor,",
        "insira um tempo válido do procedimento.",
        false
      );
      return false;
    }
    this.insertRecord();
  }

  handleChange($event) {
    
    this.color = $event.color.hex
    // color = {
    //   hex: '#333',
    //   rgb: {
    //     r: 51,
    //     g: 51,
    //     b: 51,
    //     a: 1,
    //   },
    //   hsl: {
    //     h: 0,
    //     s: 0,
    //     l: .20,
    //     a: 1,
    //   },
    // }
  }
}
