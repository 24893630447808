import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { CompanyService } from "../../service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalFormCreateQuestionComponent } from "../modal-form-create-question/modal-form-create-question.component";
import { ModalChoiceComponent } from "../modal-choice/modal-choice.component";
import { ModalFormPermissionsComponent } from "../modal-form-permissions/modal-form-permissions.component";
import { AdminService } from "src/app/service/admin.service";
import { ModalFormModelDetailComponent } from "./modal-form-model-detail/modal-form-model-detail.component";

@Component({
  selector: "modal-form-model-list",
  templateUrl: "./modal-form-model-list.component.html",
  styleUrls: ["./modal-form-model-list.component.css"],
})
export class ModalFormModelListComponent {
  data: any[];

  titles = ["id", "title", "edit"];

  constructor(
    public modalRef: MDBModalRef,
    public sanitizer: DomSanitizer,
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private adminService: AdminService
  ) {}

  loading: boolean = false;
  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";
  
  ngOnInit() {
    this.getModels();
  }

  getModels() {
    this.loading = true;
    this.adminService.getFormsModel().subscribe((data) => {
      this.loading = false;
      this.data = data;
    });
  }

  openMessage(title, text, bgclick: boolean = false) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  seeFormDetail(form) {
    this.modalRef2 = this.modalService.show(ModalFormModelDetailComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-xl",
      containerClass: "",
      animated: false,
      data: {
        title: "Detalhes do formulário",
        btn_text: "Salvar",
        form,
      },
    });
    this.modalRef2.content.action.subscribe((result: any) => {
      this.action.next(result);
      this.modalRef2.hide();
    });
  }
}
