import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CalendarService {
  constructor(private http: HttpClient) {}

  getCalendarByDate(date): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/calendar/services-by-day/?day=${date}`,
      options
    );
  }

  startService(id, serviceType): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/calendar/start/${id}/?serviceType=${serviceType}`,
      options
    );
  }

  getAllCalendar(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/calendar/all-calendar/`,
      options
    );
  }

  createRecord(body): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(
      AppConfig.path + `core/calendar/create/`,
      body,
      options
    );
  }

  editRecord(body, id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.patch<any>(
      AppConfig.path + `core/calendar/edit/${id}/`,
      body,
      options
    );
  }

  deleteRecord(id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.delete<any>(
      AppConfig.path + `core/calendar/delete/${id}/`,
      options
    );
  }

  getCalendarSettings(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/calendar/settings/`,
      options
    );
  }

  updateCalendarSettings(body): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.patch<any>(
      AppConfig.path + `core/calendar/settings/edit/`,
      body,
      options
    );
  }
}
