import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { CompanyService } from "../../service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalFormCreateQuestionComponent } from "../modal-form-create-question/modal-form-create-question.component";
import { ModalChoiceComponent } from "../modal-choice/modal-choice.component";
import { ModalFormPermissionsComponent } from "../modal-form-permissions/modal-form-permissions.component";

@Component({
  selector: "modal-scoreanswers-create",
  templateUrl: "./modal-scoreanswers-create.component.html",
  styleUrls: ["./modal-scoreanswers-create.component.css"],
})
export class ModalScoreAnswersCreateComponent {
  url: string = "https://www.mmlpqtpkasjdashdjahd.com";
  urlSafe: SafeResourceUrl;
  constructor(
    public modalRef: MDBModalRef,
    public sanitizer: DomSanitizer,
    private modalService: MDBModalService,
    private companyService: CompanyService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";

  form_clone;
  is_new: boolean;
  loading: boolean = false;
  name: string = "";

  validator = new ValidatorUtils();
  intervals = [];
  start_value: number;
  end_value: number;
  description: string;
  id: any;
  ngOnInit() {}

  isEmpty(str) {
    if (!str || !str.length) {
      return true;
    }
    return false;
  }

  submit() {
    if (this.isEmpty(this.name)) {
      this.openMessage(
        "Erro",
        "Você precisa informar um nome para essa resposta"
      );
      return;
    }

    if (!this.intervals || !this.intervals.length) {
      this.openMessage(
        "Erro",
        "Você precisa adicionar pelo menos um intervalo"
      );
      return;
    }

    const form = {
      name: this.name,
      intervals_score_answer: this.intervals,
    };
    //console.log(form);

    if (this.id) {
      // console.log(form)
      this.loading = true;
      this.companyService.editScoreAnswers(form, this.id).subscribe(
        (data) => {
          this.openMessage("Sucesso", "Resposta editada!");
          this.action.next(true);
          this.modalRef.hide();
        },
        (error) => {
          let msg = "Ocorreu um erro, tente novamente";
          if (
            error.response &&
            error.response.data &&
            error.response.data.non_field_errors &&
            error.response.data.non_field_errors.length > 0
          ) {
            msg = error.response.data.non_field_errors[0];
          }
          this.openMessage("Erro", msg);

          this.loading = false;
        }
      );
    } else {
      this.loading = true;
      this.companyService.createScoreAnswers(form).subscribe(
        (data) => {
          this.openMessage("Sucesso", "Resposta criada!");
          this.action.next(true);
          this.modalRef.hide();
        },
        (error) => {
          let msg = "Ocorreu um erro, tente novamente";
          if (
            error.response &&
            error.response.data &&
            error.response.data.non_field_errors &&
            error.response.data.non_field_errors.length > 0
          ) {
            msg = error.response.data.non_field_errors[0];
          }
          this.openMessage("Erro", msg);

          this.loading = false;
        }
      );
    }
  }

  openMessage(title, text, bgclick: boolean = false) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  addInterval() {
    if (this.start_value === null || !Number.isInteger(this.start_value)) {
      this.openMessage("Erro", "Você precisa informar um valor inicial válido");
      return;
    }
    if (this.end_value === null || !Number.isInteger(this.end_value)) {
      this.openMessage("Erro", "Você precisa informar um valor final válido");
      return;
    }
    if (this.start_value > this.end_value) {
      this.openMessage(
        "Erro",
        "O valor inicial não pode ser maior que o final"
      );
      return;
    }
    if (!this.description || !this.description.length) {
      this.openMessage("Erro", "Você precisa informar uma descrição");
      return;
    }

    this.intervals.push({
      start_value: this.start_value,
      end_value: this.end_value,
      description: this.description,
    });
    this.start_value = null;
    this.end_value = null;
    this.description = null;
  }

  deleteInterval(i) {
    this.intervals.splice(i, 1);
  }
}
