import { Component, Input } from "@angular/core";
import { MDBModalRef } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { CompanyService } from "src/app/service/company.service";

@Component({
  selector: "modal-choice",
  templateUrl: "./modal-select-form.component.html",
  styleUrls: ["./modal-select-form.component.css"],
})
export class ModalSelectFormComponent {
  constructor(
    public modalRef: MDBModalRef,
    private companyService: CompanyService
  ) {}

  action: Subject<any> = new Subject();
  text: string = "";
  title: string = "";
  loading = true;
  patient_id;
  forms = null;
  selectedForm = null;

  ngOnInit() {
    this.companyService
      .getFormsFromCompany(this.patient_id)
      .subscribe((data) => {
        this.loading = false;
        this.forms = data;
      });
  }

  close() {
    if (this.selectedForm) {
      this.action.next(this.forms.find((val) => val.id === this.selectedForm));
      this.modalRef.hide();
    }
  }
}
