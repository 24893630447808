import { Component, OnInit } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Md5 } from "md5-typescript";
import { Subject } from "rxjs";
import { ModalChoiceComponent } from "src/app/modals/modal-choice/modal-choice.component";
import { ModalFormCreateQuestionComponent } from "src/app/modals/modal-form-create-question/modal-form-create-question.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { AdminService } from "src/app/service/admin.service";
import { CompanyService } from "src/app/service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";

@Component({
  selector: "app-modal-form-admin",
  templateUrl: "./modal-form-admin.component.html",
  styleUrls: ["./modal-form-admin.component.css"],
})
export class ModalFormAdminComponent implements OnInit {
  modalRef2: MDBModalRef;
  loading: boolean = false;

  action: Subject<any> = new Subject();

  title: string = "";
  btn_text: string;
  form: any;

  form_title: string;
  questions: any[];
  questions_alternatives: any[];
  form_detail;

  validator = new ValidatorUtils();

  questionsNew: any[] = [];
  constructor(
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private adminService: AdminService,
    public modalRef: MDBModalRef
  ) {}

  ngOnInit(): void {
    this.getFormDetail();
  }

  getFormDetail() {
    this.loading = true;
    this.companyService.getFormsById(this.form.id).subscribe((data) => {
      this.form_title = data.title;
      this.questions = [];
      this.questions_alternatives = [];
      for (const question of data.questions) {
        this.questions.push(question.title);
        let aux = [];
        for (const alternative of question.alternatives) {
          aux.push(alternative.title);
        }
        this.questions_alternatives.push(aux);
      }

      this.form_detail = data;
      this.loading = false;
    });
  }

  save() {
    if (this.validator.isEmptyOrNull(this.form_title)) {
      this.openMessage("Por favor,", "informe o título do formulário.", false);
      return false;
    }

    for (let i = 0; i < this.questions.length; i++) {
      if (this.validator.isEmptyOrNull(this.questions[i])) {
        this.openMessage(
          "Por favor,",
          "informe o título da questão " + (i + 1) + ".",
          false
        );
        return false;
      }

      for (let j = 0; j < this.questions_alternatives[i].length; j++) {
        if (this.validator.isEmptyOrNull(this.questions_alternatives[i][j])) {
          this.openMessage(
            "Por favor,",
            "informe o título da alternativa " +
              (j + 1) +
              " da questão " +
              (i + 1) +
              ".",
            false
          );
          return false;
        }
      }
    }

    const json = {
      title: this.form_title,
      questions: [],
      alternatives: [],
      questionsNew: this.questionsNew,
    };

    for (let i = 0; i < this.questions.length; i++) {
      if (this.form_detail.questions[i].title !== this.questions[i]) {
        json.questions.push({
          id: this.form_detail.questions[i].id,
          title: this.questions[i],
        });
      }
      for (let j = 0; j < this.questions_alternatives[i].length; j++) {
        if (
          this.form_detail.questions[i].alternatives[j].title !==
          this.questions_alternatives[i][j]
        ) {
          json.alternatives.push({
            id: this.form_detail.questions[i].alternatives[j].id,
            title: this.questions_alternatives[i][j],
          });
        }
      }
    }

    this.adminService.updateFormTitles(json, this.form.id).subscribe(
      (data) => {
        this.openMessage("Sucesso", "Formulário atualizado", false);
        this.modalRef.hide();
      },
      (error) => {
        this.error(error, "editar");
      }
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível ${string} o formulário.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  changeQuestion(e, index) {
    this.questions[index] = e.target.value;
  }

  changeAlternativeQuestion(e, index, alternative) {
    this.questions_alternatives[index][alternative] = e.target.value;
  }

  getType(q) {
    if (q.is_scale) {
      return "6";
    }

    if (q.is_frequency) {
      return "5";
    }
  
    if (q.is_numeric_scale) {
      return "7";
    }
  
    if (q.is_numeric) {
      return "2";
    }
    if (q.is_image) {
      return "3";
    }
    if (q.is_date) {
      return "4";
    }  
    if (q.is_list) {
      return "8";
    }
    if (q.is_selectable) {
      return "1";
    }
    return "0";
  }

  getTypeDate(type) {
    if (type === "full") return "0";
    if (type === "date") return "1";
    if (type === "hour") return "2";
  }

  addQuestion(pos = null) {
    this.modalRef2 = this.modalService.show(ModalFormCreateQuestionComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: pos !== null ? "Editar Questão" : "Salvar Questão",
        btn_text: pos !== null ? "Editar" : "Adicionar",
        is_new: pos !== null ? false : true,
        titleQuestion: pos !== null ? this.questionsNew[pos].title : null,
        position: pos !== null ? this.questionsNew[pos].position : 0,
        type: pos !== null ? this.getType(this.questionsNew[pos]) : 0,

        required: pos !== null ? this.questionsNew[pos].required : false,
        number_of_choices:
          pos !== null ? this.questionsNew[pos].number_of_choices : 1,
        alternatives: pos !== null ? this.questionsNew[pos].alternatives : [],

        type_date:
          pos !== null
            ? this.getTypeDate(this.questionsNew[pos].type_date)
            : null,
        min_frequency:
          pos !== null
            ? this.getTypeDate(this.questionsNew[pos].min_frequency)
            : null,
        max_frequency:
          pos !== null
            ? this.getTypeDate(this.questionsNew[pos].max_frequency)
            : null,
        range_frequency:
          pos !== null
            ? this.getTypeDate(this.questionsNew[pos].range_frequency)
            : 1,
      },
    });
    this.modalRef2.content.action.subscribe((result: any) => {
      this.modalRef2.hide();
      const aux = this.questionsNew;
      if (pos !== null) {
        aux.splice(pos, 1);
      }
      aux.push(result);
      aux.sort((a, b) => a.position - b.position);
      this.questionsNew = aux;
    });
  }

  deleteQuestion(pos) {
    this.modalRef2 = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja deletar a questão?`,
      },
    });
    this.modalRef2.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.questionsNew.splice(pos, 1);
      }
    });
  }

  editQuestion(pos) {
    this.addQuestion(pos);
  }
}
