import { DatePipe } from "@angular/common";
import { Component, ViewChild } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { DoctorService } from "../../service/doctor.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";

const formatDate = (date: string) => {
  if (!date) {
    return "Data não informada";
  }
  const d = new Date(date + "+00:00");
  const datestring =
    ("0" + d.getDate()).slice(-2) +
    "/" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "/" +
    d.getFullYear() +
    " " +
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2);

  return datestring;
};

@Component({
  selector: "modal-doctor-notification",
  templateUrl: "./modal-doctor-notification.component.html",
  styleUrls: ["./modal-doctor-notification.component.css"],
})
export class ModalDoctorNotificationComponent {
  constructor(
    public modalRef: MDBModalRef,
    private router: Router,
    private modalService: MDBModalService,
    private doctorService: DoctorService,
    private datePipe: DatePipe
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";

  doctor_id: number = 0;
  doctor_name: string = "";
  is_loaded: boolean = true;
  patient_selected = [];

  validator = new ValidatorUtils();
  canSee: boolean = false;
  always_notitication: boolean = true;
  patients = [];
  hours = [];

  startTime;
  endTime;
  dayOfWeek;
  ngOnInit() {
    this.title = `${this.title} / ${this.doctor_name}`;
    this.loadData();
  }

  loadData() {
    this.is_loaded = false;
    this.doctorService.getDoctorNotifications().subscribe((data) => {
      // console.log(data);
      this.always_notitication = data.always_notitication;
      if (!data.doctor_patient_alarms || !data.doctor_patient_alarms.length) {
        this.canSee = false;
      } else {
        this.canSee = true;
      }

      this.patient_selected = [];
      for (const p of data.doctor_patient_alarms) {
        this.patient_selected.push(p.patient.id);
      }

      this.hours = [];
      for (const d of data.doctor_alarms) {
        this.hours.push({
          start: d.start_time_minute,
          end: d.end_time_minute,
          day_of_week: d.day_of_week,
        });
      }

      this.patients = data.patients;
      this.is_loaded = true;
    });
  }

  convertToHour(minute) {
    const twoPlace = (number) => (number < 10 ? "0" + number : number);
    return `${twoPlace(Math.floor(minute / 60))}:${twoPlace(
      Math.floor(minute % 60)
    )}`;
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} o médico.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  save() {
    const json = {
      patients: [],
      hours: [],
      always_notitication: true,
    };
    if (this.always_notitication) {
      this.doctorService.updateAlarms(json).subscribe(
        (data) => {
          this.openMessage(
            "Sucesso!",
            "Configurações de notificações atualizadas",
            false
          );
          this.modalRef.hide();
        },
        (err) => {
          this.error(err, "editar");
        }
      );
      return;
    }
    json['always_notitication'] = false;
    if (this.canSee) {
      if (!this.patient_selected) {
        this.openMessage(
          "Erro",
          "Você deve selecionar pelo menos um paciente.",
          false
        );
        return;
      }

      if (!this.hours || !this.hours.length) {
        this.openMessage(
          "Erro",
          "Você deve informar pelo menos um horário.",
          false
        );
        return;
      }
    }

    if (this.canSee) {
      json["patients"] = this.patient_selected;
      json["hours"] = this.hours;
    }

    this.doctorService.updateAlarms(json).subscribe(
      (data) => {
        this.openMessage(
          "Sucesso!",
          "Configurações de notificações atualizadas",
          false
        );
        this.modalRef.hide();
      },
      (err) => {
        this.error(err, "editar");
      }
    );
  }

  checkTime(x) {
    if (!x || x.length != 5) return false;
    if (Number(x.split(":")[0]) >= 24) return false;
    if (Number(x.split(":")[2]) >= 60) return false;
    return true;
  }

  convertToMinute(date) {
    return Number(date.split(":")[0]) * 60 + Number(date.split(":")[1]);
  }

  addTime() {
    if (!this.checkTime(this.startTime)) {
      this.openMessage(
        "Erro",
        "Por favor, informe a data de início de forma correta (HH:MM).",
        false
      );
      return;
    }

    if (!this.checkTime(this.endTime)) {
      this.openMessage(
        "Erro",
        "Por favor, informe a data de fim de forma correta (HH:MM).",
        false
      );
      return;
    }

    if (this.dayOfWeek === null || this.dayOfWeek.length === 0) {
      this.openMessage("Erro", "Por favor, escolha o dia da semana.", false);
      return;
    }
    for(const d of this.dayOfWeek) {
      this.hours.push({
        start: this.convertToMinute(this.startTime),
        end: this.convertToMinute(this.endTime),
        day_of_week: Number(d),
      });
    }
      
    this.dayOfWeek = null;
    this.startTime = null;
    this.endTime = null;
  }

  convertToDay(val) {
    val = Number(val);
    if (val === 0) return "Domingo";
    if (val === 1) return "Segunda-feira";
    if (val === 2) return "Terça-feira";
    if (val === 3) return "Quarta-feira";
    if (val === 4) return "Quinta-feira";
    if (val === 5) return "Sexta-feira";
    if (val === 6) return "Sábado";
  }

  remove(id) {
    this.hours.splice(id, 1);
  }
}
