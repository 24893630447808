import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { MedicationService } from 'src/app/service/medication.service';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { ModalMessageComponent } from '../modal-message/modal-message.component';

@Component({
    selector: 'app-modal-medication-detail',
    templateUrl: './modal-medication-detail.component.html',
    styleUrls: ['./modal-medication-detail.component.css']
})

export class ModalMedicationDetailComponent implements OnInit {

    constructor(
        public modalRef: MDBModalRef, 
        private modalService: MDBModalService,
        private medicationService: MedicationService,
    ) { }

    action: Subject<any> = new Subject();
    modalRef2: MDBModalRef;
    
    title: string = "";
    btn_text: string;
    is_new: boolean = true;

    medication_clone;

    medication = {
        name: null,
        commercial_name: null,
        description: null,
        observation: null,
    }

    validator = new ValidatorUtils();

    ngOnInit(): void {
        if(this.medication_clone != null && !this.is_new){
            this.medication.name = this.medication_clone.name;
            this.medication.description = this.medication_clone.description;
            this.medication.commercial_name = this.medication_clone.commercial_name;
            this.medication.observation = this.medication_clone.observation;
        }
    }

    checkMedication(){
        if(this.validator.isEmptyOrNull(this.medication.name)){
            this.openMessage("Por favor,", "insira o nome do medicamento.", false);
            return false
        }
        else if(this.validator.isEmptyOrNull(this.medication.description)){
            this.openMessage("Por favor,", "insira uma descrição para o medicamento.", false);
            return false
        }
        else if(this.validator.isEmptyOrNull(this.medication.commercial_name)){
            this.openMessage("Por favor,", "insira um nome comercial para o medicamento.", false);
            return false
        }
        else{
            if(this.is_new)
                this.createMedication()
            else
                this.editMedication()
        }
    }

    createMedication(){
        this.openMessage("Por favor, aguarde", "", true)
        this.medicationService.newMedication(this.medication.name, this.medication.description, this.medication.observation,
            this.medication.commercial_name).subscribe( data => {
            this.modalRef2.hide()
            this.openMessage("Criado!", "Medicamento criado com sucesso.", false)
            this.action.next('update');
            this.modalRef.hide()
        }, error => {
            this.modalRef2.hide()
            this.error(error, 'criar')
        })
    }

    editMedication(){
        this.openMessage("Por favor, aguarde", "", true)
        this.medicationService.editMedication(this.medication_clone.id, this.medication.name, this.medication.description, 
            this.medication.observation, this.medication.commercial_name).subscribe( data => {
            this.modalRef2.hide()
            this.openMessage("Salvo!", "Medicamento editado com sucesso.", false)
            this.action.next('update');
            this.modalRef.hide()
        }, error => {
            this.modalRef2.hide()
            this.error(error, 'editar')
        })
    }

    error(error, string){
        var msg = ""
        if(error.error != null && error.error.error != null && error.error.error.length < 50)
            msg = error.error.error;
        else
            msg = "Por favor verifique as informações inseridas"
        this.openMessage(`Erro, não foi possível ${string} o medicamento.`, msg, false)
    }

    openMessage(title, text, bgclick: boolean){
        this.modalRef2 = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }

}
