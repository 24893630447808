import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { DoctorService } from "../../service/doctor.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { CompanyService } from "src/app/service/company.service";

const formatDate = (date: string) => {
  const d = new Date(date);
  const datestring =
    ("0" + d.getDate()).slice(-2) +
    "/" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "/" +
    d.getFullYear() +
    " " +
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2);

  return datestring;
};

@Component({
  selector: "modal-form-answer",
  templateUrl: "./modal-form-answer.component.html",
  styleUrls: ["./modal-form-answer.component.css"],
})
export class ModalFormAnswerComponent {
  constructor(
    public modalRef: MDBModalRef,
    private router: Router,
    private modalService: MDBModalService,
    private companyService: CompanyService
  ) {}

  displayedColumns: string[] = [];
  data_source = new MatTableDataSource<any>();

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";
  columns: Array<{ label: string; key: string }> = [];

  form_id: number = 0;
  form_title: string = "";
  answers: any = [];

  validator = new ValidatorUtils();
  answersToShow = [];
  ngOnInit() {
    this.title = `${this.title}`;

    this.getAnswer();
  }

  getAnswer() {
    const answersByQuestion = {};
    for (const answer of this.answers) {
      if (!answersByQuestion[answer.form_question.id]) {
        answersByQuestion[answer.form_question.id] = [];
      }
      answersByQuestion[answer.form_question.id].push(answer);
    }
    const resp = [];
    for (const id in answersByQuestion) {
      if (id in answersByQuestion[id]) {
        answersByQuestion[id] = answersByQuestion[id].sort(
          (a, b) => a.form_question.position - b.form_question.position
        );
      }
      resp.push(answersByQuestion[id]);
    }

    this.answersToShow = resp;
  }

  openPatient(id) {
    this.modalRef.hide();
    this.router.navigate([`/home/patient/${id}`]);
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} o médico.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
