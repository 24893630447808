import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-plans',
    templateUrl: './plans.component.html',
    styleUrls: ['./plans.component.css']
})

export class PlansComponent implements OnInit {

    constructor() { }

    check_items = [1, 2, 3, 4, 5, 6]
    plans = ['Plano Basic', 'Plano Pro', 'Plano Premium', 'Plano Platinum']

    ngOnInit(): void {
       
    }

}
