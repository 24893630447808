import { DatePipe } from "@angular/common";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalAddPatientComponent } from "../modals/modal-edit-patient/modal-add-patient.component";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { UserService } from "../service/user.service";
import { ModalPatientDetailComponent } from "../modals/modal-patient-detail/modal-patient-detail.component";
import { ModalPatientResponsablesComponent } from "../modals/modal-patient-responsables/modal-patient-responsables.component";
import { ServiceService } from "../service/service.service";
import { PrescricoesService } from "./components/prescricoes/prescicoes.service";
import { MemedService } from "../service/memed.service";
import { DoctorService } from "../service/doctor.service";
import { ModalServiceTypeSettingsExtraComponent } from "./components/modals/modal-service-type-settings-extra/modal-service-type-settings-extra.component";

@Component({
  selector: "app-patient",
  templateUrl: "./patient.component.html",
  styleUrls: ["./patient.component.css"],
})
export class PatientComponent {
  
  constructor(
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private modalService: MDBModalService,
    private datePipe: DatePipe,
    private serviceService: ServiceService,
    private prescricoesService: PrescricoesService,
    private memedService: MemedService,
    private doctorService: DoctorService
  ) {

    
    try {
      this.id = this.route.snapshot.paramMap.get("id");
    } catch {
      this.router.navigate(["/home/patients"]);
    }
  }
  company_type = localStorage.getItem("companyType");

  activeTab: number = 6;
  modalErrorRef: MDBModalRef;
  is_loaded: boolean = false;
  id;
  patient;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  checkbox_message: boolean = true;
  checkbox_pressure: boolean = true;
  checkbox_glucose: boolean = true;
  checkbox_temperature: boolean = true;
  checkbox_heartbeat: boolean = true;
  checkbox_oxygenation: boolean = true;

  loadingTable: boolean = true;
  displayedColumns: string[] = [
    "time",
    "pas",
    "pad",
    "glucose",
    "temperature",
    "heart_rate",
    "saturation",
    "comments",
  ];
  dataSource = [];

  selected_columns = [
    "pressure",
    "glucose",
    "temperature",
    "heartbeat",
    "oxygenation",
    "message",
    "hour",
  ];

  aux_info = {};
  keys = [
    "blood_pressure_1",
    "blood_pressure_2",
    "glucose",
    "temperature",
    "heartbeat",
    "oxygenation",
    "message",
    "receipt_time",
  ];

  modalRef: MDBModalRef;
  patients: any = [];
  patientsFull: any = [];
  patient_change = null;

  @ViewChild("inputPatient") input: ElementRef;

  serviceRunning = null;
  memed: any = null;
  loading_memed: any = false;
  doctor = null;

  loading_clinic = false;

  histories = {
    health: false,
    medication: false,
    exam: false,
    prescription: false,
    form: {},
  };

  extra_fields = {
    'use_health_record': false,
    'use_medication': false,
    'use_exam': false,
    'use_prescription': false,
    'forms': []
  }
  ngOnInit() {
    if (localStorage.getItem("token") == null) {
      this.router.navigate([""]);
    } else {
      this.range.setValue({ start: new Date(), end: new Date() });
      this.getPatient();
    }

    this.patients = JSON.parse(localStorage.getItem("patients"));
    this.patientsFull = this.patients;
    this.loadServiceRunning();
    this.memed =
      localStorage.getItem("memed") === "null"
        ? null
        : JSON.parse(localStorage.getItem("memed"));
    this.doctor =
      localStorage.getItem("doctor") === "null"
        ? null
        : JSON.parse(localStorage.getItem("doctor"));
  }

  loadServiceRunning() {
    this.loading_clinic = true;
    this.serviceService.getRunning().subscribe((data) => {
      // console.log(data)
      if (data) {
        this.serviceRunning = data;
        localStorage.setItem(
          "serviceRunning",
          JSON.stringify(this.serviceRunning)
        );
      }
      this.loading_clinic = false;
    });
  }

  handleActiveTab(tab: number) {
    this.activeTab = tab;
  }

  changePatient() {
    if (this.serviceRunning) {
      this.router.navigate(["home/patient/" + this.patient_change]);
      // .then(() => window.location.reload());
      this.id = this.patient_change;
      this.patient_change = null;
      this.input.nativeElement.value = "";
      this.ngOnInit();
    } else {
      this.id = this.patient_change;
      this.patient_change = null;
      this.input.nativeElement.value = "";
      this.ngOnInit();
    }
  }

  getPatient() {
    this.userService.getPatient(this.id).subscribe(
      (data) => {
        this.patient = data;
      },
      (error) => {
        this.router.navigate([`/home/patients`]);
        this.openMessage("Error", "Paciente não encontrado!", false);
      }
    );
  }

  newPrescription() {
    const token = this.memed.attributes.token;
    //console.log(this.memed);
    this.prescricoesService.load(
      token,
      this.patient,
      this.doctor,
      this.memedService,
      this.onPrescription
    );
  }

  onPrescription(data, token, patient_id, doctor_id, memedService) {
    const medications = data.prescricao.medicamentos.map((medi) => {
      return {
        medication_id: medi.id,
        name: medi.nome,
        description: medi.descricao,
        posology: medi.sanitized_posology,
        quantity: medi.quantidade,
        unit: medi.unit,
      };
    });

    const allergies = data.prescricao.paciente.allergy.map((allergy) => {
      return {
        allergy_id: allergy.id,
        ingredient_id: allergy.ingredient_id,
        ingredient_name: allergy.ingredient_name,
      };
    });

    const body = {
      token,
      patient: patient_id,
      doctor: doctor_id,
      prescription_id: data.prescricao.id,
      prescription_date: data.prescricao.prescriptionDate,
      medications,
      allergies,
    };

    memedService
      .savePrescription(body, this.serviceRunning.id)
      .subscribe((data) => {
        console.log("foi");
      });
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalErrorRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  registerMemed() {
    this.loading_memed = true;

    this.doctorService.registerMemed().subscribe(
      (data) => {
        this.memedService.getStatus().subscribe(
          (data) => {
            if (data === null) {
              this.openMessage(
                "Erro",
                "Ocorreu um erro ao se cadastrar com a Memed",
                false
              );
            }
            this.memed = data.status;
            this.doctor = data.doctor;

            localStorage.setItem("memed", JSON.stringify(this.memed));
            localStorage.setItem("doctor", JSON.stringify(this.doctor));
            this.loading_memed = false;
          },
          (err) => {
            let message = "Ocorreu um erro ao se cadastrar com a Memed";
            if (err && err.error && err.error.detail)
              message = err.error.detail;
            this.openMessage("Erro", message, false);
            this.loading_memed = false;
          }
        );
      },
      (err) => {
        let message = "Ocorreu um erro ao se cadastrar com a Memed";
        if (err && err.error && err.error.detail) message = err.error.detail;
        this.openMessage("Erro", message, false);
        this.loading_memed = false;
      }
    );
  }

  openEdit() {
    this.modalRef = this.modalService.show(ModalAddPatientComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: "Alterar dados de saúde",
        btn_text: "Salvar",
        is_new: true,
        patient: this.patient,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getPatient();
      }
    });
  }

  openEditPatient() {
    this.modalRef = this.modalService.show(ModalPatientDetailComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: "Editar Paciente",
        btn_text: "Salvar",
        is_new: false,
        patient_clone: this.patient,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getPatient();
      }
    });
  }

  memedFrontEnd() {}

  getAddress(patient) {
    if (!patient) return "";
    let address = "";

    if (patient.street) {
      address += patient.street;
    }
    if (patient.number) {
      address += ", " + patient.number;
    }
    if (patient.neighborhood) {
      address += " - " + patient.neighborhood;
    }
    if (patient.city) {
      address += " - " + patient.city;
    }
    if (patient.state) {
      address += "/" + patient.state;
    }
    return address;
  }

  openResponsables() {
    this.modalRef = this.modalService.show(ModalPatientResponsablesComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: "Responsáveis do paciente - " + this.patient.name,
        btn_text: "Salvar",
        is_new: false,
        patient_clone: this.patient,
      },
    });
  }

  accentFold(inStr) {
    return inStr.replace(
      /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
      function (str, a, c, e, i, n, o, s, u, y, ae) {
        if (a) return "a";
        if (c) return "c";
        if (e) return "e";
        if (i) return "i";
        if (n) return "n";
        if (o) return "o";
        if (s) return "s";
        if (u) return "u";
        if (y) return "y";
        if (ae) return "ae";
      }
    );
  }

  selectSearchPatient(value) {
    // ev.preventDefault();
    // const value = ev.target.value;
    this.patients = [];

    let filter = this.accentFold(value.toLowerCase());

    for (let i = 0; i < this.patientsFull.length; i++) {
      let option = this.patientsFull[i];
      if (this.accentFold(option.name.toLowerCase()).indexOf(filter) >= 0) {
        this.patients.push(option);
      }
    }
  }

  newPatient() {
    this.modalRef = this.modalService.show(ModalPatientDetailComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: "Adicionar Paciente",
        btn_text: "Adicionar",
        is_new: true,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getPatients(1);
      }
    });
  }

  getPatients(page, search = null) {
    this.userService.getPatientsPagination(page, false).subscribe(
      (data) => {
        this.is_loaded = true;

        localStorage.setItem("patients", JSON.stringify(data));
      },
      (error) => {
        localStorage.removeItem("token");
        this.router.navigate(["/login"]);
      }
    );
  }

  newFields() {
    
    this.modalRef = this.modalService.show(ModalServiceTypeSettingsExtraComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        initial_use_health_record: this.serviceRunning.service_type.use_health_record || this.extra_fields.use_health_record,
        initial_use_exam: this.serviceRunning.service_type.use_exam || this.extra_fields.use_exam,
        initial_use_medication: this.serviceRunning.service_type.use_medication || this.extra_fields.use_medication,
        initial_use_prescription: this.serviceRunning.service_type.use_prescription || this.extra_fields.use_prescription,
        initial_forms: [...this.serviceRunning.service_type.forms, ...this.extra_fields.forms],
      },
    });

    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        
        const newForms = [...this.extra_fields.forms, ...result.forms]

        this.extra_fields = {...this.extra_fields, ...result}
        this.extra_fields.forms = newForms
        
      }
    });
  }

  allFormFields() {
    return[...this.serviceRunning.service_type.forms, ...this.extra_fields.forms]
  }
}
