import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { CompanyService } from "../service/company.service";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: "app-report_day-forms",
  templateUrl: "./report_day-forms.component.html",
  styleUrls: ["./report_day-forms.component.css"],
})
export class ReportDayFormsComponent implements OnInit {
  constructor(
    private companyService: CompanyService,
    private modalService: MDBModalService,
    private datePipe: DatePipe
  ) {}
  company_type = localStorage.getItem('companyType')
  displayedColumns: any[] = [];
  columnsIndexToId: any;
  data_source = new MatTableDataSource<any>();

  modalRef: MDBModalRef;
  day: Date = new Date();

  is_loaded: boolean = false;

  /* pagination related variabels */
  page: number = 1;
  count_total: number = 0;
  page_count1: number = 0;
  page_count2: number = 0;
  page_size = 10;

  is_company_admin: boolean = false;
  forms: any[] = [];
  selected_form: any;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  sortBy;
  columns: any[] = [];
  ngOnInit(): void {
    this.getForms();
  }
  ngAfterViewInit() {
    console.log("sorting .. ");
    this.data_source.sort = this.sort;
  }
  truncateString(str, max) {
    return str.length > max ? str.substr(0, max - 1) + "…" : str;
  }

  currentDay() {
    return this.datePipe.transform(this.day, "yyyy/MM/dd");
  }

  getForms() {
    this.is_loaded = false;
    this.companyService.getForms().subscribe((data) => {
      this.forms = data;
      this.is_loaded = true;
    });
  }

  getReportDay(page) {
    // console.log("COmod", this.selected_form, this.day);
    if (!this.selected_form || !this.day) return;

    this.is_loaded = false;
    let start = this.datePipe.transform(this.day, "yyyy-MM-dd") + " 00:00:00";
    let end = this.datePipe.transform(this.day, "yyyy-MM-dd") + " 23:59:59";

    this.companyService
      .getReportDayForms(start, end, this.selected_form)
      .subscribe(
        (data) => {
          const columns = [{ name: "Nome", id: -1 }];
          const sortColumns = [
            { name: "Nome ↑", id: 1 },
            { name: "Nome ↓", id: -1 },
          ];
          this.sortBy = null;
          const allQuestions = {};
          for (const d of data) {
            for (const q in d.questions) {
              if (!allQuestions[d.questions[q].id]) {
                allQuestions[d.questions[q].id] = {
                  id: d.questions[q].id,
                  position: d.questions[q].position,
                  name: d.questions[q].name,
                };
              }
            }
          }

          const filteredQuestions = [];
          for (const a in allQuestions) {
            filteredQuestions.push(allQuestions[a]);
          }

          filteredQuestions.sort((a, b) => a.position - b.position);
          this.columnsIndexToId = {};
          let id = 1;
          for (const q of filteredQuestions) {
            columns.push(q);

            sortColumns.push({ name: q.name + " ↑", id: id + 1 });
            sortColumns.push({ name: q.name + " ↓", id: -(id + 1) });
            this.columnsIndexToId[id++] = q.id;
          }
          this.columns = sortColumns;
          console.log(this.columnsIndexToId);
          this.displayedColumns = columns.map((c) => c.name);

          const dataTransformed = [];
          for (const d of data) {
            const arr = [d.name];
            for (let i = 1; i < id; i++) arr.push(this.getData(d, i));
            dataTransformed.push(arr);
          }
          // console.log(dataTransformed);
          this.data_source.data = dataTransformed;
          this.data_source.sort = this.sort;
          //console.log(this.data_source);
          // pagination calc
          this.page = page;
          this.count_total = data.length;
          this.page_count1 = 1 + (page - 1) * this.page_size;
          if (this.page_count1 + (this.page_size - 1) > this.count_total) {
            this.page_count2 = this.count_total;
          } else {
            this.page_count2 = this.page_count1 + (this.page_size - 1);
          }
          this.is_loaded = true;
        },
        (error) => {
          console.log(error);
          this.is_loaded = true;
        }
      );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} o médico.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  getData(data, index) {
    if (index === 0) return data.name;
    const id = this.columnsIndexToId[index];
    if (data.questions[id]) {
      return data.questions[id].answers.reduce((acc, element) => {
        let ans = acc;

        if (ans.length) ans += "<br/>";

        if (element.answer_image) {
          ans += `<img
            src="${element.answer_image}"
            alt="resposta"
            width="100"
        
          />`;
        } else {
          ans += element.answer;
        }

        return ans;
      }, "");
    }

    return "--";
  }

  sortData() {
    this.data_source.data.sort((a, b) => {
      if (this.sortBy < 0) {
        return a[-this.sortBy - 1].localeCompare(b[-this.sortBy - 1]);
      }
      return b[this.sortBy - 1].localeCompare(a[this.sortBy - 1]);
    });
    this.data_source.data = this.data_source.data;
  }
}
