import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../service/user.service";
import { Md5 } from "md5-typescript";
import { InformationsService } from "../service/informations.service";
import { ValidatorUtils } from "../utils/validator.utils";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { DoctorService } from "../service/doctor.service";
import { CompanyService } from "../service/company.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  constructor(
    private userService: UserService,
    private router: Router,
    private informationsService: InformationsService,
    private modalService: MDBModalService,
    private doctorService: DoctorService,
    private companyService: CompanyService
  ) {}

  loading = false;

  cnpj: string;
  name: string;
  email: string;
  phone: string;
  city: string;
  state: string;
  neighborhood: string;
  street: string;
  number: string;
  complement: string;

  nameDoctor: string;
  emailDoctor: string;
  birthday: string;
  company: string;
  phoneDoctor: string;
  cpf: string;
  crm: string;
  gender: string;
  specialty: string;
  association: string;
  cityDoctor: string;
  stateDoctor: string;
  neighborhoodDoctor: string;
  streetDoctor: string;
  numberDoctor: string;
  complementDoctor: string;
  password: string;
  confirmPassword: string;
  associations: [];
  specialties: [];
  checkInput = false;
  active = true;

  errorMessage: string;

  validator = new ValidatorUtils();

  modalRef2: MDBModalRef;
  clear() {
    this.cnpj = null;
    this.name = null;
    this.email = null;
    this.phone = null;
    this.state = null;
    this.city = null;
    this.neighborhood = null;
    this.street = null;
    this.number = null;
    this.complement = null;
    this.association = null;
    this.nameDoctor = null;
    this.emailDoctor = null;
    this.birthday = null;
    this.phoneDoctor = null;
    this.company = null;
    this.cpf = null;
    this.crm = null;
    this.gender = null;
    this.cityDoctor = null;
    this.stateDoctor = null;
    this.streetDoctor = null;
    this.neighborhoodDoctor = null;
    this.numberDoctor = null;
    this.complementDoctor = null;
    this.password = null;
    this.confirmPassword = null;
    this.active = true;
  }

  ngOnInit(): void {
    this.clear();
    this.informationsService.getCompanyType().subscribe((data) => {
      this.associations = data;
    });

    this.informationsService.getSpecialty().subscribe((data) => {
      this.specialties = data;
    });

  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  checkDoctor() {
    if (this.validator.isEmptyOrNull(this.name)) {
      this.openMessage("Por favor,", "insira o nome da empresa.", false);
      return false;
    }
    if (this.validator.isEmptyOrNull(this.cnpj)) {
      this.openMessage("Por favor,", "insira o CNPJ da empresa", false);
      return false;
    }
    if (this.validator.isEmptyOrNull(this.email)) {
      this.openMessage("Por favor,", "insira o e-mail da empresa", false);
      return false;
    }
    if (this.validator.isEmptyOrNull(this.phone)) {
      this.openMessage("Por favor,", "insira o telefone da empresa", false);
      return false;
    }
    if (this.validator.isEmptyOrNull(this.city)) {
      this.openMessage("Por favor,", "insira a cidade da empresa", false);
      return false;
    }
    if (this.validator.isEmptyOrNull(this.state)) {
      this.openMessage("Por favor,", "insira o estado da empresa", false);
      return false;
    }
    if (this.validator.isEmptyOrNull(this.association)) {
      this.openMessage("Por favor,", "insira o tipo da empresa", false);
      return false;
    }

    if (this.validator.isEmptyOrNull(this.nameDoctor)) {
      this.openMessage(
        "Por favor,",
        "insira o nome do profissional responsável.",
        false
      );
      return false;
    } else if (this.validator.isEmptyOrNull(this.emailDoctor)) {
      this.openMessage(
        "Por favor,",
        "insira o email do profissional responsável.",
        false
      );
      return false;
    } else if (this.validator.isEmptyOrNull(this.cpf)) {
      this.openMessage(
        "Por favor,",
        "insira o CPF do profissional responsável..",
        false
      );
      return false;
    } else if (this.validator.isEmptyOrNull(this.password)) {
      this.openMessage("Por favor,", "insira a senha.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.confirmPassword)) {
      this.openMessage("Por favor,", "confirme a senha.", false);
      return false;
    } else if (this.password !== this.confirmPassword) {
      this.openMessage("Erro,", "as senhas são diferentes.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.birthday)) {
      this.openMessage(
        "Por favor,",
        "insira a data de nascimento do profissional responsável..",
        false
      );
      return false;
    } else if (this.validator.isEmptyOrNull(this.gender)) {
      this.openMessage(
        "Por favor,",
        "selecione o gênero do profissional responsável..",
        false
      );
      return false;
    } else if (this.validator.isEmptyOrNull(this.crm)) {
      this.openMessage(
        "Por favor,",
        "informe o Registro de classe do profissional responsável..",
        false
      );
      return false;
    } else if (this.validator.isEmptyOrNull(this.stateDoctor)) {
      this.openMessage(
        "Por favor,",
        "informe o estado do médico do profissional responsável..",
        false
      );
      return false;
    } else {
      const day = Number(this.birthday.substring(0, 2));
      const month = Number(this.birthday.substring(3, 5));
      const year = Number(this.birthday.substring(6, 10));

      const date = new Date(Number(year), Number(month) - 1, Number(day));
      const time = new Date().getTime() - date.getTime();
      const timePassed = time / 1000 / 60;

      if (day > 31 || day < 1 || month > 12 || month < 1 || year < 1900) {
        this.openMessage(
          "Por favor,",
          "insira uma data de nascimento válida",
          false
        );
        return false;
      } else if (timePassed < 0) {
        this.openMessage(
          "Por favor,",
          "insira uma data de nascimento válida",
          false
        );
        return false;
      } else {
        this.createDoctor(`${year}-${month}-${day}`);
      }
    }
  }

  createDoctor(birthday) {
    this.openMessage("Por favor, aguarde", "", true);
    const passwordMD5 = Md5.init(this.password).toUpperCase();

    const body = {
      cnpj: this.cnpj,
      name: this.name,
      email: this.email,
      phone: this.phone,
      state: this.state,
      city: this.city,
      neighborhood: this.neighborhood,
      street: this.street,
      number: this.number,
      complement: this.complement,
      company_type: this.association,

      doctor: {
        name: this.nameDoctor,
        email: this.emailDoctor,
        birthday: birthday,
        phone: this.phoneDoctor,
        company: this.company,
        cpf: this.cpf,
        crm: this.crm,
        gender: this.gender,
        city: this.cityDoctor,
        state: this.stateDoctor,
        street: this.streetDoctor,
        neighborhood: this.neighborhoodDoctor,
        number: this.numberDoctor,
        complement: this.complementDoctor,
        password: passwordMD5,
        active: this.active,
      },
    };

    this.companyService.createNewCompany(body).subscribe(
      (data) => {
        this.modalRef2.hide();
        this.openMessage("Criado!", "Conta criada com sucesso.", false);
        this.loading = false;
        this.router.navigate(["login"]);
      },
      (error) => {
        this.modalRef2.hide();
        this.loading = false;
        this.errorMessage = error.error.detail;
      }
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title,
        text,
      },
    });
  }
}
