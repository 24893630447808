import { Component, Input, SkipSelf } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { CompanyService } from "src/app/service/company.service";

@Component({
  selector: "add-answer-form-clinic",
  templateUrl: "./add-answer-form-clinic.component.html",
  styleUrls: ["./add-answer-form-clinic.component.css"],
})
export class AddAnswerFormClinicComponent {
  constructor(
    private companyService: CompanyService,

    private modalService: MDBModalService
  ) {}

  @Input() form;
  @Input() patient_id: string;
  @Input() service_id: string;

  text: string = "";
  title: string = "";
  
  answers = {};
  loading = false;
  modalRef2: MDBModalRef;
  totalScore = { value: 0 };
  form_answer_question: any = null;
  form_answer_id: any = null;
  // setAnswer = (value) => {
  //   this.answers = {...this.answers, ...this.getValue(this.question, value)}

  // }

  ngOnInit() {
    const newAns = {};
    const alternativesById = {};
    for (const question of this.form.questions) {
      newAns[question.id] = "";
      if (question.is_selectable || question.is_list) {
        newAns[question.id] = {};
        if (!alternativesById[question.id]) alternativesById[question.id] = [];

        for (const alternative of question.alternatives) {
          newAns[question.id][alternative.id] = false;
          alternativesById[question.id].push(alternative);
        }
      } else {
        // console.log('O id é', question.id)
      }
    }
 /// LOADING
    
    if (this.form_answer_question) {
      // console.log('entrou aqui')
      // console.log(this.form_answer_question)
      for (const answer of this.form_answer_question) {
        if (
          answer.form_question.is_selectable ||
          answer.form_question.is_list
        ) {
          for (const alternative of alternativesById[answer.form_question.id]) {
            if (alternative.title === answer.answer)
              newAns[answer.form_question.id][alternative.id] = true;
          }
          // for (const alternative of answer.form_question.alternatives) {
          //   this.answers[answer.form_question.id][alternative.id] = true;
          // }
        } else {
          
          newAns[answer.form_question.id] = answer.answer;
          
        }
      }
    }

    this.answers = newAns;
    
  }

  getValue(question, value) {
    const ans = {};
    ans[question.id] = value;
    return ans;
  }

  setAnswer(value, question) {
    //console.log(this.answers);
    // //console.log('aqui', value, question)
    // const ans = {};
    // //console.log(this.answers);
    // ans[question.id] = value;
    // //console.log('aqui2', ans)
    // this.answers = {
    //   ...this.answers,
    //   ...ans,
    // };
    // //console.log({
    //   ...this.answers,
    //   ...ans,
    // })
    // //console.log(this.answers)
  }

  getAlternativeValue(question, id) {
    for (const alternative of question.alternatives) {
      if (Number(alternative.id) === Number(id)) {
        return alternative.title;
      }
    }
  }

  countSelectedAlternatives(answers) {
    let ans = 0;
    if (!answers) return 0;
    for (const x in answers) {
      ans += answers[x];
    }
    return ans;
  }

  empty() {}

  openMessage(title, text, bgclick: boolean = false) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  validateDate(date) {
    if (!date || date.length !== 10) return false;
    const d = new Date(date.split("/").reverse().join("-"));
    return d instanceof Date && !isNaN(d as any);
  }

  validateHour(hour) {
    if (!hour || hour.length !== 5) return false;
    const h = hour.split(":")[0];
    const m = hour.split(":")[1];
    return h <= 23 && m < 60;
  }

  toDecimal(val) {
    if (!val) return val;

    return String(val).replace(",", ".");
  }
  isDecimal(val) {
    val = this.toDecimal(val);
    val = Number(val);
    if (isNaN(val)) return false;
    if (typeof val !== "number") return false;
    return true;
  }

  async submit() {
    let id = 0;
    const questionById = {};
    for (const question of this.form.questions) {
      id += 1;
      questionById[question.id] = question;
      if (question.required) {
        if (!question.is_selectable && !question.is_list) {
          if (
            !this.answers[question.id] ||
            this.answers[question.id].length === 0
          ) {
            this.openMessage(
              "Erro",
              `A questão ${id} é obrigatória e precisa ser preenchida`
            );
            return;
          }
        } else {
          const totalSelected = this.countSelectedAlternatives(
            this.answers[question.id]
          );
          if (!totalSelected) {
            this.openMessage(
              "Erro",
              `A questão ${id} é obrigatória e precisa ser preenchida`
            );
            return;
          }
          const maxSelected = question.number_of_choices ?? 0;
          if (totalSelected > maxSelected) {
            this.openMessage(
              "Erro",
              `Só é possível selecionar ${maxSelected} resposta${
                maxSelected === 1 ? "" : "s"
              } na questão ${id}`
            );
            return;
          }
        }
      }

      if (question.is_selectable || question.is_list) {
        const totalSelected = this.countSelectedAlternatives(
          this.answers[question.id]
        );
        const maxSelected = question.number_of_choices ?? 0;
        if (totalSelected > maxSelected) {
          this.openMessage(
            "Erro",
            `Só é possível selecionar ${maxSelected} resposta${
              maxSelected === 1 ? "" : "s"
            } na questão ${id}`
          );
          return;
        }
      }

      if (question.is_numeric) {
        if (
          this.answers[question.id] &&
          String(this.answers[question.id]).length > 0 &&
          !this.isDecimal(this.answers[question.id])
        ) {
          this.openMessage(
            "Erro",
            `A questão ${id} aceita somente números, insira um valor correto`
          );
          return;
        }
      }

      if (question.is_date) {
        if (
          this.answers[question.id] &&
          String(this.answers[question.id]).length > 0
        ) {
          if (question.type_date === "full") {
            if (
              this.answers[question.id].length !== 16 ||
              !this.validateDate(this.answers[question.id].split(" ")[0]) ||
              !this.validateHour(this.answers[question.id].split(" ")[1])
            ) {
              this.openMessage(
                "Erro",
                `A questão ${id} aceita somente datas válidas, use o formato dd/mm/aaaa hh:mm`
              );
              return;
            }
          } else if (question.type_date === "date") {
            if (
              this.answers[question.id].length !== 10 ||
              !this.validateDate(this.answers[question.id])
            ) {
              this.openMessage(
                "Erro",
                `A questão ${id} aceita somente datas válidas, use o formato dd/mm/aaaa`
              );
              return;
            }
          } else if (question.type_date === "hour") {
            if (
              this.answers[question.id].length !== 5 ||
              !this.validateHour(this.answers[question.id])
            ) {
              this.openMessage(
                "Erro",
                `A questão ${id} aceita somente datas válidas, use o formato hh:mm`
              );
              return;
            }
          }
        }
      }
    }
    const body = {
      formId: this.form.id,
      answers: [],
    };
    for (const id in this.answers) {
      if (this.answers[id]) {
        if (!questionById[id].is_selectable && !questionById[id].is_list) {
          body.answers.push({
            id,
            answer: this.answers[id],
          });
        } else {
          const value = [];
          for (const i in this.answers[id]) {
            if (this.answers[id][i]) {
              value.push({
                id: i,
                answer: this.getAlternativeValue(questionById[id], i),
              });
            }
          }
          body.answers.push({
            id,
            answers: value,
          });
        }
      }
    }

    if (this.form_answer_id) {
      this.loading = true;
      this.companyService.editFormAnswer(this.form_answer_id, body, this.service_id).subscribe(
        (data) => {
          this.openMessage("Sucesso", "Resposta editada!");
          // this.action.next(true);
          // this.modalRef.hide();

          this.loading = false;
        },
        (error) => {
          let msg = "Ocorreu um erro, tente novamente";
          if (
            error.response &&
            error.response.data &&
            error.response.data.non_field_errors &&
            error.response.data.non_field_errors.length > 0
          ) {
            msg = error.response.data.non_field_errors[0];
          }
          //console.log(error);
          this.openMessage("Erro", msg);

          this.loading = false;
        }
      );
    } else {
      this.loading = true;
      this.companyService.saveAnswers(this.patient_id, body, this.service_id).subscribe(
        (data) => {
          this.openMessage("Sucesso", "Resposta salva!");
          // this.action.next(true);
          // this.modalRef.hide();
          this.loading = false;
        },
        (error) => {
          let msg = "Ocorreu um erro, tente novamente";
          if (
            error.response &&
            error.response.data &&
            error.response.data.non_field_errors &&
            error.response.data.non_field_errors.length > 0
          ) {
            msg = error.response.data.non_field_errors[0];
          }
          //console.log(error);
          this.openMessage("Erro", msg);

          this.loading = false;
        }
      );
    }
  }

  openImageFull(url) {
    window.open(url, "_blank");
  }

  getAnswerScore(score, total) {
    for (const interval of score.intervals_score_answer) {
      if (interval.start_value <= total && interval.end_value >= total) {
        return interval.description;
      }
    }
    return "Valor fora da escala";
  }
}
