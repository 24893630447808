import { Component, Input, SkipSelf } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { CompanyService } from "src/app/service/company.service";
import { ModalMessageComponent } from "../modal-message/modal-message.component";

@Component({
  selector: "modal-form-question-caption",
  templateUrl: "./modal-form-question-caption.html",
  styleUrls: ["./modal-form-question-caption.css"],
})
export class ModalFormQuestionCaptionComponent {
  constructor(
    public modalRef: MDBModalRef,
    private companyService: CompanyService,

    private modalService: MDBModalService
  ) {}

  action: Subject<any> = new Subject();
  caption_text: string = "";
  caption_image: string = "";
  title: string = "";

  // setAnswer = (value) => {
  //   this.answers = {...this.answers, ...this.getValue(this.question, value)}

  // }

  ngOnInit() {
  
  }

  ok() {
    this.modalRef.hide();
  }

  openImageFull(url) {
    window.open(url, "_blank");
  }
}
