import { Component, OnInit } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Md5 } from "md5-typescript";
import { ModalChoiceComponent } from "src/app/modals/modal-choice/modal-choice.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { AdminService } from "src/app/service/admin.service";
import { CompanyService } from "src/app/service/company.service";
import { ProcedureService } from "src/app/service/procedure.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { ModalProcedureSettingsComponent } from "./modal-procedure-settings/modal-procedure-settings.component";

@Component({
  selector: "app-procedure-settings",
  templateUrl: "./procedure-settings.component.html",
  styleUrls: ["./procedure-settings.component.css"],
})
export class ProcedureSettingsComponent implements OnInit {
  modalRef: MDBModalRef;
  loading: boolean = false;
  data: any[];

  titles = ["name", "time", "color", "edit"];

  constructor(
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private adminService: AdminService,
    private procedureService: ProcedureService
  ) {}

  ngOnInit(): void {
    this.getProcedures();
  }

  deleteForm(form) {
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja deletar esse procedimento?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.procedureService.deleteProcedures(form.id).subscribe(
          (data) => {
            this.openMessage("Sucesso", "Procedimento deletado", false);
            this.getProcedures();
          },
          (error) => {
            this.error(error, "deletar");
          }
        );
      }
    });
  }

  addProcedure() {
    this.openModalAdd();
  }

  editProcedure(p) {
    this.openModalAdd(p);
  }

  openModalAdd(ev = null) {
    this.modalRef = this.modalService.show(ModalProcedureSettingsComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: `${!ev ? "Adicionar" : "Editar"} procedimento`,
        btn_text: !ev ? "Adicionar" : "Editar",
        is_new: true,
        procedure: ev,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        if (!ev) {
          this.data = [...this.data, this.createData(result)];
        } else {
          const id = this.data.findIndex((r) => r.id === ev.id);
          const copy = this.data;
          copy[id] = this.createData(result);
          this.data = [...copy];
        }
      }
    });
  }

  createData(result) {
    return {
      name: result.name,
      time: result.time,
      color: result.color,
      id: result.id,
    };
  }

  getProcedures() {
    this.loading = true;
    this.procedureService.getProcedures().subscribe((data) => {
      this.data = [...data];
      this.loading = false;
    });
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível ${string} o formulário.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
