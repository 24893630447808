import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalChoiceComponent } from '../modals/modal-choice/modal-choice.component';
import { ModalMessageComponent } from '../modals/modal-message/modal-message.component';
import { InformationsService } from '../service/informations.service';
import { ModalAssociationComponent } from './association/modal-association.component';
import { ModalSpecialtyComponent } from './specialty/modal-specialty.component';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'app-informations',
    templateUrl: './informations.component.html',
    styleUrls: ['./informations.component.css']
})
export class InformationsComponent implements OnInit {

    constructor(
        private informationsService: InformationsService,
        private modalService: MDBModalService
    ) { }
    company_type = localStorage.getItem('companyType')
    displayedColumns1: string[] = ['name', 'options'];
    data_source1 = new MatTableDataSource<any>();

    displayedColumns2: string[] = ['name', 'options'];
    data_source2 = new MatTableDataSource<any>();

    panel1OpenState: boolean = false;
    panel2OpenState: boolean = false;

    modalRef: MDBModalRef;

    loaded = {specialities: false, association: false}

    @ViewChild(MatSort, { static: false }) sort: MatSort;
    
    ngOnInit(): void {
        this.getSpecialities()
        this.getAssociation()
    }

    getSpecialities(){
        this.loaded.specialities = false;
        this.informationsService.getSpecialty().subscribe( data => {
            this.data_source1.data = data;
            this.data_source1.sort = this.sort;
            this.loaded.specialities = true;
        })
    }

    getAssociation(){
        this.loaded.association = false;
        this.informationsService.getAssociation().subscribe( data => {
            this.data_source2.data = data;
            this.data_source2.sort = this.sort;
            this.loaded.association = true;
        })
    }

    detailSpeciality(event, is_new: boolean, name: string, id: number){
        event.stopPropagation()
        this.modalRef = this.modalService.show(ModalSpecialtyComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
				title: is_new ? "Adicionar especialidade" : "Editar especialidade",
				btn_text: is_new ? "Adicionar" : "Salvar",
				is_new: is_new,
                name: name,
                id: id
            }
        })
        this.modalRef.content.action.subscribe( (result: any) => { 
            if(result == "update"){
                this.getSpecialities()
			}
        });
    }

    detailAssociation(event, is_new: boolean, name: string, id: number){
        event.stopPropagation()
        this.modalRef = this.modalService.show(ModalAssociationComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
				title: is_new ? "Adicionar parceria" : "Editar parceria",
				btn_text: is_new ? "Adicionar" : "Salvar",
				is_new: is_new,
                name: name,
                id: id
            }
        })
        this.modalRef.content.action.subscribe( (result: any) => { 
            if(result == "update"){
                this.getAssociation()
			}
        });
    }

    delete(type: string, info){
        this.modalRef = this.modalService.show(ModalChoiceComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: "Tem certeza...",
                text: `de que deseja deletar a ${type} ${info.name}?`
            }
        })
        this.modalRef.content.action.subscribe( (result: any) => { 
            if(result == "yes"){
                this.openMessage("Por favor, aguarde", "", true)

                if(type == "parceria"){
                    this.informationsService.deleteAssociation(info.id).subscribe( data => {
                        this.modalRef.hide()
                        this.openMessage("Deletada!", "Parceria deletada com sucesso.", false)
                        this.getAssociation()
                    }, error => {
                        this.modalRef.hide()
                        this.error(error, 'deletar a parceria')
                    })
                }
                else if(type == "especialidade"){
                    this.informationsService.deleteSpecialty(info.id).subscribe( data => {
                        this.modalRef.hide()
                        this.openMessage("Deletada!", "Especialidade deletada com sucesso.", false)
                        this.getSpecialities()
                    }, error => {
                        this.modalRef.hide()
                        this.error(error, 'deletar a especialidade')
                    })
                }
			}
        });
    }

    deleted(type){
        
    }

    error(error, action){
        var msg = ""
        if(error.error != null && error.error.error != null && error.error.error.length < 50)
            msg = error.error.error;
        else
            msg = "Por favor verifique as informações inseridas"
        this.openMessage(`Erro, não foi possível ${action}.`, msg, false)
    }

    openMessage(title, text, bgclick: boolean){
        this.modalRef = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }
    
}