import { DatePipe } from "@angular/common";
import { Component, ViewChild } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { CompanyService } from "../../service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalFormCreateQuestionComponent } from "../modal-form-create-question/modal-form-create-question.component";
import { ModalChoiceComponent } from "../modal-choice/modal-choice.component";
import { ModalFormPermissionsComponent } from "../modal-form-permissions/modal-form-permissions.component";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ModalCorePlanPatientVinculateComponent } from "../modal-core-plan-patient-vinculate/modal-core-plan-patient-vinculate.component";
import { ModalCorePlanCloseComponent } from "../modal-core-plan-close/modal-core-plan-close.component";

@Component({
  selector: "modal-core-plan-patients",
  templateUrl: "./modal-core-plan-patients.component.html",
  styleUrls: ["./modal-core-plan-patients.component.css"],
})
export class ModalCorePlanPatientsComponent {
  url: string = "https://www.mmlpqtpkasjdashdjahd.com";
  urlSafe: SafeResourceUrl;
  constructor(
    public modalRef: MDBModalRef,
    public sanitizer: DomSanitizer,
    private modalService: MDBModalService,
    private companyService: CompanyService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";
  core_plan;
  loading: boolean = false;

  displayedColumns: string[] = [
    "id",
    "name",
    "dateStart",
    "dateEnd",
    "status",
    "reason",
    "icons",
  ];
  data_source = new MatTableDataSource<any>();

  /* pagination related variabels */
  page: number = 1;
  count_total: number = 0;
  page_count1: number = 0;
  page_count2: number = 0;
  page_size = 10;

  filterCorePlan = "all";

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  ngOnInit() {
    this.getPatients();
  }

  isEmpty(str) {
    if (!str || !str.length) {
      return true;
    }
    return false;
  }

  getPatients(page = 1) {
    this.loading = true;
    this.companyService
      .getPatientsForCorePlan(this.core_plan.id, this.filterCorePlan)
      .subscribe((data) => {
        this.data_source.data = data;
        this.data_source.sort = this.sort;
        // pagination calc
        this.page = page;
        this.count_total = data.length;
        this.page_count1 = 1 + (page - 1) * this.page_size;
        if (this.page_count1 + (this.page_size - 1) > this.count_total) {
          this.page_count2 = this.count_total;
        } else {
          this.page_count2 = this.page_count1 + (this.page_size - 1);
        }
        this.loading = false;
      });
  }

  submit() {}

  openMessage(title, text, bgclick: boolean = false) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  next() {
    if (this.page_count2 < this.count_total && !this.loading) {
      this.data_source.data = [];
      this.loading = true;
      this.getPatients(this.page + 1);
    }
  }

  prev() {
    if (this.page > 1 && !this.loading) {
      this.data_source.data = [];
      this.loading = true;
      this.getPatients(this.page - 1);
    }
  }

  editPatient(corePlanPatient) {
    this.modalRef2 = this.modalService.show(
      ModalCorePlanPatientVinculateComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: true,
        ignoreBackdropClick: true,
        class: "modal-dialog-centered modal-dialog modal-lg",
        containerClass: "",
        animated: false,
        data: {
          title: "Editar vínculo do paciente ao plano",
          btn_text: "Vincular",
          core_plan: this.core_plan,
          is_new: false,
          selected_patient: corePlanPatient.patient.id,
          start_date: corePlanPatient.start_date,
          end_date: corePlanPatient.end_date,
          is_continuous: corePlanPatient.is_continuous,
          core_plan_patient_id: corePlanPatient.id,
        },
      }
    );
    this.modalRef2.content.action.subscribe((result: any) => {
      if (result) {
        this.getPatients(this.page);
        this.modalRef2.hide();
      }
    });
  }

  deletePatient(corePlanPatient) {
    this.modalRef2 = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja deletar o vínculo do paciente ${corePlanPatient.patient.name}?`,
      },
    });
    this.modalRef2.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.openMessage("Por favor, aguarde", "", true);
        this.companyService.deleteCorePlanPatient(corePlanPatient.id).subscribe(
          (data) => {
            this.modalRef2.hide();
            this.openMessage(
              "Deletado!",
              "Vínculo deletado com sucesso.",
              false
            );
            this.getPatients(this.page);
          },
          (error) => {
            this.modalRef2.hide();
            this.error(error, "deletar");
          }
        );
      }
    });
  }
  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} o vínculo.`, msg, false);
  }
  addPatient() {
    this.modalRef2 = this.modalService.show(
      ModalCorePlanPatientVinculateComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: true,
        ignoreBackdropClick: true,
        class: "modal-dialog-centered modal-dialog modal-lg",
        containerClass: "",
        animated: false,
        data: {
          title: "Vincular pacientes ao plano",
          btn_text: "Vincular",
          core_plan: this.core_plan,
          is_new: true,
        },
      }
    );
    this.modalRef2.content.action.subscribe((result: any) => {
      if (result) {
        this.getPatients(this.page);
        this.modalRef2.hide();
      }
    });
  }

  getDate(date) {
    return date.split("T")[0].split("-").reverse().join("/");
  }

  changeFilter(filter) {
    this.filterCorePlan = filter;
    this.getPatients();
  }

  getStyle(cur) {
    if (this.filterCorePlan === cur) return "bold";
    return "normal";
  }

  getStatus(concluded, end_date) {
    if (concluded === true) return "Inativo";
    if (!end_date) return "Ativo";
    const today = new Date();
    const dateEnd = new Date(end_date);

    if (dateEnd >= today) return "Ativo";
    return "Pendente";
  }

  closeCorePlane(corePlan) {
    this.modalRef2 = this.modalService.show(ModalCorePlanCloseComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: "Fechar plano de cuidado",
        btn_text: "Vincular",
        core_plan_patient: corePlan,
      },
    });
    this.modalRef2.content.action.subscribe((result: any) => {
      if (result) {
        this.getPatients(this.page);
        this.modalRef2.hide();
      }
    });
  }
}
