import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MemedService {
  constructor(private http: HttpClient) {}

  getStatus(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + "core/doctor/status-memed/", options);
  }

  getPrescriptions(patient_id, start, end): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + `core/patient-prescriptions/by-doctor/?start_date=${start}&end_date=${end}&patient=${patient_id}`, options);
  }

  savePrescription(params, service_id = null): Observable<any> {
  
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.post<any>(AppConfig.path + `core/patient-prescriptions/create/${service_id ? '?service=' + service_id : ''}`, params, options);
  }
}
