import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DoctorService {
  constructor(private http: HttpClient) {}

  getDoctors(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    if (localStorage.getItem("company") !== null) {
      const company = localStorage.getItem("company");
      options = {
        ...options,
        params: {
          company,
        },
      };
    }

    return this.http.get<any>(
      AppConfig.path + "core/doctor/all-doctors/",
      options
    );
  }

  getDoctor(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + `core/patient/${id}/`, options);
  }

  getCompanies(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + `core/doctor/get-companies/`, options);
  }

  getCompanyById(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + `core/doctor/get-company-id/${id}/`, options);
  }

  newDoctor(doctor, password, birthday): Observable<any> {
    let body = new HttpParams()
      .append("password", password)
      .append("birthday", birthday);

    for (var key in doctor) {
      if (key != "password" && key != "birthday" && doctor[key] != null)
        body = body.append(key, doctor[key]);
    }

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + "core/doctor/create-doctor-panel/",
      body.toString(),
      { headers }
    );
  }

  vinculateDoctor(email, companyId): Observable<any> {
    let body = new HttpParams()
      .append("email", email)
      .append("companyId", companyId);

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + "core/doctor/vinculate-doctor-panel/",
      body.toString(),
      { headers }
    );
  }

  vinculateDoctorConfirmation(doctor, companyId): Observable<any> {
    let body = new HttpParams()
      .append("doctor", doctor)
      .append("companyId", companyId);

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    })

    return this.http.post<any>(
      AppConfig.path + "core/doctor/vinculate-doctor-panel-confirmation/",
      body.toString(),
      { headers }
    );
  }

  registerMemed(): Observable<any> {
    let body = new HttpParams();

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + "core/doctor/register-memed/",
      body.toString(),
      { headers }
    );
  }

  newDoctorRegister(params): Observable<any> {
    let body = new HttpParams();

    for (const key in params) {
      if (params[key] != null) {
        body = body.append(key, params[key]);
      }
    }

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    return this.http.post<any>(
      AppConfig.path + "core/doctor/create-doctor/",
      body.toString(),
      { headers }
    );
  }

  editDoctor(doctor, id, birthday): Observable<any> {
    let body = new HttpParams()
      .append("birthday", birthday)
      .append("doctor", id);

    for (var key in doctor) {
      if (key != "password" && key != "birthday" && doctor[key] != null)
        body = body.append(key, doctor[key]);
    }

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.patch<any>(
      AppConfig.path + `core/doctor/update-doctor/`,
      body.toString(),
      { headers }
    );
  }

  editDoctorActive(active, id): Observable<any> {
    let body = new HttpParams().append("doctor", id).append("active", active);

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.patch<any>(
      AppConfig.path + `core/doctor/update-doctor/`,
      body.toString(),
      { headers }
    );
  }

  deleteDoctor(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
      body: new HttpParams().append("doctor", id),
    };

    return this.http.delete<any>(
      AppConfig.path + `core/doctor/delete-doctor/`,
      options
    );
  }

  getTimeControl(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/timecontrol/doctor/${id}/`,
      options
    );
  }

  getPatientsByDoctor(doctor_id): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    if (localStorage.getItem("company") !== "null") {
      const company = localStorage.getItem("company");
      options = {
        ...options,
        params: {
          company,
        },
      };
    }

    return this.http.get<any>(
      AppConfig.path + `core/doctor/patient/${doctor_id}/`,
      options
    );
  }

  getIntegrationCode() {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/integrationcodedoctorandpatient/create-code/`,
      options
    );
  }

  getDoctorNotifications() {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/doctor-alarms/all/`,
      options
    );
  }

  updateAlarms(body) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.patch<any>(
      AppConfig.path + `core/doctor-alarms/update/`,
      body,
      options
    );
  }
}
