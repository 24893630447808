import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private http: HttpClient) {}
  getDataForPatients(filter = true): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path +
        `core/patient/data/` +
        (filter == true
          ? "?actived=true"
          : filter == false
          ? "?actived=false"
          : ""),
      options
    );
  }

  getDataForDoctors(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + `core/doctor/data/`, options);
  }

  getDataForForms(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + `core/form/data/`, options);
  }

  getDataForCorePlan(startDay, endDay): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/core-plan/data/?start=${startDay}&end=${endDay}`,
      options
    );
  }

  getDataForFormsAnswer(startDay, endDay, formId): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path +
        `core/form/data-for-dashboard/?start=${startDay}&end=${endDay}&form=${formId}`,
      options
    );
  }

  getDataForClinic(startDay, endDay): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/service/data/?start=${startDay}&end=${endDay}`,
      options
    );
  }
}
