import { FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

    constructor() { }

    selectedVideo = new FormControl(0);
    videos = [
        { 
            title: 'Monitoramento de sinais vitais', 
            text: 'Tecnologia para cuidar da sua saúde com segurança.', 
            button: 'Saiba mais', 
            video: '/assets/videos/paciente.mp4',
            link: '/patient' 
        },
        { 
            title: 'Aplicativo multiplataforma', 
            text: 'Tecnologia e saúde para a prevenção da sua saúde.', 
            button: 'Saiba mais', 
            video: '/assets/videos/banner.mp4',
            link: '/about' 
        },
        { 
            title: 'Plataforma exclusiva para médicos', 
            text: 'Acompanhamento individualizado e prevenção de graves eventos.', 
            button: 'Saiba mais', 
            video: '/assets/videos/draJulia.mp4',
            link: '/doctor' 
        }
    ]

    list_text_wheel = ['O indivíduo está sendo constantemente monitorado.', 
                      'O app informa suas variações vitais para si, seu responsável e seu médico.',
                      'O app induz o encaminhamento para o médico.',
                      'Gera dados para auxílio do médico, reduzindo o tempo de consulta.', 
                      'O paciente é medicado e monitoramos sua recuperação através de um acompanhamento remoto.']
    text_wheel: string = '';

    wheel: number = 5;

    ngOnInit(): void {
        this.text_wheel = this.list_text_wheel[4]

        document.getElementById('wheel1').style.opacity = "0";
        document.getElementById('wheel2').style.opacity = "0";
        document.getElementById('wheel3').style.opacity = "0";
        document.getElementById('wheel4').style.opacity = "0";
        document.getElementById('wheel5').style.opacity = "1";
    }

    setVideo(index) {
        this.selectedVideo.setValue(index)
    }

    setWheel() {
        if(this.wheel <= 4){
            document.getElementById(`wheel${this.wheel}`).style.opacity = "0";
            document.getElementById(`wheel${this.wheel+1}`).style.opacity = "1";
            this.wheel++;
            this.text_wheel = this.list_text_wheel[this.wheel-1]
            //console.log(this.text_wheel)
        }
        else if(this.wheel == 5){
            document.getElementById(`wheel${this.wheel}`).style.opacity = "0";
            document.getElementById(`wheel1`).style.opacity = "1";
            this.wheel = 1;
            this.text_wheel = this.list_text_wheel[this.wheel-1]
        }
    }

}
