import { Component } from '@angular/core';
// import * as template from '../../assets/html/terms.html';

@Component({
	selector: 'app-terms',
	templateUrl: './terms.component.html',
	styleUrls: ['./terms.component.css']
})
export class TermsComponent {

	constructor() {
		// //console.log(template)
	}
	company_type = localStorage.getItem('companyType')
	ngOnInit(){
	}

}
