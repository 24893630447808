import { Component } from '@angular/core';
// import * as template from '../../assets/html/terms.html';

@Component({
	selector: 'app-terms-rew',
	templateUrl: './terms-raw.component.html',
})
export class TermsRawComponent {

	constructor() {
		// //console.log(template)
	}

	ngOnInit(){
	}

}
