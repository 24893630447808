import { DatePipe } from "@angular/common";
import { Component, ViewChild } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { DoctorService } from "../../service/doctor.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { CompanyService } from "src/app/service/company.service";
import { ModalFormAnswerComponent } from "../modal-form-answer/modal-form-answer.component";
import { FormControl, FormGroup } from "@angular/forms";
import { MatSort } from "@angular/material/sort";
import { ModalFillFormComponent } from "../modal-fill-form/modal-fill-form.component";
import { ModalChoiceComponent } from "../modal-choice/modal-choice.component";

const formatDate = (date: string) => {
  const d = new Date(date);
  const datestring =
    ("0" + d.getDate()).slice(-2) +
    "/" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "/" +
    d.getFullYear() +
    " " +
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2);

  return datestring;
};

@Component({
  selector: "modal-form-answer-list",
  templateUrl: "./modal-form-answer-list.component.html",
  styleUrls: ["./modal-form-answer-list.component.css"],
})
export class ModalFormAnswerListComponent {
  constructor(
    public modalRef: MDBModalRef,
    private router: Router,
    private modalService: MDBModalService,
    private companyService: CompanyService,

    private datePipe: DatePipe
  ) {}

  displayedColumns: string[] = [];
  data_source = new MatTableDataSource<any>();

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";
  columns: Array<{ label: string; key: string }> = [];

  form_id: number = 0;
  form_title: string = "";

  validator = new ValidatorUtils();
  listAnswer = [];
  tamlist: number;
  loading = false;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  selected_question = null;
  form_questions = [];
  filterText = "";
  filter_alternative = null;
  filter_loading = false;
  filter_range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  questions_by_id = {};
  filteringByQuestion = false;
  answerFilter = [];
  loadingPatient = false;
  patients: any = [];
  patientsFull: any = [];
  selected_patient = null;

  form: any;
  from_patient: any;
  data_form_answers_by_id: any = {};
  patient_id: any;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  access_to_form_deletion: any = false;
  access_to_form_edition: any = false;

  ngOnInit() {
    const settings = localStorage.getItem("company_settings");

    if (settings) {
      const js = JSON.parse(settings);
      this.access_to_form_deletion = js.access_to_form_deletion;
      this.access_to_form_edition = js.access_to_form_edition;
    }

    this.title = `${this.title}`;
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    this.range.setValue({ start: startDate, end: new Date() });
    this.filter_range.setValue({ start: startDate, end: new Date() });

    if (this.patient_id) {
      this.selected_patient = this.patient_id;
    }
    this.getAnswer();
    this.getPatient();

    for (const q of this.form_questions) {
      this.questions_by_id[q.id] = q;
    }
    
  }

  getAnswer() {
    this.filteringByQuestion = false;
    if (!this.range.value.start || !this.range.value.end) return;
    let date = this.datePipe.transform(this.range.value.start, "yyyy-MM-dd");
    let dateEnd = this.datePipe.transform(this.range.value.end, "yyyy-MM-dd");
    // console.log(date, dateEnd);

    this.loading = true;
    this.companyService
      .getAnswerFormCompany(this.form_id, date, dateEnd)
      .subscribe(
        (data) => {
          this.listAnswer = data;
          this.tamlist = this.listAnswer.length;
          this.loading = false;
        },
        (error) => {
          //console.log(error);
        }
      );
  }

  getPatient() {
    this.loadingPatient = true;
    // console.log(date, dateEnd);

    this.companyService.getPatientsSimple().subscribe(
      (data) => {
        this.patients = data;
        this.patientsFull = data;
        this.loadingPatient = false;
        if(this.from_patient)
        this.makeFilterPatient();
      },
      (error) => {
        //console.log(error);
      }
    );
  }

  openAnswer(data) {
    this.companyService.getAnswerFormPatientByFormId(data.id).subscribe(
      (dataAnswers) => {
        //console.log("aaaaa");
        //console.log(dataAnswers);
        this.modalRef2 = this.modalService.show(ModalFormAnswerComponent, {
          backdrop: true,
          keyboard: true,
          focus: true,
          show: true,
          width: "100%",
          ignoreBackdropClick: true,
          class: "modal-dialog-centered modal-dialog modal-xl",
          StyleSheet: { width: "100% !important" },
          containerClass: "modal-answer",
          animated: false,
          data: {
            title: `Respostas do Formulário ${data.form.title}`,
            form_id: data.id,
            form_title: data.form.title,
            answers: dataAnswers,
          },
        });
      },
      (error) => {
        //console.log(error);
      }
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível ${string} a resposta.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  makeFilterPatient() {
    if (!this.selected_patient) {
      this.openMessage(
        "Erro",
        "Você precisa selecionar um paciente para filtrar",
        false
      );
      return;
    }
    this.filteringByQuestion = true;
    this.filter({ patient_id: this.selected_patient, is_patient: true });
  }

  makeFilter() {
    const body = {};
    this.filteringByQuestion = true;
    const q = this.questions_by_id[this.selected_question];
    if (!q) {
      this.openMessage(
        "Erro",
        "Você precisa selecionar uma pergunta para filtrar",
        false
      );
      return;
    }

    if (this.isText()) {
      if (!this.filterText || !this.filterText.length) {
        this.openMessage(
          "Erro",
          "Você precisa preencher o campo de texto para filtrar",
          false
        );
        return;
      }
      body["is_text"] = true;
      body["text"] = this.filterText;
    } else if (this.isSelectable()) {
      if (!this.filter_alternative) {
        this.openMessage(
          "Erro",
          "Você precisa selecionar uma alternativa para filtrar",
          false
        );
        return;
      }
      body["is_alternative"] = true;
      let value = "";
      for (const a of q.alternatives) {
        if (a.id == this.filter_alternative) {
          value = a.title;
          break;
        }
      }
      body["alternative"] = value;
    } else if (this.isDate()) {
      body["is_date"] = true;
      let date = this.datePipe.transform(
        this.filter_range.value.start,
        "yyyy-MM-dd"
      );
      let dateEnd = this.datePipe.transform(
        this.filter_range.value.end,
        "yyyy-MM-dd"
      );
      body["start_date"] = date;
      body["end_date"] = dateEnd;
    } else {
      this.openMessage(
        "Erro",
        "Tipo de pergunta não suportado para filtrar",
        false
      );
      return;
    }

    body["question_id"] = this.selected_question;

    this.filter(body);
  }

  filter(body) {
    this.filter_loading = true;
    this.companyService
      .getAnswerFormCompanyFilter(this.form_id, body)
      .subscribe(
        (data) => {
          this.data_form_answers_by_id = {};

          let columns = [];
          for (const q of data) {
            columns.push(q.form_question.title);
            if (!this.data_form_answers_by_id[q.form_answer.id]) {
              this.data_form_answers_by_id[q.form_answer.id] = [];
            }
            this.data_form_answers_by_id[q.form_answer.id].push(q);
          }
          columns = [...new Set(columns)];

          const byFormAnswerId = {};
          for (const a of data) {
            if (!byFormAnswerId[a.form_answer.id]) {
              byFormAnswerId[a.form_answer.id] = {
                patient: a.form_answer.patient.name,
                doctor: a.form_answer?.doctor?.name ?? "--",
                id: a.form_answer.id,
                columns: {},
              };
              for (const c of columns) {
                byFormAnswerId[a.form_answer.id].columns[c] = [];
              }
            }
            byFormAnswerId[a.form_answer.id].columns[
              a.form_question.title
            ].push(a.answer);
          }

          const ans = [];
          for (const b in byFormAnswerId) {
            ans.push(byFormAnswerId[b]);
          }

          this.tamlist = this.answerFilter.length;

          const ansTransformed = [];
          for (const a of ans) {
            const arr = [];
            arr.push(a.patient);
            arr.push(a.doctor);
            for (const c of columns) {
              arr.push(this.getData(a.columns[c]));
            }
            arr.push(a.id);
            ansTransformed.push(arr);
          }
          // console.log(ansTransformed);

          this.data_source.data = ansTransformed;
          this.data_source.sort = this.sort;
          this.answerFilter = ansTransformed;
          this.displayedColumns = [
            "Paciente",
            "Profissional",
            ...columns,
            "Ação",
          ];
          this.filter_loading = false;
        },
        (error) => {
          //console.log(error);
        }
      );
  }

  getData(data) {
    return data.reduce((acc, element) => {
      let ans = acc;

      if (ans.length) ans += "<br/>";

      if (element.startsWith("data:image/") && element.includes("base64")) {
        ans += `<img
            src="${element}"
            alt="resposta"
            width="100"
        
          />`;
      } else {
        ans += element;
      }

      return ans;
    }, "");
  }

  isText() {
    const q = this.questions_by_id[this.selected_question];
    if (!q) return false;
    if (
      !q.is_date &&
      !q.is_frequency &&
      !q.is_image &&
      !q.is_list &&
      !q.is_numeric &&
      !q.is_numeric_scale &&
      !q.is_scale &&
      !q.is_selectable
    )
      return true;

    if (q.is_frequency || q.is_numeric) return true;

    return false;
  }

  isSelectable() {
    const q = this.questions_by_id[this.selected_question];
    if (!q) return false;
    if (q.is_list || q.is_selectable) return true;
    return false;
  }
  isDate() {
    const q = this.questions_by_id[this.selected_question];
    if (!q) return false;
    if (q.is_date) return true;
    return false;
  }

  accentFold(inStr) {
    return inStr.replace(
      /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g, 
      function (str, a, c, e, i, n, o, s, u, y, ae) {
        if (a) return 'a';
        if (c) return 'c';
        if (e) return 'e';
        if (i) return 'i';
        if (n) return 'n';
        if (o) return 'o';
        if (s) return 's';
        if (u) return 'u';
        if (y) return 'y';
        if (ae) return 'ae';
      }
    );
  }


  selectSearchPatient(value: string) {
    this.patients = [];
    let filter = this.accentFold(value.toLowerCase());
    for (let i = 0; i < this.patientsFull.length; i++) {
      let option = this.patientsFull[i];
      if (this.accentFold(option.name.toLowerCase()).indexOf(filter) >= 0) {
        this.patients.push(option);
      }
    }
  }

  onTabChanged(ev) {
    this.loading = false;
    this.loadingPatient = false;
    this.filter_loading = false;
    this.filteringByQuestion = false;
    this.answerFilter = [];
    this.listAnswer = [];

    if (this.from_patient && ev == 1) {
      this.makeFilterPatient();
    }
  }

  fillFom() {
    this.modalRef = this.modalService.show(ModalFillFormComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: this.form_title,
        form: this.form,
        patient_id: this.selected_patient,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.makeFilterPatient();
      }
    });
  }

  deleteAnswer(answer) {
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja deletar essa resposta?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        console.log("Apagar");
        this.companyService.deleteFormAnswerById(answer.slice(-1)[0]).subscribe(
          (data) => {
            this.openMessage("Sucesso", "Diagnóstico deletado", false);
            if (this.selected_patient) this.makeFilterPatient();
            else this.makeFilter();
          },
          (error) => {
            this.error(error, "deletar");
          }
        );
      }
    });
  }

  editAnswer(answer) {
    this.modalRef = this.modalService.show(ModalFillFormComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: this.form_title,
        form: this.form,
        patient_id: this.selected_patient,
        form_answer_question: this.data_form_answers_by_id[answer.slice(-1)[0]],
        form_answer_id: answer.slice(-1)[0],
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.makeFilterPatient();
      }
    });
  }
}
