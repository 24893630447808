import { Component } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { ModalMessageComponent } from 'src/app/modals/modal-message/modal-message.component';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { InformationsService } from 'src/app/service/informations.service';

@Component({
  	selector: 'modal-association',
  	templateUrl: './modal-association.component.html',
  	styleUrls: ['./modal-association.component.css']
})

export class ModalAssociationComponent {

	constructor(
        public modalRef: MDBModalRef, 
        private modalService: MDBModalService,
        private informationsService: InformationsService,
    ) { }

	action: Subject<any> = new Subject();
    modalRef2: MDBModalRef;
    
    title: string = "";
    btn_text: string;
    is_new: boolean = true;

    name: string
    id: number;
    validator = new ValidatorUtils();

	ngOnInit() {
        
    }
    
    check(){
        if(this.validator.isEmptyOrNull(this.name)){
            this.openMessage("Por favor,", "insira o nome da parceria.", false);
            return false
        }
        else{
            if(this.is_new)
                this.create()
            else
                this.edit()
        }
    }

    create(){
        this.openMessage("Por favor, aguarde", "", true)
        this.informationsService.newAssociation(this.name).subscribe( data => {
            this.modalRef2.hide()
            this.openMessage("Criado!", "Parceria criada com sucesso.", false)
            this.action.next('update');
            this.modalRef.hide()
        }, error => {
            this.modalRef2.hide()
            this.error(error, 'criar')
        })
    }

    edit(){
        this.openMessage("Por favor, aguarde", "", true)
        
        this.informationsService.editAssociation(this.name, this.id).subscribe( data => {
            this.modalRef2.hide()
            this.openMessage("Salvo!", "Parceria editada com sucesso.", false)
            this.action.next('update');
            this.modalRef.hide()
        }, error => {
            this.modalRef2.hide()
            this.error(error, 'editar')
        })
    }

	error(error, string){
        var msg = ""
        if(error.error != null && error.error.error != null && error.error.error.length < 50)
            msg = error.error.error;
        else
            msg = "Por favor verifique as informações inseridas"
        this.openMessage(`Erro, não foi possível ${string} a parceria.`, msg, false)
    }

	openMessage(title, text, bgclick: boolean){
        this.modalRef2 = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }

}