import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.css"],
})
export class AboutComponent implements OnInit {
  constructor() {}

  selected_text: number = 0;

  options: string[] = [
    "Nossa Proposta",
    "A Plataforma",
    "Conveniência",
    "Integração",
  ];

  texts: string[] = [
    "A equipe Medical Angel é formada por especialistas em Tecnologia e tem como \
    missão desenvolver e aprimorar sistemas que visam a preservação e prevenção da \
    saúde, bem como apoiar empresas e profissionais ligados à esta área fornecendo \
    ferramentas e soluções que facilitem o acompanhamento e monitoramento de seus \
    clientes e pacientes.",
    "A plataforma Medical Angel é um hub de informações aferidas por diversos Apps de monitoramento da saúde, especialistas em suas áreas, as quais são expostos de forma organizada e controladas por limites máximos e mínimos dos registros e períodos estabelecidos pelo seu médico, além de alertar seu responsável (familiar) cadastrado.",
    "Nossos usuários podem estar em qualquer lugar mantendo o monitoramento ativo com conforto e qualidade. Profissionais de saúde poderão acessar remotamente os dados de seus clientes e pacientes quando desejarem ou quando alertados pela plataforma. O Medical Angel oferece conforto, segurança, qualidade, redução de tempo em casos de urgência, agilidade e monitoração dos mais diversos Apps com suas especialidades, tudo em um só lugar.",
    "O sistema está apto para receber e se comunicar com aplicativos, seja por IOS ou Android. As informações são recebidas na plataforma digitalmente via Bluetooth ou sistema analógico inserindo os dados manualmente, os quais receberão o mesmo tratamento pela plataforma. O Medical Angel coleta e organiza todas as informações para que, tanto o usuário quanto o médico tenham tudo em um só lugar, no Medical Angel Universal.",
  ];

  ngOnInit(): void {}
}
