import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { UserService } from "src/app/service/user.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import { DoctorService } from "src/app/service/doctor.service";
import { ModalChoiceComponent } from "../modal-choice/modal-choice.component";
import { ModalPatientResponsableDetailComponent } from "../modal-patient-responsable-detail/modal-patient-responsable-detail.component";

@Component({
  selector: "modal-patient-responsables",
  templateUrl: "./modal-patient-responsables.component.html",
  styleUrls: ["./modal-patient-responsables.component.css"],
})
export class ModalPatientResponsablesComponent {
  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private userService: UserService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private doctorService: DoctorService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";
  btn_text: string;
  is_new: boolean = true;

  patient_clone;

  patient = {
    id: null,

    name: null,
    email: null,
    birthday: null,
    gender: null,
    company: null,
    phone: null,
    number: null,
    cep: null,
    city: null,
    complement: null,
    neighborhood: null,
    street: null,
    state: null,
    user: null,
  };

  validator = new ValidatorUtils();
  responsables = [];
  loading = false;
  ngOnInit() {
    if (this.patient_clone != null && !this.is_new) {
      for (var key in this.patient) {
        if (key == "cpf") this.patient[key] = this.patient_clone.user[key];
        else if (key == "birthday") {
          this.patient[key] = `${this.patient_clone[key].substring(
            8,
            10
          )}/${this.patient_clone[key].substring(5, 7)}/${this.patient_clone[
            key
          ].substring(0, 4)}`;
        } else if (key != "password" && key !== "company")
          this.patient[key] = this.patient_clone[key];
        else if (key === "company")
          this.patient[key] = this.patient_clone[key].id;
      }
    }
    this.getResponsables();
  }

  errorBack(error, string) {
    var msg = error;

    this.openMessage(
      `Erro, não foi possível ${string} o paciente.`,
      msg,
      false
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível ${string} o responsável do paciente.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "modal-lg",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  getResponsables() {
    this.loading = true;
    this.userService
      .getPatientResponsable(this.patient.id)
      .subscribe((data) => {
        this.responsables = data;
        this.loading = false;
      });
  }

  addResponsible() {
    this.modalRef = this.modalService.show(
      ModalPatientResponsableDetailComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: true,
        ignoreBackdropClick: true,
        class: "modal-dialog-centered modal-dialog modal-lg modal-size",
        containerClass: "",
        animated: false,
        data: {
          title: "Adicionar Responsável",
          btn_text: "Adicionar",
          is_new: true,
          patient_id: this.patient.id,
        },
      }
    );
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getResponsables();
      }
    });
  }

  editResponsable(responsable) {
    this.modalRef = this.modalService.show(
      ModalPatientResponsableDetailComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: true,
        ignoreBackdropClick: true,
        class: "modal-dialog-centered modal-dialog modal-lg modal-size",
        containerClass: "",
        animated: false,
        data: {
          title: "Editar Responsável",
          btn_text: "Salvar",
          is_new: false,
          responsable_clone: responsable,
        },
      }
    );
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getResponsables();
      }
    });
  }

  deleteResponsable(item) {
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja deletar esse responsável?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        console.log("Apagar");
        this.userService.removeResponsable(item.id).subscribe(
          (data) => {
            this.openMessage("Sucesso", "Responsável deletado", false);
            this.getResponsables();
          },
          (error) => {
            this.error(error, "deletar");
          }
        );
      }
    });
  }
}
