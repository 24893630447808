import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) {}

  getConstants(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + "core/constants/", options);
  }

  login(cpf, password): Observable<any> {
    const body = new HttpParams()
      .set(`username`, cpf)
      .set(`password`, password);

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    return this.http.post<any>(AppConfig.path + "core/auth/", body.toString(), {
      headers,
    });
  }

  newUser(cpf, password): Observable<any> {
    let body = new HttpParams().append("password", password).append("cpf", cpf);

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(AppConfig.path + "core/user/", body.toString(), {
      headers,
    });
  }

  getDoctor(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + "core/doctor/", options);
  }

  getUser(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + "core/user/current", options);
  }

  getPatients(filter = true): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    if (localStorage.getItem("company") !== "null") {
      const company = localStorage.getItem("company");
      options = {
        ...options,
        params: {
          company,
        },
      };
    }

    return this.http.get<any>(
      AppConfig.path +
        "core/doctor/patients/" +
        (filter == true
          ? "?actived=true"
          : filter == false
          ? "?actived=false"
          : ""),
      options
    );
  }

  getPatientsPagination(
    page,
    filter = true,
    searchText = null
  ): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    if (localStorage.getItem("company") !== "null") {
      const company = localStorage.getItem("company");
      const params = {
        company,
        page,
      };
      if (searchText) {
        params["search"] = searchText;
      }
      options = {
        ...options,
        params,
      };
    }

    return this.http.get<any>(
      AppConfig.path +
        "core/doctor/patients-pagination/" +
        (filter == true
          ? "?actived=true"
          : filter == false
          ? "?actived=false"
          : ""),
      options
    );
  }

  getPatient(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(AppConfig.path + `core/patient/${id}/`, options);
  }

  getPatientDoctors(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };

    return this.http.get<any>(
      AppConfig.path + `core/patient-doctor/by-patient/${id}/`,
      options
    );
  }

  getExams(page, date_from, date_to, patient = null): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
      params: new HttpParams()
        .set("page_size", "9999")
        .set("page", page)
        .set("start_date_time__gte", date_to)
        .set("end_date_time__lte", date_from),
    };
    let url = `core/my-exam/`;

    if (patient) {
      url += `patient/${patient}/`;
    }
    return this.http.get<any>(AppConfig.path + url, options);
  }

  getTypeExams(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };
    let url = `core/type-exam/`;
    return this.http.get<any>(AppConfig.path + url, options);
  }

  insertExam(form, service_id = null): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(AppConfig.path + `core/my-exam/create/${service_id ? '?service=' + service_id : ''}`, form, {
      headers,
    });
  }

  getMedicationSchedule(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token 6b5d63eef85fcb5f073f73980d97eaac83bd67e3`),
    };
    return this.http.get<any>(
      AppConfig.path + `core/schedule-medication/medication-alerts/`,
      options
    );
  }

  getPatientRecord(id, page, date_from, date_to): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
      params: new HttpParams()
        .set("page_size", "9999")
        .set("patient", id)
        .set("page", page)
        .set("date_to", date_to)
        .set("date_from", date_from),
    };

    return this.http.get<any>(
      AppConfig.path + "core/doctor/patient-record/",
      options
    );
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = Observable.create((observer: any) => {
      reader.onloadend = (e) => {
        observer.error(JSON.parse(reader.result as string));
        observer.complete();
      };
    });
    //reader.readAsText(err.error);
    obs.subscribe((data) => {
      console.log(data);
    });
    return obs;
  }
  getPatientRecordPDF(id, page, date_from, date_to, columns): Observable<any> {
    const options = {
      headers: new HttpHeaders({}).set(
        "Authorization",
        `token ${localStorage.getItem("token")}`
      ),
      params: new HttpParams()
        .set("page_size", "9999")
        .set("patient", id)
        .set("page", page)
        .set("date_to", date_to)
        .set("date_from", date_from)
        .set("columns", columns),
    };

    return this.http.get<any>(
      AppConfig.path + "core/doctor/patient-record-file/",
      options
    );
  }

  getPatientRecordCSV(id, page, date_from, date_to, columns): Observable<any> {
    const options = {
      headers: new HttpHeaders({}).set(
        "Authorization",
        `token ${localStorage.getItem("token")}`
      ),
      params: new HttpParams()
        .set("page_size", "9999")
        .set("patient", id)
        .set("page", page)
        .set("date_to", date_to)
        .set("date_from", date_from)
        .set("columns", columns),
    };

    return this.http.get<any>(
      AppConfig.path + "core/doctor/patient-record-file-csv/",
      options
    );
  }

  createPatient(patient, password, birthday): Observable<any> {
    let body = new HttpParams()
      .append("password", password)
      .append("birthday", birthday);

    for (var key in patient) {
      if (key != "password" && key != "birthday")
        body = body.append(key, patient[key]);
    }

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + "core/doctor/add-patient/",
      body.toString(),
      { headers }
    );
  }

  createRecord(form, patient_id, service_id = null): Observable<any> {
    let body = new HttpParams();

    for (var key in form) {
      body = body.append(key, form[key]);
    }
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.post<any>(
      AppConfig.path + `core/health-record/bydoctor/${patient_id}/${service_id ? '?service=' + service_id : ''}`,
      body.toString(),
      { headers }
    );
  }

  updatePatient(form, patient_id): Observable<any> {
    let body = {};

    for (var key in form) {
      body[key] = form[key];
    }
    console.log(body);
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=UTF-8",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.patch<any>(
      AppConfig.path + `core/doctor/update-patient-health/${patient_id}/`,
      body,
      { headers }
    );
  }

  recoveryPassword(body): Observable<any> {
    
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=UTF-8",
    });

    return this.http.post<any>(
      AppConfig.path + `core/patient/forgot-password/`,
      body,
      { headers }
    );
  }

  editPatient(id, patient, birthday): Observable<any> {
    let body = new HttpParams()
      .append("birthday", birthday)
      .append("patient", id);

    for (var key in patient) {
      if (key != "password" && key != "birthday")
        body = body.append(key, patient[key]);
    }

    body.append("is_active", patient.user.is_active);

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.patch<any>(
      AppConfig.path + "core/doctor/update-patient/",
      body.toString(),
      { headers }
    );
  }

  editPatientActived(id, is_active): Observable<any> {
    let body = new HttpParams().append("is_active", is_active);

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }).set("Authorization", `token ${localStorage.getItem("token")}`);

    return this.http.patch<any>(
      AppConfig.path + "core/doctor/update-patient-actived/" + id + "/",
      body.toString(),
      { headers }
    );
  }

  changeForgottenPassword(password, cpf, hash) {
    const body = new HttpParams()
      .append(`new_password`, password)
      .append(`cpf`, cpf)
      .append(`forgot_password_hash`, hash);

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded;",
    });

    return this.http.post<any>(
      AppConfig.path + "core/patient/change-forgotten-password/",
      body,
      { headers }
    );
  }

  deleteAccount(password, cpf, hash) {
    const body = new HttpParams()
      .append(`password`, password)
      .append(`cpf`, cpf)
      .append(`delete_account_hash`, hash);

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded;",
    });

    return this.http.post<any>(
      AppConfig.path + "core/user/confirm_delete_account/",
      body,
      { headers }
    );
  }

  getPatientResponsable(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };
    let url = `core/responsible/patient/${id}/`;
    return this.http.get<any>(AppConfig.path + url, options);
  }

  removeResponsable(id) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };
    let url = `core/responsible/remove/${id}/`;
    return this.http.delete<any>(AppConfig.path + url, options);
  }

  editResponsable(id, body) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };
    let url = `core/responsible/edit/${id}/`;
    return this.http.patch<any>(AppConfig.path + url, body, options);
  }

  createResponsable(patient, body) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).set("Authorization", `token ${localStorage.getItem("token")}`),
    };
    let url = `core/responsible/create/${patient}/`;
    return this.http.post<any>(AppConfig.path + url, body, options);
  }
}
