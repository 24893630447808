import { Component, OnInit } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Md5 } from "md5-typescript";
import { ModalChoiceComponent } from "src/app/modals/modal-choice/modal-choice.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { AdminService } from "src/app/service/admin.service";
import { CompanyService } from "src/app/service/company.service";
import { ProcedureService } from "src/app/service/procedure.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { ModalServiceTypeSettingsComponent } from "./modal-service-type-settings/modal-service-type-settings.component";
import { ServiceService } from "src/app/service/service.service";

@Component({
  selector: "app-service-type-settings",
  templateUrl: "./service-type-settings.component.html",
  styleUrls: ["./service-type-settings.component.css"],
})
export class ServiceTypeSettingsComponent implements OnInit {
  modalRef: MDBModalRef;
  loading: boolean = false;
  data: any[];

  titles = ["name", "services", "forms_name", "edit"];

  constructor(
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private adminService: AdminService,
    private serviceService: ServiceService
  ) {}

  ngOnInit(): void {
    this.getServices();
  }

  deleteForm(form) {
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja deletar esse tipo de atendimento?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.serviceService.deleteServiceTypes(form.id).subscribe(
          (data) => {
            this.openMessage("Sucesso", "Tipo de atendimento deletado", false);
            this.getServices();
          },
          (error) => {
            this.error(error, "deletar");
          }
        );
      }
    });
  }

  addService() {
    this.openModalAdd();
  }

  editService(p) {
    this.openModalAdd(p);
  }

  openModalAdd(ev = null) {
    this.modalRef = this.modalService.show(ModalServiceTypeSettingsComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: `${!ev ? "Adicionar" : "Editar"} tipo de atendimento`,
        btn_text: !ev ? "Adicionar" : "Editar",
        is_new: true,
        service: ev,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        if (!ev) {
          this.data = [...this.data, this.createData(result)];
        } else {
          const id = this.data.findIndex((r) => r.id === ev.id);
          const copy = this.data;
          copy[id] = this.createData(result);
          this.data = [...copy];
        }
      }
    });
  }

  createData(result) {
    let services = "";
    if (result.use_health_record) {
      services = "Sinais vitais";
    }
    if (result.use_medication) {
      if (services.length > 0) services += ", ";
      services += "Medicamentos";
    }
    if (result.use_exam) {
      if (services.length > 0) services += ", ";
      services += "Exames";
    }
    if (result.use_prescription) {
      if (services.length > 0) services += ", ";
      services += "Prescrições";
    }

    if (services.length === 0) {
      services = "Nenhum serviço";
    }

    let forms_name = "";
    for (const f of result.forms) {
      if (forms_name.length > 0) forms_name += ", ";
      forms_name += f.title;
    }

    if (forms_name.length === 0) {
      forms_name = "Nenhum formulário";
    }
    return {
      name: result.name,
      services,
      forms_name,
      id: result.id,
      forms: result.forms,
      use_health_record: result.use_health_record,
      use_medication: result.use_medication,
      use_exam: result.use_exam,
      use_prescription: result.use_prescription,
    };
  }

  getServices() {
    this.loading = true;
    this.serviceService.getServiceTypes().subscribe((data) => {
      const ans = [];
      for (const d of data) ans.push(this.createData(d));
      this.data = ans;
      this.loading = false;
    });
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível ${string} o tipo de atendimento.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
