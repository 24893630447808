import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { CompanyService } from "../service/company.service";
import { MatSort } from "@angular/material/sort";
import { SofyaService } from "../service/sofya.service";
import { ModalCompanySettingsAdminComponent } from "./modal-company-settings-admin/modal-company-settings-admin.component";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.css"],
})
export class AdminComponent implements OnInit {
  loading: boolean = false;
  modalRef: MDBModalRef;
  numberOfUseSofya: any[];
  companiesUsers: [];
  companiesDoctors: [];
  companies: any[];
  loadingCompany: boolean = false;
  loadingDoctors: boolean = false;
  titlesCompanies = ["company", "patients", "patientsNot", "doctors", "edit"];
  titlesSofya = ["company", "total"];
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  company_type = localStorage.getItem('companyType')
  constructor(
    private sofyaService: SofyaService,
    private modalService: MDBModalService,
    private companyService: CompanyService,

    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.range.setValue({ start: new Date(), end: new Date() });
    this.getSofya();
    this.getAllCompanies();
  }

  getSofya() {

    let date = this.datePipe.transform(this.range.value.start, "yyyy-MM-dd");
    let dateEnd = this.datePipe.transform(this.range.value.end, "yyyy-MM-dd");
    let count = 0;

    if (!date || !dateEnd) {
      return;
    }

    this.loading = true;
    this.sofyaService.getUsage(date, dateEnd).subscribe((data) => {
      let sum = 0;
      for (const d of data) {
        sum += d.total;
      }

      this.numberOfUseSofya = [...data, { name: "TOTAL DE USO", total: sum }];
      this.loading = false;
    });
  }

  getAllCompanies() {
    this.loadingCompany = true;
    this.companyService
      .getUsersAndDoctorsFromAllCompanies()
      .subscribe((data) => {
        
        let sumPatient = 0;
        let sumPatientNot = 0;
        let sumDoctor = 0;
        for (const d of data) {
          sumPatient += d.patients;
          sumPatientNot += d.patientsNot;
          sumDoctor += d.doctors;
        }

        this.companies = [
          ...data,
          {
            name: "TOTAL",
            patients: sumPatient,
            patientsNot: sumPatientNot,
            doctors: sumDoctor,
            
          },
        ];

        this.loadingCompany = false;
      });
  }

  editCompany(company) {
    console.log(company)
    this.modalRef = this.modalService.show(ModalCompanySettingsAdminComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size modal-xl",
      containerClass: "",
      animated: false,
      data: {
        title: "Editar informações da empresa",
        btn_text: "Salvar",
        
        company
      },
    });
  }
}
