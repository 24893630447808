import { Injectable } from "@angular/core";

declare var document: any;
declare var MdSinapsePrescricao: any;
declare var MdHub: any;

@Injectable()
export class PrescricoesService {
  loaded = false;

  load(token, patient, doctor, memedService, onPrescription) {

    document.getElementById("buttonAddPrescription").disabled = true;
    document.getElementById("divLoading").style.display = "flex";
    if(this.loaded) {
      this.makePrescription(patient, doctor, token, memedService, onPrescription);
      return;
    }
    
    let script = document.createElement("script");
    let idScript = "sinapseMemed";

    script.type = "text/javascript";
    script.id = idScript;
    script.src =
      "https://memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js";
    script.setAttribute("data-token", token);
    script.setAttribute("data-color", "#6610f2");
    script.setAttribute("color", "#6610f2");
    // script.setAttribute('data-container', 'ID-DO-CONTAINER');

    script.onload = () => {
      this.loaded = true;
      this.initEventsMemed(patient, doctor, token, memedService, onPrescription);
    };
    document.body.appendChild(script);
  }

  initEventsMemed(patient, doctor, token, memedService, onPrescription) {
    
    MdSinapsePrescricao.event.add("core:moduleInit", (module) => {
    
      // O módulo da prescrição foi iniciado.
      if (module.name === "plataforma.prescricao") {
        this.makePrescription(patient, doctor, token, memedService, onPrescription)
      }
    });
  }

  makePrescription(patient, doctor, token, memedService, onPrescription) {
      // AQUI VOCE PODE REMOVER O LOADER DE ALGUM BOTÃO
        // AQUI VOCE PODE ADICIONAR UM LISTENER NO BOTÃO PARA ABRIR A PRESCRICAO.
        //console.log("aqui");
        const birthday = patient.birthday.split("-").reverse().join("/");
        //console.log(doctor);
        let address = "";
        if (patient.street) {
          address = patient.street + " " + patient.number;
        }
        let address2 = "";
        if (doctor.street) {
          address2 = doctor.street + " " + doctor.number;
        }

        MdHub.event.add('prescricaoImpressa', function(prescriptionData) {
          // No objeto "prescriptionData", é retornado as informações da prescrição gerada.
          // Implementar ações, callbacks, etc.
          onPrescription(prescriptionData, token, patient.id, doctor.id, memedService)
        });

        MdHub.command
          .send("plataforma.prescricao", "setPaciente", {
            nome: patient.name,
            idExterno: patient.id,
            cpf: patient.user.cpf,
            data_nascimento: birthday,
            endereco: address,
            cidade: patient.city || "",
          })
          .then(() => {
            MdHub.command
              .send("plataforma.prescricao", "setWorkplace", {
                city: doctor.city,
                state: doctor.state,
                address: address2,
                phone: doctor.phone,
              })
              .then(() => {
              
                MdHub.module.show("plataforma.prescricao");
                document.getElementById("buttonAddPrescription").disabled =
                  false;
                document.getElementById("divLoading").style.display = "none";
              });
          });

      
        // MdHub.command
        //   .send(module.name, "setPaciente", {
        //     nome: "José da Silva",
        //     endereco: "Rua da Saúde, 123",
        //     cidade: "São Paulo",
        //     telefone: "11960467098",
        //     peso: 75,
        //     altura: 1.8,
        //     nome_mae: "Nome da mãe",
        //     dificuldade_locomocao: true,
        //     idExterno: "some_encoded_data_or_hash_or_id",
        //   })
        //   .then(() => {
        //     MdHub.module.show(module.name);
        //   });
  }
}
