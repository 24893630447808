import { DatePipe } from "@angular/common";
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChildren,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Chart } from "chart.js";
import { Subject } from "rxjs";
import { ModalFillFormComponent } from "src/app/modals/modal-fill-form/modal-fill-form.component";
import { ModalFormAnswerComponent } from "src/app/modals/modal-form-answer/modal-form-answer.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { ModalSelectFormComponent } from "src/app/modals/modal-select-form/modal-select-form.component";
import { CompanyService } from "src/app/service/company.service";
import { MedicationService } from "src/app/service/medication.service";
import { UserService } from "src/app/service/user.service";

@Component({
  selector: "app-formulario",
  templateUrl: "./formulario.component.html",
  styleUrls: ["./formulario.component.css"],
})
export class FormularioComponent {
  @ViewChildren("mycharts") allMyCanvas: any;
  constructor(
    private companyService: CompanyService,
    private medicationService: MedicationService,
    private modalService: MDBModalService,
    private datePipe: DatePipe,
    private userService: UserService
  ) {}

  action: Subject<any> = new Subject();
  msg_error: string = "";
  loading: boolean = false;
  loadingRecords: boolean = false;
  loadingDownload: boolean = false;
  modalRef: MDBModalRef;
  title: string = "";
  btn_text: string;
  is_new: boolean = true;
  @Input() patient_id: number;
  @Input() history: boolean;

  @Input() form_id: number;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  week = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };

  selectedForms = [];
  fieldsChecked = {};
  allForms = [];
  selectedMedication;
  listAnswer = [];
  tamlist: number = 0;
  medication;
  formatedForms = null;
  formTitles = null;
  dataSourcesTable = [];

  titleRecords = [];
  dataSourceRecords = [];
  mode_table = false;

  public charts: {};
  public legends: {};
  public nameQuestions: {};
  public infosQuestions: {};
  public positionAnswers: {};
  public toTable: {};
  public toTableParameter: {};

  public chartParameters: {} = {};
  public parameters = [
    "sis",
    "dia",
    "glucose",
    "temperature",
    "heartbeat",
    "oxygenation",
    "respiratoryFrequency",
  ];
  public parametersName = [
    "Sistólica",
    "Diastólica",
    "Glicose",
    "Temperatura",
    "Frequência cardíaca",
    "Saturação",
    "Frequência respiratória",
  ];

  scoreScalesByFormId: any = {};

  ngOnInit(): void {

    this.range.setValue({ start: new Date(), end: new Date() });
    if (this.allForms.length > 1) {
      this.getAnswerFormPatient(this.patient_id);
    } else {
      this.getForms(this.patient_id);
    }
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   this.patient_id = changes["patient_id"].currentValue;
  //   this.getForms(this.patient_id);
  // }

  changeFields(ev) {
    // this.getAnswerFormPatient(this.patient_id)
    // console.log('miudando')
  }

  getForms(patient) {
    this.loading = true;
    this.loadingRecords = true;
    this.companyService.getFormsFromCompany(patient).subscribe((data) => {
      const aux = data.map((d) => {
        return {
          name: d.title,
          value: d.id,
        };
      });
      const auxSelect = ["sinal", ...data.map((d) => d.id)];
      const auxFields = { sinal: true };
      for (const d of data) auxFields[d.id] = true;
      this.allForms = aux;
      
      this.selectedForms = auxSelect;
      this.fieldsChecked = auxFields;
      if (this.history) {
        this.selectedForms = [this.form_id];
        this.fieldsChecked = {};
        this.fieldsChecked['sinal'] = false;
        this.fieldsChecked[this.form_id] = true;
        
      }
      this.getAnswerFormPatient(patient);
    });
  }

  getAnswerFormPatient(id) {
    let date = this.datePipe.transform(this.range.value.start, "yyyy-MM-dd");
    let dateEnd = this.datePipe.transform(this.range.value.end, "yyyy-MM-dd");
    if (!date || !dateEnd) return;
    this.loading = true;
    this.loadingRecords = true;
    this.formatedForms = null;
    this.scoreScalesByFormId = {};
    this.getHealthRecords();
    this.companyService
      .getAnswerFormPatient(id, date, dateEnd, this.selectedForms)
      .subscribe(
        (data) => {
          this.listAnswer = data;
          this.tamlist = this.listAnswer.length;
          this.initScoreData(data);
          const forms = {};
          const titles = {};
          for (let d of data) {
            titles[d.form_answer.form.id] = d.form_answer.form;

            if (!forms[d.form_answer.form.id]) {
              forms[d.form_answer.form.id] = [];
            }
            if (!forms[d.form_answer.form.id][d.form_question.id]) {
              forms[d.form_answer.form.id][d.form_question.id] = {
                items: {},
                title: d.form_question.title,
              };
            }

            const data = d.form_answer.created_at.split("T")[0];

            if (!forms[d.form_answer.form.id][d.form_question.id].items[data])
              forms[d.form_answer.form.id][d.form_question.id].items[data] = [];

            forms[d.form_answer.form.id][d.form_question.id].items[data].push({
              answer: d.answer,
              form_id: d.form_answer.form.id,
              created_at: d.created_at,
              position: d.form_answer.form.position,
              is_numeric: d.form_question.is_numeric,
              is_numeric_scale: d.form_question.is_numeric_scale,
              is_selectable: d.form_question.is_selectable,
              number_of_choices: d.form_question.number_of_choices,
              is_list: d.form_question.is_list,
              is_image: d.form_question.is_image,

              is_date: d.form_question.is_date,
              type_date: d.form_question.type_date,
              is_frequency: d.form_question.is_frequency,
              min_frequency: d.form_question.min_frequency,
              max_frequency: d.form_question.max_frequency,
              range_frequency: d.form_question.range_frequency,
              is_scale: d.form_question.is_scale,

              positionAnswer: d.position,
              answer_image: d.answer_image,
            });
          }

          this.formatedForms = [];
          for (const item in forms) {
            this.formatedForms.push({
              form_id: item,
              items: forms[item],
            });
          }
          this.formTitles = titles;
          const dataSources = [];

          for (const x in this.formatedForms) {
            const aux = {
              name: titles[this.formatedForms[x].form_id],
              position: titles[this.formatedForms[x].form_id].position,
              title: [],
              rows: [],
              columns: [],
              id: this.formatedForms[x].form_id,
              infos: [],
              positionAnswers: [],
            };
            let allDates = [];

            for (const item in this.formatedForms[x].items) {
              const val = this.formatedForms[x].items[item];
              for (const item in val.items) {
                const datas = item;
                allDates.push(item);
              }
            }
            this.positionAnswers = {};
            allDates.sort();
            allDates = [...new Set(allDates)];
            aux.title = ["Pergunta", ...allDates];

            for (const item in this.formatedForms[x].items) {
              const val = this.formatedForms[x].items[item];

              const rowTitle = val.title;
              const row = new Array(aux.title.length);
              const infos = new Array(aux.title.length);
              row[0] = rowTitle;
              infos[0] = null;
              const positionAnswers = {};
              // console.log(val);
              for (let i = 1; i < aux.title.length; i++) {
                row[i] = "";
                infos[i] = {
                  is_numeric: false,
                  is_numeric_scale: false,
                  is_image: false,
                  is_selectable: false,
                  is_list: false,
                  is_frequency: false,
                  number_of_choices: 0,
                };
              }
              for (const item in val.items) {
                const data = item;
                const answers = val.items[item];
                const pos = aux.title.indexOf(data);

                infos[pos] = {
                  is_numeric:
                    this.findField(answers, "is_numeric") ||
                    (infos[pos].is_numeric ? true : false),
                  is_selectable:
                    this.findField(answers, "is_selectable") ||
                    (infos[pos].is_selectable ? true : false),
                  is_list:
                    this.findField(answers, "is_list") ||
                    (infos[pos].is_list ? true : false),
                  is_image:
                    this.findField(answers, "is_image") ||
                    (infos[pos].is_image ? true : false),
                  is_frequency:
                    this.findField(answers, "is_frequency") ||
                    (infos[pos].is_frequency ? true : false),
                  number_of_choices: this.findFieldNumber(
                    answers,
                    "number_of_choices"
                  )
                    ? this.findFieldNumber(answers, "number_of_choices")
                    : infos[pos].number_of_choices,
                };

                row[pos] = "";
                for (const answer of answers) {
                  //console.log(answer);
                  if (!row[pos] || !row[pos].length) row[pos] = "";
                  else row[pos] += "<br/>";
                  row[pos] += answer.is_image
                    ? answer.answer_image
                    : answer.answer;
                  // infos[pos] = {
                  //   is_numeric:
                  //     answer.is_numeric ||
                  //     (infos[pos].is_numeric ? true : false),
                  //   is_selectable:
                  //     answer.is_selectable ||
                  //     (infos[pos].is_selectable ? true : false),
                  //   is_image:
                  //     answer.is_image || (infos[pos].is_image ? true : false),
                  //   is_frequency:
                  //     answer.is_frequency ||
                  //     (infos[pos].is_frequency ? true : false),
                  // };
                  positionAnswers[answer.answer] = answer.positionAnswer;
                }
              }
              aux.rows.push(row);
              aux.infos.push(infos);
              aux.positionAnswers.push(positionAnswers);
            }
            // console.log(aux);
            const columns = [];
            for (let i = 0; i < aux.title.length; i++) {
              const col = [aux.title[i]];
              for (const item of aux.rows) col.push(item[i]);
              columns.push(col);
            }
            aux.columns = columns;
            dataSources.push(aux);
          }

          // console.log(dataSources);
          dataSources.sort((a, b) => (a.position < b.position ? -1 : 1));

          this.dataSourcesTable = dataSources;
          this.charts = {};
          this.legends = {};
          this.nameQuestions = {};
          this.infosQuestions = {};
          this.toTable = {};
          // console.log(this.charts);
          // console.log(dataSources);
          for (let i = 0; i < dataSources.length; i++) {
            for (let j = 0; j < dataSources[i].infos.length; j++) {
              const best = {
                is_numeric: false,
                is_image: false,
                is_selectable: false,
                is_list: false,
                is_frequency: false,
                number_of_choices: 0,
              };
              for (let k = 0; k < dataSources[i].infos[j].length; k++) {
                if (!dataSources[i].infos[j][k]) continue;
                best.is_image =
                  best.is_image || dataSources[i].infos[j][k].is_image;
                best.is_numeric =
                  best.is_numeric || dataSources[i].infos[j][k].is_numeric;
                best.is_selectable =
                  best.is_selectable ||
                  dataSources[i].infos[j][k].is_selectable;
                best.is_list =
                  best.is_list || dataSources[i].infos[j][k].is_list;
                best.is_frequency =
                  best.is_frequency || dataSources[i].infos[j][k].is_frequency;
                best.number_of_choices = best.number_of_choices
                  ? best.number_of_choices
                  : dataSources[i].infos[j][k].number_of_choices;
              }

              for (let k = 0; k < dataSources[i].infos[j].length; k++)
                dataSources[i].infos[j][k] = best;
            }
          }

          // console.log(dataSources);
          for (let i = 0; i < dataSources.length; i++) {
            this.charts[dataSources[i].id] = [];
            this.legends[dataSources[i].id] = [];
            this.nameQuestions[dataSources[i].id] = [];
            this.infosQuestions[dataSources[i].id] = [];
            this.toTable[dataSources[i].id] = [];

            const sz = dataSources[i].columns[0].length - 1;

            for (let j = 0; j < sz; j++) {
              this.charts[dataSources[i].id].push({});
              this.toTable[dataSources[i].id].push({});
              this.legends[dataSources[i].id].push([]);
              this.nameQuestions[dataSources[i].id].push(
                dataSources[i].columns[0][j + 1]
              );
              this.infosQuestions[dataSources[i].id].push(
                dataSources[i].infos[j][1]
              );
            }
          }
          console.log(this.infosQuestions)
          // console.log(this.charts);
          // console.log(this.legends);
          // console.log(this.nameQuestions)

          this.loading = false;
          // console.log(dataSources);
          setTimeout(() => {
            for (let j = 0; j < dataSources.length; j++) {
              // console.log(dataSources[0]);
              for (let k = 1; k < dataSources[j].columns[0].length; k++) {
                const values = [];
                for (let i = 1; i < dataSources[j].columns.length; i++) {
                  if (
                    dataSources[j].columns[i][k] &&
                    dataSources[j].columns[i][0]
                  )
                    values.push({
                      x: dataSources[j].columns[i][0],
                      y: dataSources[j].columns[i][k],
                    });
                }
                if (values.length > 0) {
                  this.createChart(
                    dataSources[j].columns[0][k],
                    values,
                    dataSources[j].id,
                    k - 1,
                    dataSources[j].positionAnswers[k - 1]
                  );
                }
              }
            }
          }, 1000);
        },
        (error) => {
          //console.log(error);
          this.loading = false;
        }
      );
  }

  getHealthRecords() {
    let date = this.datePipe.transform(this.range.value.start, "yyyy-MM-dd");
    let dateEnd = this.datePipe.transform(this.range.value.end, "yyyy-MM-dd");
    this.dataSourceRecords = [];
    this.titleRecords = [];
    this.loadingRecords = true;
    this.userService
      .getPatientRecord(this.patient_id, 1, date, dateEnd)
      .subscribe(
        (data) => {
          const days = [];
          const recordsByDay = {};
          const bySinal = {};
          for (let d of data) {
            const data = d.record.created_at.split("T")[0];
            days.push(data);
          }

          days.sort();
          const titles = ["Sinal", ...new Set(days)];

          bySinal["sis"] = new Array(titles.length);
          bySinal["dia"] = new Array(titles.length);
          bySinal["glucose"] = new Array(titles.length);
          bySinal["heartbeat"] = new Array(titles.length);
          bySinal["oxygenation"] = new Array(titles.length);
          bySinal["respiratoryFrequency"] = new Array(titles.length);
          bySinal["temperature"] = new Array(titles.length);

          bySinal["sis"][0] = "Sistólica";
          bySinal["dia"][0] = "Diastólica";
          bySinal["glucose"][0] = "Glicose";
          bySinal["heartbeat"][0] = "Frequência cardíaca";
          bySinal["oxygenation"][0] = "Saturação";
          bySinal["respiratoryFrequency"][0] = "Frequência respiratória";
          bySinal["temperature"][0] = "Temperatura";

          const sinals = [
            "sis",
            "dia",
            "heartbeat",
            "glucose",
            "temperature",
            "oxygenation",
            "respiratoryFrequency",
          ];
          for (const s of sinals) {
            for (let i = 1; i < titles.length; i++) bySinal[s][i] = "";
          }

          const columnsOfDay = {};

          let idx = 0;
          for (const d of titles) {
            columnsOfDay[d] = idx++;
          }
          for (const d of data) {
            const pos = columnsOfDay[d.record.created_at.split("T")[0]];
            const time = d.record.created_at.split("T")[1].slice(0, 5);
            const createdBy = d.doctor ? d.doctor.name : "Paciente";

            if (d.record.blood_pressure_1 && d.record.blood_pressure_2) {
              if (!bySinal["sis"][pos] || !bySinal["sis"][pos].length)
                bySinal["sis"][pos] = "";
              else bySinal["sis"][pos] += "<br/>";
              bySinal["sis"][pos] +=
                `<span style="${
                  d.notification && d.notification.length > 0
                    ? "color: red"
                    : ""
                }">` +
                d.record.blood_pressure_1 +
                " mmHg" +
                ` (${time} - ${createdBy})` +
                "</span>";

              if (!bySinal["dia"][pos] || !bySinal["dia"][pos].length)
                bySinal["dia"][pos] = "";
              else bySinal["dia"][pos] += "<br/>";
              bySinal["dia"][pos] +=
                `<span style="${
                  d.notification && d.notification.length > 0
                    ? "color: red"
                    : ""
                }">` +
                d.record.blood_pressure_2 +
                " mmHg" +
                ` (${time} - ${createdBy})` +
                "</span>";
            }
            if (d.record.heartbeat) {
              if (
                !bySinal["heartbeat"][pos] ||
                !bySinal["heartbeat"][pos].length
              )
                bySinal["heartbeat"][pos] = "";
              else bySinal["heartbeat"][pos] += "<br/>";
              bySinal["heartbeat"][pos] +=
                `<span style="${
                  d.notification && d.notification.length > 0
                    ? "color: red"
                    : ""
                }">` +
                d.record.heartbeat +
                " bpm" +
                ` (${time} - ${createdBy})` +
                "</span>";
            }
            if (d.record.glucose) {
              if (!bySinal["glucose"][pos] || !bySinal["glucose"][pos].length)
                bySinal["glucose"][pos] = "";
              else bySinal["glucose"][pos] += "<br/>";
              bySinal["glucose"][pos] +=
                `<span style="${
                  d.notification && d.notification.length > 0
                    ? "color: red"
                    : ""
                }">` +
                d.record.glucose +
                " mm/dL" +
                ` (${time} - ${createdBy})` +
                "</span>";
            }
            if (d.record.temperature) {
              if (
                !bySinal["temperature"][pos] ||
                !bySinal["temperature"][pos].length
              )
                bySinal["temperature"][pos] = "";
              else bySinal["temperature"][pos] += "<br/>";
              bySinal["temperature"][pos] +=
                `<span style="${
                  d.notification && d.notification.length > 0
                    ? "color: red"
                    : ""
                }">` +
                d.record.temperature +
                " ºC" +
                ` (${time} - ${createdBy})` +
                "</span>";
            }
            if (d.record.oxygenation) {
              if (
                !bySinal["oxygenation"][pos] ||
                !bySinal["oxygenation"][pos].length
              )
                bySinal["oxygenation"][pos] = "";
              else bySinal["oxygenation"][pos] += "<br/>";
              bySinal["oxygenation"][pos] +=
                `<span style="${
                  d.notification && d.notification.length > 0
                    ? "color: red"
                    : ""
                }">` +
                d.record.oxygenation +
                " %SpO" +
                ` (${time} - ${createdBy})` +
                "</span>";
            }
            if (d.record.respiratoryFrequency) {
              if (
                !bySinal["respiratoryFrequency"][pos] ||
                !bySinal["respiratoryFrequency"][pos].length
              )
                bySinal["respiratoryFrequency"][pos] = "";
              else bySinal["respiratoryFrequency"][pos] += "<br/>";
              bySinal["respiratoryFrequency"][pos] +=
                `<span style="${
                  d.notification && d.notification.length > 0
                    ? "color: red"
                    : ""
                }">` +
                d.record.respiratoryFrequency +
                ` (${time} - ${createdBy})` +
                "</span>";
            }
          }
          const rows = [];
          for (const sinal of sinals) {
            rows.push(bySinal[sinal]);
          }
          this.loadingRecords = false;
          this.chartParameters = {};
          this.toTableParameter = {};
          if (data && data.length > 0) {
            this.titleRecords = titles;
            this.dataSourceRecords = rows;

            // console.log(bySinal);
            // console.log(titles)

            for (const sinal of this.parameters) {
              this.chartParameters[sinal] = null;
              // console.log(bySinal[sinal]);

              const values = [];
              for (let i = 1; i < titles.length; i++) {
                if (bySinal[sinal][i] && bySinal[sinal][i].length > 0) {
                  const res = bySinal[sinal][i].split("<br/>");
                  let valorTotal = "";
                  for (const x of res) {
                    const valor = x.split(" (")[0].split(">")[1].split(" ")[0];
                    if (valorTotal.length > 0) valorTotal += "<br/>";
                    valorTotal += valor;
                  }
                  values.push({ x: titles[i], y: valorTotal });
                }
              }
              this.toTableParameter[bySinal[sinal][0]] = {};
              if (values.length > 0) {
                this.chartParameters[bySinal[sinal][0]] = {};
                // console.log('AAAA', bySinal[sinal][0], values);
                setTimeout(() => {
                  this.createChartParametr(sinal, values, bySinal[sinal][0]);
                }, 500);
              }
            }
          }
        },
        (error) => {
          this.loadingRecords = false;
        }
      );
  }

  formatData(data) {
    if (data !== "Pergunta") {
      return data.split("-").reverse().join("/");
    }
    return data;
  }

  openAnswer(data) {
    this.companyService.getAnswerFormPatientByFormId(data.id).subscribe(
      (dataAnswers) => {
        //console.log("aaaaa");
        //console.log(dataAnswers);
        this.modalRef = this.modalService.show(ModalFormAnswerComponent, {
          backdrop: true,
          keyboard: true,
          focus: true,
          show: true,
          width: "100%",
          ignoreBackdropClick: true,
          class: "modal-dialog-centered modal-dialog modal-xl",
          StyleSheet: { width: "100% !important" },
          containerClass: "modal-answer",
          animated: false,
          data: {
            title: `Respostas do Formulário ${data.form.title}`,
            form_id: data.id,
            form_title: data.form.title,
            answers: dataAnswers,
          },
        });
      },
      (error) => {
        //console.log(error);
      }
    );
  }

  openAddAnswer() {
    this.modalRef = this.modalService.show(ModalSelectFormComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Tem certeza...",
        text: `de que deseja deletar o médico?`,
        patient_id: this.patient_id,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.modalRef = this.modalService.show(ModalFillFormComponent, {
          backdrop: true,
          keyboard: true,
          focus: true,
          show: true,
          ignoreBackdropClick: true,
          class: "modal-dialog-centered modal-dialog modal-lg modal-size",
          containerClass: "",
          animated: false,
          data: {
            title: result.title,
            form: result,
            patient_id: this.patient_id,
          },
        });
        this.modalRef.content.action.subscribe((result) => {
          if (result) {
            this.ngOnInit();
          }
        });
      }
    });
  }

  createChart(title, answer, form_id, question_id, positions) {
    // console.log("entreii");
    // console.log(answer);
    // console.log('tyentando', form_id, question_id, answer);

    // for(let i = 1; i < answer.length; i++) {

    // }
    // console.log(title, form_id, question_id, positions);

    // console.log(title, answer, form_id, question_id, positions);
    const allData = [];
    for (const a of answer) {
      const ys = a.y.split("<br/>");
      for (const y of ys) {
        allData.push({ y, position: positions[y] });
      }
    }

    allData.sort((a, b) => (a.position < b.position ? -1 : 1));
    const dict = {};
    const reversedDict = {};
    // console.log('Trying', form_id, question_id);
    if (
      this.infosQuestions[form_id][question_id].is_numeric ||
      this.infosQuestions[form_id][question_id].is_frequency
    ) {
      let id = 1;
      for (let a of allData) {
        if (!dict[a]) {
          this.legends[form_id][question_id].push({ label: a.y, value: a.y });

          dict[a.y] = a.y;
          reversedDict[a.y] = a.y;
        }
      }
    } else {
      let id = 1;

      for (let a of allData) {
        if (!dict[a.y]) {
          this.legends[form_id][question_id].push({ label: id, value: a.y });
          dict[a.y] = id;
          reversedDict[id++] = a.y;
        }
      }
    }

    // console.log(dict);
    // const labels = answer.map((answer) => answer.x);
    // const data = answer.map((answer) => dict[answer.y]);

    const data = [];
    for (const a of answer) {
      const ys = a.y.split("<br/>");
      for (const y of ys) {
        data.push({ x: a.x, y: dict[y] });
      }
    }

    const values = {};
    for (const a of answer) {
      if (!values[a.x]) {
        values[a.x] = a.y;
      } else {
        values[a.x] += "<br/>" + a.y;
      }
    }

    let res = [];
    for (const a in values) res.push({ x: a, y: values[a] });
    res.sort((a, b) => a.x - b.x);
    // console.log(answer);
    // console.log(res);
    this.toTable[form_id][question_id] = {
      title: ["Pergunta", ...res.map((answer) => answer.x)],
      row: [
        this.nameQuestions[form_id][question_id],
        ...res.map((answer) => answer.y),
      ],
    };
    // console.log(this.toTable[form_id][question_id]);
    this.charts[form_id][question_id] = new Chart(
      `MyChart-${form_id}-${question_id}`,
      {
        type: "scatter",
        data: {
          datasets: [
            {
              lineTension: 0,
              label: title,
              // backgroundColor: "blue",
              borderColor: "blue",
              borderWidth: 2,
              hoverBackgroundColor: "blue",
              hoverBorderColor: "blue",
              fill: false,
              showLine: true,

              data,
            },
          ],
        },
        options: {
          animation: false,
          // aspectRatio: 2.5,
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: false,
            },
          },
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                return (
                  tooltipItem.xLabel.split("-").reverse().join("/") +
                  ": " +
                  reversedDict[tooltipItem.yLabel]
                );
              },
            },
          },
          scales: {
            xAxes: [
              {
                type: "time",
                distribution: "series",
                time: {
                  // unit: 'date',
                  // parser: 'YYYY MM DD'
                  parser: "YYYY-MM-DD",
                  unit: "quarter",
                  displayFormats: {
                    quarter: "DD/MM/YYYY",
                  },
                },
                ticks: {
                  autoSkip: false,
                  callback: function (value, index, values) {
                    return value;
                  },
                },
                gridLines: {
                  display: true,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  callback: function (value) {
                    if (value % 1 === 0) {
                      return value;
                    }
                  },
                },
              },
            ],
          },
        },
      }
    );
  }

  createChartParametr(title, answer, tt) {
    // console.log(answer);
    const allData = [];
    for (const a of answer) {
      const ys = a.y.split("<br/>");
      for (const y of ys) {
        allData.push(y);
      }
    }
    const dict = {};
    const reversedDict = {};
    for (let a of allData) {
      if (!dict[a]) {
        dict[a] = a;
        reversedDict[a] = a;
      }
    }
    const data = [];
    for (const a of answer) {
      const ys = a.y.split("<br/>");
      for (const y of ys) {
        data.push({ x: a.x, y: dict[y] });
      }
    }

    data.sort((a, b) => a.x - b.x);
    const dataToTable = { title: ["Parâmetro"], row: [tt] };
    for (const a of answer) {
      dataToTable.title.push(a.x);
      dataToTable.row.push(a.y);
    }
    this.toTableParameter[tt] = dataToTable;
    // this.toTableParameter[tt] = {
    //   title: ["Parâmetro", ...data.map((answer) => answer.x)],
    //   row: [tt, ...data.map((answer) => answer.y)],
    // };

    // console.log(data);
    // console.log(data);
    // console.log(this.toTable[form_id][question_id]);
    this.chartParameters[title] = new Chart(`MyChart-${title}`, {
      type: "scatter",
      data: {
        datasets: [
          {
            lineTension: 0,
            label: title,
            // backgroundColor: "blue",
            borderColor: "blue",
            borderWidth: 2,
            hoverBackgroundColor: "blue",
            hoverBorderColor: "blue",
            fill: false,
            showLine: true,

            data,
          },
        ],
      },
      options: {
        animation: false,
        // aspectRatio: 2.5,
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: false,
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return (
                tooltipItem.xLabel.split("-").reverse().join("/") +
                ": " +
                reversedDict[tooltipItem.yLabel]
              );
            },
          },
        },
        scales: {
          xAxes: [
            {
              type: "time",
              distribution: "series",
              time: {
                // unit: 'date',
                // parser: 'YYYY MM DD'
                parser: "YYYY-MM-DD",
                unit: "quarter",
                displayFormats: {
                  quarter: "DD/MM/YYYY",
                },
              },
              ticks: {
                autoSkip: false,
                callback: function (value, index, values) {
                  return value;
                },
              },
              gridLines: {
                display: true,
              },
            },
          ],
          yAxes: [{}],
        },
      },
    });
  }

  size(id) {
    const ans = [];
    for (let i = 0; i < this.charts[id].length; i++) ans.push(i);
    return ans;
  }

  openImageFull(url) {
    // var image = new Image();
    // image.src = url;

    // var w = window.open("");
    // w.document.write(image.outerHTML);

    window.open(url, "_blank");
  }

  getRowsToImage(element) {
    return element.split("<br/>");
  }

  checkDay(start, end) {
    var diffDays = Math.round((end - start) / (1000 * 60 * 60 * 24));

    if (diffDays > 31) {
      this.openMessage(
        "Ação não permitida",
        "Você só pode exportar o relatório para períodos de no máximo 31 dias!",
        false
      );
      return false;
    }
    return true;
  }
  downloadTable() {
    if (!this.checkDay(this.range.value.start, this.range.value.end)) {
      return;
    }
    // const doc = new jsPDF()
    // autoTable(doc, { html: '#tabela' })
    // doc.save('sinais-vitais.pdf')
    let date = this.datePipe.transform(this.range.value.start, "yyyy-MM-dd");
    let dateEnd = this.datePipe.transform(this.range.value.end, "yyyy-MM-dd");
    let count = 0;

    if (date && dateEnd) {
      this.loadingDownload = true;
      this.companyService
        .getPatientAnswersPDF(
          this.patient_id,
          date,
          dateEnd,
          this.selectedForms
        )
        .subscribe(
          (data) => {
            console.log("dentroooo");
            const blob = new Blob([data], { type: "application/pdf" });

            var downloadURL = window.URL.createObjectURL(data);
            console.log(downloadURL);
            var link = document.createElement("a");
            link.href = downloadURL;
            link.download = "relatorio.pdf";
            link.click();
            this.loadingDownload = false;
          },
          async (error) => {
            this.loadingDownload = false;
            const data = error.error.text;
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(
              new Blob([data], { type: "application/pdf" })
            );

            // const contentDisposition = response.headers.get('content-disposition');
            // const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            downloadLink.download = "relatorio.pdf";
            downloadLink.click();
          }
        );
    }
  }

  downloadTableCSV() {
    if (!this.checkDay(this.range.value.start, this.range.value.end)) {
      return;
    }
    // const doc = new jsPDF()
    // autoTable(doc, { html: '#tabela' })
    // doc.save('sinais-vitais.pdf')
    let date = this.datePipe.transform(this.range.value.start, "yyyy-MM-dd");
    let dateEnd = this.datePipe.transform(this.range.value.end, "yyyy-MM-dd");
    let count = 0;

    if (date && dateEnd) {
      this.loadingDownload = true;
      this.companyService
        .getPatientAnswersCSV(
          this.patient_id,
          date,
          dateEnd,
          this.selectedForms
        )
        .subscribe(
          (data) => {
            const blob = new Blob([data], { type: "text/csv" });

            var downloadURL = window.URL.createObjectURL(data);
            console.log(downloadURL);
            var link = document.createElement("a");
            link.href = downloadURL;
            link.download = "relatorio.csv";
            link.click();
            this.loadingDownload = false;
          },
          async (error) => {
            const data = error.error.text;
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(
              new Blob([data], { type: "text/csv" })
            );
            this.loadingDownload = false;
            // const contentDisposition = response.headers.get('content-disposition');
            // const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            downloadLink.download = "relatorio.csv";
            downloadLink.click();
          }
        );
    }
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  findField(val: any, field) {
    for (const y in val) {
      const data = val[y];
      if (data[field]) {
        return true;
      }
    }
    return false;
  }

  findFieldNumber(val: any, field) {
    for (const y in val) {
      const data = val[y];
      if (data[field]) {
        return data[field];
      }
    }
    return 0;
  }

  initScoreData(data) {
    const byFormId = {};
    for (const d of data) {
      if (!byFormId[d.form_answer.form.id]) {
        byFormId[d.form_answer.form.id] = [];
      }
      byFormId[d.form_answer.form.id].push(d);
    }

    for (const b in byFormId) {
      this.initScoreDataId(byFormId[b], byFormId[b][0].form_answer.form.id);
    }
  }

  initScoreDataId(data, id) {
    if (data[0].form_answer.form.score_answer === null) return;
    const val = {
      score_answer: data[0].form_answer.form.score_answer,
      scores_date: {},
      scores: [],
      answersByScore: {},
      answersByScoreIt: [],
      allDates: [],
    };

    const groupScoresByFormAnswers = {};

    for (const d of data) {
      // console.log(d.form_answer.id);
      if (!groupScoresByFormAnswers[d.form_answer.id]) {
        groupScoresByFormAnswers[d.form_answer.id] = {
          form_answer: d.form_answer,
          created_at: d.form_answer.created_at.split("T")[0],
          score: 0,
        };
      }
      groupScoresByFormAnswers[d.form_answer.id].score += d.score;
    }

    for (const g in groupScoresByFormAnswers) {
      const value = groupScoresByFormAnswers[g];
      if (!val.scores_date[value.created_at]) {
        val.scores_date[value.created_at] = {
          created_at: value.created_at,
          scores: [],
        };
      }
      val.scores_date[value.created_at].scores.push(value.score);
    }

    for (const s in val.scores_date) {
      const value = val.scores_date[s];
      val.scores.push(value);
    }

    let answersByScore = {};
    let allDates = [];
    for (const s of val.scores) {
      for (const inside of s.scores) {
        const answer = this.getAnswerScore(val.score_answer, inside);

        if (!answersByScore[answer]) {
          answersByScore[answer] = {
            answer,
            answersByDate: {},
            dates: [],
          };
        }
        allDates.push(s.created_at);
        if (!answersByScore[answer].answersByDate[s.created_at]) {
          answersByScore[answer].answersByDate[s.created_at] = [];
        }

        answersByScore[answer].answersByDate[s.created_at].push(
          "Valor: " + inside
        );

        // console.log('Value: ', inside.score)
      }
      // let array = []
      // for(const a in answersByScore) {
      //   console.log('To na resposta', answersByScore[a].answersByDate)
      //   // for(const item in answersByScore[a].answersByDate) {
      //   //   console.log('appending', item)
      //   //   answersByScore[a].dates.push(answersByScore[a].answersByDate[item])
      //   // }

      // }
      // console.log(array);
      // if(!answersByDate[])
    }

    val.answersByScore = answersByScore;
    val.answersByScoreIt = Object.values(answersByScore);
    val.allDates = [...new Set(allDates)];

    this.scoreScalesByFormId[id] = val;
  }
  getAnswerScore(score, total) {
    for (const interval of score.intervals_score_answer) {
      if (interval.start_value <= total && interval.end_value >= total) {
        return interval.description;
      }
    }
    return "Valor fora da escala";
  }

  isCheckbox(data) {
    if (!data.is_list) return true;
    // Posso selecionar mais que uma
    if (!data.is_selectable && !data.is_list) {
      return false;
    }

    return data.number_of_choices > 1;
  }

  isText(data) {
    return !data.is_frequency && !data.is_image && !data.is_list && !data.is_numeric && !data.is_selectable;
  }


  isMultiple(data) {
    
    if (!data.is_list) return true;
    // Posso selecionar só uma
    if (!data.is_selectable) {
      return false;
    }

    return data.number_of_choices === 1;
  }
}
