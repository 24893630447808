import { Component, ViewChild } from "@angular/core";
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  NavigationExtras,
} from "@angular/router";
import { UserService } from "../service/user.service";
import {
  MDBModalRef,
  MDBModalService,
  NavbarComponent,
} from "angular-bootstrap-md";
import { MemedService } from "../service/memed.service";
import { ModalDoctorSelectCompanyComponent } from "../modals/modal-doctor-select-company/modal-doctor-select-company.component";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SideBarScreenComponent {
  memedStatus = null;
  doctor;
  constructor(
    private router: Router,
    private userService: UserService,
    private memedService: MemedService,

    private modalService: MDBModalService,
    private datePipe: DatePipe
  ) {}

  loaded: boolean = true;
  doctor_name: string = "";

  isAdmin: boolean = false;
  isCompany: boolean = false;
  isAdminMedical: boolean = false;

  modalRef: MDBModalRef;

  company_type = localStorage.getItem("companyType");
  isClinic = this.company_type == "CLINIC";
  ngOnInit() {
    this.loaded = true;
  }

}
