import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { UserService } from "src/app/service/user.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import { MedicationService } from "src/app/service/medication.service";

@Component({
  selector: "modal-add-patient",
  templateUrl: "./modal-add-patient.component.html",
  styleUrls: ["./modal-add-patient.component.css"],
})
export class ModalAddPatientComponent {
  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private userService: UserService,
    private companyService: CompanyService,
    private datePipe: DatePipe
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;
  patient_id;
  btn_text: string;
  is_new: boolean = true;
  title: string;
  patient: any;

  allergy: string;
  blood_type: string;
  health_insurance: string;
  height: string;
  history_disease: string;
  past_surgeries: string;
  urgency: string;
  weight: string;

  validator = new ValidatorUtils();

  ngOnInit() {
    this.allergy = this.patient.allergy;
    this.blood_type = this.patient.blood_type;
    this.health_insurance = this.patient.health_insurance;
    this.height = this.patient.height;
    this.history_disease = this.patient.history_disease;
    this.past_surgeries = this.patient.past_surgeries;
    this.urgency = this.patient.urgency;
    this.weight = this.patient.weight;

    //console.log(this.patient)
  }

  async insertUpdate() {
    this.openMessage("Por favor, aguarde", "", true);
    const body = {
      height: this.height,
      history_disease: this.history_disease,
      allergy: this.allergy,
      blood_type: this.blood_type,
      health_insurance: this.health_insurance,
      past_surgeries: this.past_surgeries,
      urgency: this.urgency,
      weight: this.weight,
    }
    console.log(body);
    
    this.userService.updatePatient(body, this.patient.id).subscribe(
      (data) => {
        this.modalRef2.hide();
        this.openMessage("Sucesso!", "Informações do paciente editadas", false);
        this.action.next("update");
        this.modalRef.hide();
      },
      (error) => {
        this.modalRef2.hide();
        this.error(error, "criar");
      }
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi editar as informações do paciente`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "modal-lg",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  editPatient() {
    
    this.insertUpdate();
  }
}
