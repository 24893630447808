import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { CompanyService } from "../service/company.service";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: "app-report_day",
  templateUrl: "./report_day.component.html",
  styleUrls: ["./report_day.component.css"],
})
export class ReportDayComponent implements OnInit {
  constructor(
    private companyService: CompanyService,
    private modalService: MDBModalService,
    private datePipe: DatePipe
  ) {}
  company_type = localStorage.getItem('companyType')
  displayedColumns: string[] = [
    "name",
    "heartbeat",
    "blood_pressure",
    "temperature",
    "glucose",
    "oxygenation",
    "weight",
    "respiratoryFrequency",
    "option",
  ];
  data_source = new MatTableDataSource<any>();

  modalRef: MDBModalRef;
  day: Date = new Date();

  is_loaded: boolean = false;

  /* pagination related variabels */
  page: number = 1;
  count_total: number = 0;
  page_count1: number = 0;
  page_count2: number = 0;
  page_size = 10;

  is_company_admin: boolean = false;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngOnInit(): void {
    console.log();
    if (localStorage.getItem("company") !== null) {
      this.is_company_admin = true;
      this.getReportDay(1);
    }
  }

  truncateString(str, max) {
    return str.length > max ? str.substr(0, max - 1) + "…" : str;
  }

  currentDay() {
    return this.datePipe.transform(this.day, "yyyy/MM/dd");
  }

  getReportDay(page) {
    this.is_loaded = false;
    let start = this.datePipe.transform(this.day, "yyyy-MM-dd") + " 03:00:00";
    let end = this.datePipe.transform(this.day, "yyyy-MM-dd") + " 23:59:59";
    let dateAux = new Date(end);
    dateAux.setHours(dateAux.getHours() + 3);
    end = this.datePipe.transform(dateAux, "yyyy-MM-dd HH:mm:ss");

    this.companyService.getReportDay(start, end).subscribe(
      (data) => {
        //console.log(data);
        this.data_source.data = data.map((item) => {
          const result = {
            id: item.id,
            name: item.name,
            heartbeat: {
              min: item.min_heartbeat,
              max: item.max_heartbeat,
              notification: item.notification_hearbeat,
            },
            blood_pressure: {
              min_sistolica: item.min_sistolica,
              max_sistolica: item.max_sistolica,
              min_diastolica: item.min_diastolica,
              max_diastolica: item.max_diastolica,
              notification_blood_pressure: item.notification_blood_pressure,
            },
            temperature: {
              min: item.min_temperature,
              max: item.max_temperature,
              notification: item.temperature_notification,
            },
            glucose: {
              min: item.min_glucose,
              max: item.max_glucose,
              notification: item.glucose_notification,
            },
            oxygenation: {
              min: item.min_oxygenation,
              max: item.max_oxygenation,
              notification: item.oxygenation_notification,
            },
            weight: {
              min: item.min_weight,
              max: item.max_weight,
              notification: item.weight_notification,
            },
            respiratoryFrequency: {
              min: item.min_respiratoryFrequency,
              max: item.max_respiratoryFrequency,
              notification: item.respiratoryFrequency_notification,
            },
          };

          return result;
        });
        this.data_source.sort = this.sort;
        //console.log(this.data_source);
        // pagination calc
        this.page = page;
        this.count_total = data.length;
        this.page_count1 = 1 + (page - 1) * this.page_size;
        if (this.page_count1 + (this.page_size - 1) > this.count_total) {
          this.page_count2 = this.count_total;
        } else {
          this.page_count2 = this.page_count1 + (this.page_size - 1);
        }
        this.is_loaded = true;
      },
      (error) => {
        //console.log(error);
        this.is_loaded = true;
      }
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} o médico.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
