import { Component, OnInit } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Md5 } from "md5-typescript";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { CompanyService } from "src/app/service/company.service";
import { InformationsService } from "src/app/service/informations.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";

@Component({
  selector: "app-company-admin",
  templateUrl: "./company-admin.component.html",
  styleUrls: ["./company-admin.component.css"],
})
export class CompanyAdminComponent implements OnInit {
  cnpj: string;
  name: string;
  email: string;
  phone: string;
  city: string;
  state: string;
  neighborhood: string;
  street: string;
  number: string;
  complement: string;

  nameDoctor: string;
  emailDoctor: string;
  birthday: string;
  phoneDoctor: string;
  company: string;
  cpf: string;
  crm: string;
  gender: string;
  cityDoctor: string;
  stateDoctor: string;
  streetDoctor: string;
  neighborhoodDoctor: string;
  numberDoctor: string;
  complementDoctor: string;
  association: string;
  password: string;
  confirm_password: string;
  active = true;
  associations: [];

  validator = new ValidatorUtils();
  modalRef: MDBModalRef;

  constructor(
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private informationsService: InformationsService
  ) {}
  ngOnInit(): void {
    this.clear();
    this.informationsService.getCompanyType().subscribe((data) => {
      this.associations = data;
    });
  }

  clear() {
    this.cnpj = null;
    this.name = null;
    this.email = null;
    this.phone = null;
    this.state = null;
    this.city = null;
    this.neighborhood = null;
    this.street = null;
    this.number = null;
    this.complement = null;
    this.association = null;
    
    this.nameDoctor = null;
    this.emailDoctor = null;
    this.birthday = null;
    this.phoneDoctor = null;
    this.company = null;
    this.cpf = null;
    this.crm = null;
    this.gender = null;
    this.cityDoctor = null;
    this.stateDoctor = null;
    this.streetDoctor = null;
    this.neighborhoodDoctor = null;
    this.numberDoctor = null;
    this.complementDoctor = null;
    this.password = null;
    this.confirm_password = null;
    this.active = true;
  }

  send() {
    let birthday = null;
    if (this.validator.isEmptyOrNull(this.name)) {
      this.openMessage("Por favor,", "insira o nome.", false);
      return false;
    }
    if (this.validator.isEmptyOrNull(this.cnpj)) {
      this.openMessage("Por favor,", "insira o CNPJ.", false);
      return false;
    }
    if (this.validator.isEmptyOrNull(this.email)) {
      this.openMessage("Por favor,", "insira o e-mail.", false);
      return false;
    }
    if (this.validator.isEmptyOrNull(this.phone)) {
      this.openMessage("Por favor,", "insira o telefone.", false);
      return false;
    }
    if (this.validator.isEmptyOrNull(this.city)) {
      this.openMessage("Por favor,", "insira a cidade.", false);
      return false;
    }
    if (this.validator.isEmptyOrNull(this.state)) {
      this.openMessage("Por favor,", "insira o estado.", false);
      return false;
    }
    if (this.validator.isEmptyOrNull(this.association)) {
      this.openMessage("Por favor,", "insira o tipo da empresa", false);
      return false;
    }

    if (this.validator.isEmptyOrNull(this.nameDoctor)) {
      this.openMessage("Por favor,", "insira o nome do responsável.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.emailDoctor)) {
      this.openMessage("Por favor,", "insira o email do responsável.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.cpf)) {
      this.openMessage("Por favor,", "insira o CPF do responsável.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.password)) {
      this.openMessage("Por favor,", "insira a senha do responsável.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.confirm_password)) {
      this.openMessage("Por favor,", "confirme a senha do responsável.", false);
      return false;
    } else if (this.password != this.confirm_password) {
      this.openMessage("Erro,", "as senhas são diferentes.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.birthday)) {
      this.openMessage(
        "Por favor,",
        "insira a data de nascimento do responsável.",
        false
      );
      return false;
    } else if (this.validator.isEmptyOrNull(this.gender)) {
      this.openMessage(
        "Por favor,",
        "selecione o gênero do responsável.",
        false
      );
      return false;
    } else if (this.validator.isEmptyOrNull(this.stateDoctor)) {
      this.openMessage("Por favor,", "informe o estado do responsável.", false);
      return false;
    } else {
      var day: any = this.birthday.substring(0, 2);
      var month: any = this.birthday.substring(3, 5);
      var year: any = this.birthday.substring(6, 10);

      var date = new Date(year, month - 1, day);
      var time = new Date().getTime() - date.getTime();
      var time_passed = time / 1000 / 60;

      if (day > 31 || day < 1 || month > 12 || month < 1 || year < 1900) {
        this.openMessage(
          "Por favor,",
          "insira uma data de nascimento válida",
          false
        );
        return false;
      } else if (time_passed < 0) {
        this.openMessage(
          "Por favor,",
          "insira uma data de nascimento válida",
          false
        );
        return false;
      }

      birthday = `${year}-${month}-${day}`;
    }

    const json = {
      cnpj: this.cnpj,
      name: this.name,
      email: this.email,
      phone: this.phone,
      state: this.state,
      city: this.city,
      neighborhood: this.neighborhood,
      street: this.street,
      number: this.number,
      complement: this.complement,
      company_type: this.association,

      doctor: {
        name: this.nameDoctor,
        email: this.emailDoctor,
        birthday: birthday,
        phone: this.phoneDoctor,
        company: this.company,
        cpf: this.cpf,
        crm: this.crm,
        gender: this.gender,
        city: this.cityDoctor,
        state: this.stateDoctor,
        street: this.streetDoctor,
        neighborhood: this.neighborhoodDoctor,
        number: this.numberDoctor,
        complement: this.complementDoctor,
        password: this.password,

        active: this.active,
      },
    };

    this.openMessage("Por favor, aguarde", "", true);
    var passwordMD5 = Md5.init(this.password).toUpperCase();
    json.doctor.password = passwordMD5;
    this.companyService.createNewCompany(json).subscribe(
      (data) => {
        this.openMessage("Criado!", "Companhia criada com sucesso.", false);
        this.modalRef.hide();
        this.clear();
      },
      (error) => {
        this.modalRef.hide();
        this.error(error, "criar");
      }
    );

    this.clear();
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} uma companhia.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
