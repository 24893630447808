import { Component, OnInit } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Md5 } from "md5-typescript";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { AdminService } from "src/app/service/admin.service";
import { CompanyService } from "src/app/service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { ModalChoiceComponent } from "src/app/modals/modal-choice/modal-choice.component";
import { ModalScoreAnswersCreateComponent } from "src/app/modals/modal-scoreanswers-create/modal-scoreanswers-create.component";

@Component({
  selector: "app-scoreanswer-admin",
  templateUrl: "./scoreanswer-admin.component.html",
  styleUrls: ["./scoreanswer-admin.component.css"],
})
export class ScoreAnswerAdminComponent implements OnInit {
  
  modalRef: MDBModalRef;
  loading: boolean =false;
  data: any[]
  company = -1

  titles = ["id", "title", 'company', 'edit'];
  companies = []



  constructor(
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private adminService: AdminService,
  ) {}

  ngOnInit(): void {
    this.getCompanies();
  }

  getCompanies() {
    this.loading = true;
    this.companyService.getCompanys().subscribe(data => {
      this.companies = [{id: -1, name: 'Todas companhias'}, ...data.map(company =>{
        return {
          id: company.id,
          name: company.name
        }
      })];
      this.getAnswers()
    })
  }


  getAnswers() {
    const company_id = this.company !== -1 ? this.company : null
    this.loading = true;
    this.adminService.getScoreAnswers(company_id).subscribe((data) => {
      

      this.data = [...data];
      this.loading = false;      
    })
  }


  

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} o modelo.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }


  editAnaswer(data = null) {
    this.modalRef = this.modalService.show(ModalScoreAnswersCreateComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: "Editar resposta da escala",
        btn_text: "Editar",
        is_new: data ? false : true,
        name: data ? data.name : "",
        intervals: data ? data.intervals_score_answer : [],
        id: data ? data.id : null,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.getAnswers();
        this.modalRef.hide();
      }
    });
  }

  makeModel(score) {
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja criar um modelo com base na resposta ${score.name}?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        
        this.adminService.makeModelScoreAnswer(score.id).subscribe((data) => {
          this.openMessage("Sucesso", "Modelo criado com sucesso", false);
        },
        (error) => {
          this.error(error, "criar");
        })
      }
    });
  } 
}
