import { Component, ViewChild } from "@angular/core";
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  NavigationExtras,
} from "@angular/router";
import { UserService } from "../service/user.service";
import {
  MDBModalRef,
  MDBModalService,
  NavbarComponent,
} from "angular-bootstrap-md";
import { MemedService } from "../service/memed.service";
import { ModalDoctorSelectCompanyComponent } from "../modals/modal-doctor-select-company/modal-doctor-select-company.component";
import { DatePipe } from "@angular/common";
import { CalendarService } from "../service/calendar.service";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { ServiceService } from "../service/service.service";
import { ModalSelectServiceTypeComponent } from "./modal-select-service/modal-select-service.component";
import { ModalChoiceComponent } from "../modals/modal-choice/modal-choice.component";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarScreenComponent {
  @ViewChild("navbar") navbar: NavbarComponent;
  memedStatus = null;
  doctor;
  id;
  timeControl = null;
  isSecretary = false;
  serviceId=null;
  constructor(
    public router: Router,
    private userService: UserService,
    private memedService: MemedService,
    private route: ActivatedRoute,

    private modalService: MDBModalService,
    private datePipe: DatePipe,
    private calendarService: CalendarService,
    private serviceService: ServiceService
  ) {


    /* catch page change event */
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if (this.navbar != null && this.navbar.shown) {
          this.navbar.hide();
        }

        if (ev.url.startsWith("/home/patient/")) {
          this.id = Number(ev.url.split("/")[3].split('?')[0]);
          this.isInPatient = true;
          
          this.route.queryParams.subscribe(params => {
            this.serviceId = params['serviceId'];
        });
        } else {
          this.isInPatient = false;
        }

        if (ev.url !== "/register" && localStorage.getItem("token") == null) {
          this.router.navigate(["/login"]);
        }

        if (localStorage.getItem("admin") == "true") {
          this.isAdmin = true;
        }

        if (localStorage.getItem("adminMedical") == "true") {
          this.isAdminMedical = true;
        }

        if (
          localStorage.getItem("company") !== null &&
          localStorage.getItem("company") !== "null"
        ) {
          this.isCompany = true;
        }
        this.loadCalendar();
        this.loadServiceRunning();
      }
    });
  }

  loaded: boolean = true;
  doctor_name: string = "";
  isInPatient: boolean = false;
  isAdmin: boolean = false;
  isCompany: boolean = false;
  isAdminMedical: boolean = false;

  modalRef: MDBModalRef;

  company_type = localStorage.getItem("companyType");
  isClinic = this.company_type == "CLINIC";

  patients = [];

  patientsInQueue = {};

  loading_patients = false;
  serviceRunning = null;
  interval = null;
  ngOnInit() {
    this.loaded = true;
    this.doctor_name = localStorage.getItem("doctor");
   
    /*this.userService.getConstants().subscribe( data => {
           //console.log(data)
        })*/
    this.memedService.getStatus().subscribe((data) => {
      this.memedStatus = data.status;

      this.doctor = data.doctor;
      localStorage.setItem("memed", JSON.stringify(this.memedStatus));
      localStorage.setItem("doctor", JSON.stringify(data.doctor));
      
      if (data.doctor) {
        
        if(data.doctor.specialty === 23)
          this.isSecretary = true;
        
      }
    });
    this.loadCalendar();
    const aux = localStorage.getItem("services");
    if (aux) {
      this.serviceRunning = JSON.parse(aux);
    }
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("doctor");
    localStorage.removeItem("admin");
    localStorage.removeItem("company");
    localStorage.removeItem("company");
    localStorage.removeItem("adminMedical");
    this.router.navigate(["/login"]);
  }

  openModalCompanies() {
    this.modalRef = this.modalService.show(ModalDoctorSelectCompanyComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        btn_text: "Adicionar",
        is_new: true,
      },
    });
  }

  getDate() {
    return this.datePipe.transform(new Date(), "dd/MM/yyyy");
  }

  justHour(t) {
    return t.split("T")[1].substr(0, 5);
  }

  loadCalendar() {
    const today = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.loading_patients = true;
    this.calendarService.getCalendarByDate(today).subscribe((data) => {
      
      this.patients = [];
      for (const p of data) {
        
        if(p.id+"" === this.serviceId)  {
          continue;
        }
        this.patients.push(p);
      }
      this.loading_patients = false;
      this.patientsInQueue = {};

      for (const p of data) {
    
        if (!this.patientsInQueue[p.patient.id]) {
          this.patientsInQueue[p.patient.id] = {}
        }
        this.patientsInQueue[p.patient.id][p.id] = p
      } 
    });
  }

  loadServiceRunning() {
    this.loading_patients = true;
    this.serviceService.getRunning().subscribe((data) => {
      // console.log(data)
      if (data) {
        this.serviceRunning = data;
        localStorage.setItem(
          "serviceRunning",
          JSON.stringify(this.serviceRunning)
        );

        if (this.id === this.serviceRunning.patient.id) {
          this.startTime(this.serviceRunning.start_date);
        }
      }
    });
  }

  formatTwoPlaces(n) {
    if (n < 10) {
      return "0" + n;
    }
    return n;
  }

  startTime(start) {
    var countDownDate = new Date(start).getTime();

    // Update the count down every 1 se cond
    this.interval = setInterval(() => {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance: any = now - countDownDate;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours =
        days * 24 +
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.timeControl =
        this.formatTwoPlaces(hours) +
        ":" +
        this.formatTwoPlaces(minutes) +
        ":" +
        this.formatTwoPlaces(seconds);

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  goToPatient(id, serviceId = null) {
    if (this.isSecretary) {
      return;
    }
    this.router
      .navigate(["home/patient/" + id], { queryParams: serviceId ? {serviceId}: {}})
      .then(() => window.location.reload());
  }

  start() {
    this.modalRef = this.modalService.show(ModalSelectServiceTypeComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        
        this.createService(result);
      }
    });
  }

  createService(serviceType) {
    this.calendarService
      .startService(this.patientsInQueue[this.id][this.serviceId].id, serviceType)
      .subscribe(
        (data) => {
          // console.log(data)
          localStorage.setItem("service", JSON.stringify(data));

          this.serviceRunning = data;
          delete this.patientsInQueue[this.id][this.serviceId];
          this.goToPatient(this.id)
          if (this.id === this.serviceRunning.patient.id) {
            this.startTime(this.serviceRunning.start_date);
          }
        },
        (err) => {
          this.error(err);
        }
      );
  }

  end() {
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja finalizar esse atendimento?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.stopService();
      }
    });
  }

  stopService() {
    this.serviceService.finishService(this.serviceRunning.id).subscribe(
      (data) => {
        // console.log(data)
        localStorage.removeItem("service");
        this.serviceRunning = null;
        this.router.navigate(["home/calendar/"])
        
        clearInterval(this.interval);
      },
      (err) => {
        this.error(err);
      }
    );
  }

  error(error) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else if (error.error && error.error.detail) {
      msg = error.error.detail;
    } else msg = "Por favor verifique as informações inseridas";
    console.log(error);
    this.openMessage(`Ocorreu um erro.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  sumTime(d, min) {
    const newDate = new Date(new Date(d).getTime() + min * 60000);
    return (
      this.formatTwoPlaces(newDate.getHours()) +
      ":" +
      this.formatTwoPlaces(newDate.getMinutes())
    );
  }
}
