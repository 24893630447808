import { Component, OnInit } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Md5 } from "md5-typescript";
import { ModalChoiceComponent } from "src/app/modals/modal-choice/modal-choice.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { AdminService } from "src/app/service/admin.service";
import { CalendarService } from "src/app/service/calendar.service";
import { CompanyService } from "src/app/service/company.service";
import { ProcedureService } from "src/app/service/procedure.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";

@Component({
  selector: "app-calendar-settings",
  templateUrl: "./calendar-settings.component.html",
  styleUrls: ["./calendar-settings.component.css"],
})
export class CalendarSettingsComponent implements OnInit {
  modalRef: MDBModalRef;
  loading: boolean = false;

  validator = new ValidatorUtils();
  constructor(
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private adminService: AdminService,
    private procedureService: ProcedureService,
    private calendarService: CalendarService
  ) {}

  days = { 0: true, 1: true, 2: true, 3: true, 4: true, 5: true, 6: true };
  daysName = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ];
  start_hour: string;
  end_hour: string;
  lunch_start: string;
  lunch_end: string;

  ngOnInit(): void {
    this.getCalendarSettings();
  }

  twoPlaces(d) {
    if (d < 10) return "0" + d;
    return d;
  }

  formatHour(d) {
    if (!d) return null;

    return this.twoPlaces(Math.floor(d / 60)) + ":" + this.twoPlaces(d % 60);
  }

  validHour(hour) {
    if (!hour || hour.length !== 5) {
      return false;
    }

    var hora = Number(hour.substring(0, 2));
    var minuto = Number(hour.substring(3, 5));
    if (hora >= 24 || hora < 0 || minuto > 59 || minuto < 0) {
      return false;
    }
    return true;
  }

  getCalendarSettings() {
    this.loading = true;
    this.calendarService.getCalendarSettings().subscribe((data) => {
      const daysNew = {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      };
      for (const d of data.daysOfWeek) {
        daysNew[d] = true;
      }
      this.days = daysNew;
      this.start_hour = this.formatHour(data.start_hour);
      this.end_hour = this.formatHour(data.end_hour);
      this.lunch_start = this.formatHour(data.lunch_start);
      this.lunch_end = this.formatHour(data.lunch_end);

      this.loading = false;
    });
  }

  save() {
    if (this.validator.isEmptyOrNull(this.start_hour)) {
      this.openMessage("Por favor,", "informe a hora de abertura.", false);
      return false;
    } else if (!this.validHour(this.start_hour)) {
      this.openMessage(
        "Por favor,",
        "informe uma hora de abertura válida.",
        false
      );
      return false;
    } else if (this.validator.isEmptyOrNull(this.end_hour)) {
      this.openMessage("Por favor,", "informe a hora de fechamento.", false);
      return false;
    } else if (!this.validHour(this.end_hour)) {
      this.openMessage(
        "Por favor,",
        "informe uma hora de fechamento válida.",
        false
      );
      return false;
    } else if (this.lunch_start && this.lunch_start.length > 0) {
      if (!this.validHour(this.lunch_start)) {
        this.openMessage(
          "Por favor,",
          "informe a hora do almoço válida.",
          false
        );
        return false;
      }
      if (!this.validHour(this.lunch_end)) {
        this.openMessage(
          "Por favor,",
          "informe a hora de retorno do almoço.",
          false
        );
        return false;
      }
    }
    this.create();
  }

  getHourInMinute(h) {
    var hora = Number(h.substring(0, 2));
    var minuto = Number(h.substring(3, 5));
    return hora * 60 + minuto;
  }

  create() {
    const data = {
      start_hour: this.getHourInMinute(this.start_hour),
      end_hour: this.getHourInMinute(this.end_hour),
    };
    if (this.lunch_start && this.lunch_start.length > 0) {
      data["lunch_start"] = this.getHourInMinute(this.lunch_start);
      data["lunch_end"] = this.getHourInMinute(this.lunch_end);
    }
    const days = [];
    for (const d in this.days) {
      if (this.days[d]) {
        days.push(d);
      }
    }
    data["daysOfWeek"] = days;
    this.calendarService.updateCalendarSettings(data).subscribe(
      (data) => {
        this.openMessage("Sucesso!", "Configurações salvas", false);
      },
      (error) => {
        this.error(error, "editar");
      }
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível ${string} o formulário.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
