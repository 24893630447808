import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormHomeService } from 'src/app/service/form-home.service';

@Component({
    selector: 'app-home-doctor',
    templateUrl: './home-doctor.component.html',
    styleUrls: ['./home-doctor.component.css']
})

export class HomeDoctorComponent implements OnInit {

    constructor(
        private formHomeService: FormHomeService,
        private _snackBar: MatSnackBar
    ) { }

    loading: boolean = false;
    warningMap = new Map();

    name: string = '';
    email: string = '';
    phone: string = '';
    crm: string = '';
    specialty: string = '';
    message: string = '';

    error_message: string = '';

    ngOnInit(): void {

    }

    sendInfoForm() {
        this.formHomeService.sendForm('information', this.name, this.email, this.phone, this.crm, this.specialty, null, null, this.message).subscribe(data => {
            //console.log(data)

            this.name = '';
            this.email = '';
            this.phone = '';
            this.crm = '';
            this.specialty = '';
            this.message = '';

            this.loading = false;
            this.openSnackBar("Enviado!", "Fechar")
        },
            error => {
                this.loading = false;
                //console.log(error)
            })
    }

    sendForm() {
        this.error_message = ''
        this.warningMap = new Map();


        if (this.name == "" || this.name == null) {
            this.error_message = 'Por favor, insira o nome.'
        }
        else if (this.email == "" || this.email == null) {
            this.error_message = 'Por favor, insira o email.'
        }
        else if (this.phone == "" || this.phone == null) {
            this.error_message = 'Por favor, insira o telefone.'
        }
        else if (this.crm == "" || this.crm == null) {
            this.error_message = 'Por favor, insira o registro de classe.'
        }
        else if (this.specialty == "" || this.specialty == null) {
            this.error_message = 'Por favor, insira a especialidade.'
        }
        else if (this.message == "" || this.message == null) {
            this.error_message = 'Por favor, insira a mensagem.'
        }


        if (this.error_message == '' && !this.loading) {
            this.loading = true;
            this.sendInfoForm()
        }
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 4000,
        });
    }
}
