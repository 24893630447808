export class ValidatorUtils {

    convertMoney(money) {
		var formatter = new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		});
		return formatter.format(money);
	}

	isEmptyOrNull(str: string): boolean{
		if(str == null || str == "") return true
		else return false
	}

	/* Should be a valid CPF */
	isValidCPF(str): boolean {
		if (typeof str !== "string") return false;
		str = str.replace(/[\s.-]*/igm, '')
	    if (!str || str.length != 11 ||
			str == "00000000000" ||
			str == "11111111111" ||
			str == "22222222222" ||
			str == "33333333333" ||
			str == "44444444444" ||
			str == "55555555555" ||
			str == "66666666666" ||
			str == "77777777777" ||
			str == "88888888888" ||
			str == "99999999999") {
	        return false
		}
		let sum = 0
		let remainder;
		for (let i = 1; i <= 9; i++) 
			sum = sum + parseInt(str.substring(i-1, i)) * (11 - i);

		remainder = (sum * 10) % 11;
		if ((remainder == 10) || (remainder == 11))  remainder = 0;
		if (remainder != parseInt(str.substring(9, 10)) ) return false;
		sum = 0;
		for (let i = 1; i <= 10; i++) 
			sum = sum + parseInt(str.substring(i-1, i)) * (12 - i)

		remainder = (sum * 10) % 11
		if ((remainder == 10) || (remainder == 11))  remainder = 0
		if (remainder != parseInt(str.substring(10, 11) ) ) return false

		return true
	}

}