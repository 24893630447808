import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { CompanyService } from "../../service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalFormCreateQuestionAlternativeComponent } from "../modal-form-create-question-alternative/modal-form-create-question-alternative.component";
import { ModalChoiceComponent } from "../modal-choice/modal-choice.component";

@Component({
  selector: "modal-form-create-question",
  templateUrl: "./modal-form-create-question.component.html",
  styleUrls: ["./modal-form-create-question.component.css"],
})
export class ModalFormCreateQuestionComponent {
  constructor(
    public modalRef2: MDBModalRef,
    public sanitizer: DomSanitizer,
    private modalService: MDBModalService
  ) {
    //console.log(this.modalRef2);
  }

  action: Subject<any> = new Subject();
  modalRef3: MDBModalRef;

  title: string = "";

  form_clone;
  is_new: boolean;
  loading: boolean = false;
  titleQuestion: string;
  position: number = 0;
  type: string = null;
  required: boolean = false;
  number_of_choices: number = 1;
  alternatives = [];
  validator = new ValidatorUtils();
  type_date: string = "";
  min_frequency: number;
  max_frequency: number;
  range_frequency: number = 1;
  intervals_score_answer: any = [];
  

  start_value: number;
  end_value: number;
  score: number;
  ngOnInit() {}

  isEmpty(str) {
    if (!str || !str.length) {
      return true;
    }
    return false;
  }

  submit() {
    if (this.isEmpty(this.titleQuestion)) {
      this.openMessage(
        "Erro",
        "Você precisa informar um título para a questão"
      );
      return;
    }
    if (!this.type) {
      this.openMessage("Erro", "Você precisa informar o tipo de questão");
      return;
    }

    if (
      this.type === "7" &&
      (!this.intervals_score_answer || !this.intervals_score_answer.length)
    ) {
      this.openMessage("Erro", "Você precisa informar pelo menos um intervalo");
      return;
    }
    if (
      this.type === "0" ||
      this.type === "2" ||
      this.type === "3" ||
      this.type === "7"
    ) {
      const question = {
        title: this.titleQuestion,
        position: this.position || 0,
        required: this.required,
        is_selectable: false, 
        is_list: false, 
        is_numeric: this.type === "2" || this.type === "7",
        is_numeric_scale: this.type === "7",
        is_image: this.type === "3",
        is_date: false,
        type_date: null,
        is_frequency: false,
        min_frequency: null,
        max_frequency: null,
        range_frequency: null,
        is_scale: false,
        intervals_score_answer: this.intervals_score_answer
      };
      this.sendQuestion(question);
    } else if (this.type === "1" || this.type === '8') {
      if (!this.alternatives || !this.alternatives.length) {
        this.openMessage(
          "Erro",
          "Você precisa adicionar pelo menos uma alternativa"
        );
        return;
      }

      const question = {
        title: this.titleQuestion,
        position: this.position || 0,
        required: this.required,
        is_selectable: this.type === '1' ? true : false,
        is_list: this.type === '8' ? true : false,
        number_of_choices: this.number_of_choices,
        alternatives: this.alternatives,
        is_numeric: false,
        is_numeric_scale: false,
        is_image: false,
        is_date: false,
        type_date: null,
        is_frequency: false,
        min_frequency: null,
        max_frequency: null,
        range_frequency: null,
        is_scale: false,
      };
      this.modalRef2.hide();
      this.sendQuestion(question);
    } else if (this.type === "6") {
      if (!this.alternatives || !this.alternatives.length) {
        this.openMessage(
          "Erro",
          "Você precisa adicionar pelo menos uma alternativa"
        );
        return;
      }

      const question = {
        title: this.titleQuestion,
        position: this.position || 0,
        required: this.required,
        is_selectable: true,
        is_list: false,
        number_of_choices: this.number_of_choices,
        alternatives: this.alternatives,
        is_numeric: false,
        is_numeric_scale: false,
        is_image: false,
        is_date: false,
        type_date: null,
        is_frequency: false,
        min_frequency: null,
        max_frequency: null,
        range_frequency: null,
        is_scale: true,
      };
      this.modalRef2.hide();
      this.sendQuestion(question);
    } else if (this.type === "4") {
      if (!this.type_date) {
        this.openMessage("Erro", "Você precisa informar o tipo de data");
        return;
      }

      const question = {
        title: this.titleQuestion,
        position: this.position || 0,
        required: this.required,
        is_selectable: false,
        is_list: false,
        alternatives: this.alternatives,
        is_numeric: false,
        is_numeric_scale: false,
        is_image: false,
        is_date: true,
        type_date:
          this.type_date === "0"
            ? "full"
            : this.type_date === "1"
            ? "date"
            : "hour",
        is_frequency: false,
        min_frequency: null,
        max_frequency: null,
        range_frequency: null,
        is_scale: false,
      };
      this.modalRef2.hide();
      this.sendQuestion(question);
    } else if (this.type === "5") {
      if (this.min_frequency === null || this.min_frequency === undefined) {
        this.openMessage("Erro", "Você precisa informar o valor mínimo");
        return;
      }

      if (this.max_frequency === null || this.max_frequency === undefined)  {
        this.openMessage("Erro", "Você precisa informar o valor máximo");
        return;
      }

      if (this.min_frequency > this.max_frequency) {
        this.openMessage(
          "Erro",
          "O valor mínimo não pode ser maior que o valor máximo"
        );
        return;
      }

      if (!this.range_frequency) {
        this.openMessage("Erro", "Você precia informar o intervalo");
        return;
      }

      const question = {
        title: this.titleQuestion,
        position: this.position || 0,
        required: this.required,
        is_selectable: false,
        is_list: false,
        alternatives: this.alternatives,
        is_numeric_scale: false,
        is_numeric: false,
        is_image: false,
        is_date: false,
        type_date: null,
        is_frequency: true,
        min_frequency: this.min_frequency,
        max_frequency: this.max_frequency,
        range_frequency: this.range_frequency,
        is_scale: false,
      };
      this.modalRef2.hide();
      this.sendQuestion(question);
    }
  }

  sendQuestion(question) {
    this.action.next(question);
    this.modalRef2.hide();
  }

  openMessage(title, text, bgclick: boolean = false) {
    this.modalRef3 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  addAlternative(pos = null) {
    this.modalRef3 = this.modalService.show(
      ModalFormCreateQuestionAlternativeComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: true,
        ignoreBackdropClick: true,
        class: "modal-dialog-centered modal-dialog modal-lg",
        containerClass: "",
        animated: false,
        data: {
          title: pos !== null ? "Editar Alternativa" : "Salvar Alternativa",
          btn_text: pos !== null ? "Editar" : "Adicionar",
          is_new: pos !== null ? false : true,
          score: pos !== null ? this.alternatives[pos].score : null,
          titleQuestion: pos !== null ? this.alternatives[pos].title : null,
          position: pos !== null ? this.alternatives[pos].position : 0,
          is_scale: this.type === "6",
        },
      }
    );
    this.modalRef3.content.action.subscribe((result: any) => {
      const aux = this.alternatives;
      if (pos !== null) {
        aux[pos] = result;
      } else {
        aux.push(result);
      }
      aux.sort((a, b) => a.position - b.position);
      this.alternatives = aux;
    });
  }

  close() {
    this.modalRef2.hide();
  }

  deleteAlternative(pos) {
    this.modalRef3 = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja deletar a alternativa?`,
      },
    });
    this.modalRef3.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.alternatives.splice(pos, 1);
      }
    });
  }

  editAlternative(pos) {
    this.addAlternative(pos);
  }

  addInterval() {
    if (this.start_value === null || !Number.isInteger(this.start_value)) {
      this.openMessage("Erro", "Você precisa informar um valor inicial válido");
      return;
    }
    if (this.end_value === null || !Number.isInteger(this.end_value)) {
      this.openMessage("Erro", "Você precisa informar um valor final válido");
      return;
    }
    if (this.start_value > this.end_value) {
      this.openMessage(
        "Erro",
        "O valor inicial não pode ser maior que o final"
      );
      return;
    }

    if (this.score === null || !Number.isInteger(this.score)) {
      this.openMessage(
        "Erro",
        "Você precisa informar um valor de pontuação válido"
      );
      return;
    }

    this.intervals_score_answer.push({
      start_value: this.start_value,
      end_value: this.end_value,
      score: this.score,
    });

    this.start_value = null;
    this.end_value = null;
    this.score = null;
  }

  deleteInterval(i) {
    this.intervals_score_answer.splice(i, 1);
  }
}
