import { Component, ElementRef, Input, ViewChild, ViewChildren } from "@angular/core";

@Component({
  selector: "chart",
  templateUrl: "./chart.component.html",
  styleUrls: [],
})
export class ChartComponent {
  @Input() canvas;
  @Input() id;
  @ViewChildren('mycharts') allMyCanvas: any;

  ngAfterViewInit() {
    console.log(this.canvas, this.id);
  }
}
  