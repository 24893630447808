import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalAddMedicationComponent } from "src/app/modals/modal-add-medication/modal-add-medication.component";
import { MedicationService } from "src/app/service/medication.service";
import { PatientMedicationService } from "src/app/service/patient-medication.service";

@Component({
  selector: "app-medicamentos",
  templateUrl: "./medicamentos.component.html",
  styleUrls: ["./medicamentos.component.css"],
})
export class MedicamentosComponent {
  constructor(
    private patientMedicationService: PatientMedicationService,
    private medicationService: MedicationService,

    private modalService: MDBModalService
  ) {}

  modalRef: MDBModalRef;

  action: Subject<any> = new Subject();
  msg_error: string = "";
  loading: boolean = true;
  loadingHour: boolean = false;
  loadingHourId;
  title: string = "";
  btn_text: string;
  is_new: boolean = true;
  @Input() patient_id: number;
  @Input() history: boolean;

  week = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };

  selectedMedication;
  listMedication = [];
  tamlist: number = 0;
  medication;

  ngOnInit(): void {
    this.getSpecificPatientId(this.patient_id);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.patient_id = changes["patient_id"].currentValue;
    this.getSpecificPatientId(this.patient_id);
  }

  getMedication(id) {
    this.medicationService.getMedicationId(id).subscribe(
      (data) => {
        //console.log(data);
      },
      (error) => {
        //console.log(error);
      }
    );
  }

  getSpecificPatientId(id) {
    this.loading = true;
    this.patientMedicationService.getSpecificPatientId(id).subscribe(
      (data) => {
        this.listMedication = data;
        //console.log(this.listMedication);
        this.tamlist = this.listMedication.length;
        this.loading = false;
        //console.log(this.tamlist);
      },
      (error) => {
        //console.log(error);
      }
    );
  }

  openAddMedication() {
    this.modalRef = this.modalService.show(ModalAddMedicationComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: "Adicionar medicamento",
        btn_text: "Adicionar",
        is_new: true,
        patient_id: this.patient_id,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getSpecificPatientId(this.patient_id);
      }
    });
  }

  getSchedule(item, id) {
    this.loadingHour = true;
    this.loadingHourId = id;
    this.medicationService.getSchedule(item.id).subscribe(
      (data) => {
        const hours = [...new Set(data.map((val) => val.hour))].join(", ");
        const days = [...new Set(data.map((val) => val.day))].join(", ");

        this.listMedication[id].hours = hours;
        this.listMedication[id].days = days;
        this.loadingHour = false;
      },
      (error) => {
        this.loadingHour = true;
      }
    );
  }

  isContinous(data) {
    return data === "2300-01-01T00:00:00"
  }

  twoplaces(d) {
    if(d < 10) 
      return '0' + d;
    return d;
  }

  getDays(days) {
    const arr = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"]
    let ans = "";
    for(const i in days) {
      if(ans.length) 
        ans += ", ";
      ans += arr[days[i]]
    }
    return ans;
  }

  getHours(hours) {
    let ans = "";

    for(const i in hours) {
      const h = hours[i];
      if(ans.length) 
        ans += ", ";
      ans += this.twoplaces(Math.floor(h/60)) + ":" + this.twoplaces(h%60);
    }
    return ans;
  } 
}
