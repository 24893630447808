import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class InformationsService {

    constructor(private http : HttpClient) { }

    getSpecialty() : Observable<any>{
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                /*.set('Authorization', `token ${localStorage.getItem('token')}`)*/,
        }

        return this.http.get<any>(AppConfig.path + "core/specialty/", options)
    }

    getCompanyType() : Observable<any>{
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                /*.set('Authorization', `token ${localStorage.getItem('token')}`)*/,
        }

        return this.http.get<any>(AppConfig.path + "core/company/types/", options)
    }

    newSpecialty(name) : Observable<any> {
        let body = new HttpParams()
            .append('name', name)
        
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + "core/specialty/", body.toString(), { headers })
    }

    editSpecialty(name, id) : Observable<any> {
        let body = new HttpParams()
            .append('name', name)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/specialty/${id}/`, body.toString(), { headers })
    }

    deleteSpecialty(id) : Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/specialty/${id}/`, { headers })
    }

    getAssociation() : Observable<any>{
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
               /* .set('Authorization', `token ${localStorage.getItem('token')}`)*/,
        }

        return this.http.get<any>(AppConfig.path + "core/association/", options)
    }

    newAssociation(name) : Observable<any> {
        let body = new HttpParams()
            .append('name', name)
        
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + "core/association/", body.toString(), { headers })
    }

    editAssociation(name, id) : Observable<any> {
        let body = new HttpParams()
            .append('name', name)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/association/${id}/`, body.toString(), { headers })
    }

    deleteAssociation(id) : Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/association/${id}/`, { headers })
    }

}