import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { CompanyService } from "../../service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalFormCreateQuestionComponent } from "../modal-form-create-question/modal-form-create-question.component";
import { ModalChoiceComponent } from "../modal-choice/modal-choice.component";
import { ModalFormPermissionsComponent } from "../modal-form-permissions/modal-form-permissions.component";

@Component({
  selector: "modal-core-plan-create",
  templateUrl: "./modal-core-plan-create.component.html",
  styleUrls: ["./modal-core-plan-create.component.css"],
})
export class ModalCorePlanCreateComponent {
  url: string = "https://www.mmlpqtpkasjdashdjahd.com";
  urlSafe: SafeResourceUrl;
  constructor(
    public modalRef: MDBModalRef,
    public sanitizer: DomSanitizer,
    private modalService: MDBModalService,
    private companyService: CompanyService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";

  core_plan;
  is_new: boolean;
  loading: boolean = false;
  name: string;
  selected_forms: any[] = [];
  forms: any[];
  core_plan_id: number;
  ngOnInit() {
    this.getForms();
  }

  isEmpty(str) {
    if (!str || !str.length) {
      return true;
    }
    return false;
  }

  getForms() {
    this.loading = true;
    this.companyService.getForms().subscribe((data) => {
      this.forms = data;
      this.loading = false;
    });
  }
  submit() {
    if (this.isEmpty(this.name)) {
      this.openMessage(
        "Erro",
        "Você precisa informar o nome do plano de cuidado"
      );
      return;
    }

    if (!this.selected_forms || !this.selected_forms.length) {
      this.openMessage(
        "Erro",
        "Você precisa vincular pelo menos um formulário nesse plano de cuidado"
      );
      return;
    }

    const form = {
      name: this.name,
      forms: this.selected_forms,
    };

    this.loading = true;

    if (this.is_new) {
      this.companyService.createCorePlan(form).subscribe(
        (data) => {
          this.openMessage("Sucesso", "Plano de cuidado criado!");
          this.action.next(true);
          this.modalRef.hide();
        },
        (error) => {
          let msg = "Ocorreu um erro, tente novamente";
          if (
            error.response &&
            error.response.data &&
            error.response.data.non_field_errors &&
            error.response.data.non_field_errors.length > 0
          ) {
            msg = error.response.data.non_field_errors[0];
          }
          this.openMessage("Erro", msg);

          this.loading = false;
        }
      );
    } else {
      this.companyService.updateCorePlan(form, this.core_plan_id).subscribe(
        (data) => {
          this.openMessage("Sucesso", "Plano de cuidado editado!");
          this.action.next(true);
          this.modalRef2.hide();
        },
        (error) => {
          let msg = "Ocorreu um erro, tente novamente";
          if (
            error.response &&
            error.response.data &&
            error.response.data.non_field_errors &&
            error.response.data.non_field_errors.length > 0
          ) {
            msg = error.response.data.non_field_errors[0];
          }
          this.openMessage("Erro", msg);

          this.loading = false;
        }
      );
    }
  }

  openMessage(title, text, bgclick: boolean = false) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
