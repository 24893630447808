import { Component, OnInit } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Md5 } from "md5-typescript";
import { Subject } from "rxjs";
import { ModalChoiceComponent } from "src/app/modals/modal-choice/modal-choice.component";
import { ModalFormCreateQuestionComponent } from "src/app/modals/modal-form-create-question/modal-form-create-question.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { AdminService } from "src/app/service/admin.service";
import { CompanyService } from "src/app/service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { ModalDiagnosisIndicatoromponent } from "../modal-diagnosis-indicator/modal-diagnosis-indicator.component";
import { ModalDiagnosisNicNocComponent } from "../modal-diagnosis-nic-noc/modal-diagnosis-nic-noc.component";
import { ModalDiagnosisClassComponent } from "../modal-diagnosis-class/modal-diagnosis-class.component";

@Component({
  selector: "app-modal-diagnosis",
  templateUrl: "./modal-diagnosis.component.html",
  styleUrls: ["./modal-diagnosis.component.css"],
})
export class ModalDiagnosisomponent implements OnInit {
  modalRef2: MDBModalRef;
  loading: boolean = false;

  action: Subject<any> = new Subject();

  title: string = "";
  btn_text: string;
  diagnosis: any;

  validator = new ValidatorUtils();
  code: string = "";
  name: string = "";
  description: string = "";

  characteristics: string = "";
  related_factors: string = "";
  necessary_conditions: string = "";
  risk_population: string = "";

  indicators: any[] = [];
  class: any;
  domain: any;
  nocs: any[] = [];
  nics: any[] = [];

  all_indicators = [];
  all_nocs = [];
  all_nics = [];
  all_class = [];

  loadingIndicator = false;
  loadingNoc = false;
  loadingNic = false;
  loadingClass = false;

  constructor(
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private adminService: AdminService,
    public modalRef: MDBModalRef
  ) {}

  ngOnInit(): void {
    if (this.diagnosis) this.getDiagnosisDetail();
    else {
      this.loadIndicator();
      this.loadNoc();
      this.loadNic();
      this.loadClass();
    }
  }

  getDiagnosisDetail() {
    this.loading = true;
    this.adminService.getById(this.diagnosis.id).subscribe((data) => {
      this.loadIndicator();
      this.loadNoc();
      this.loadNic();
      this.loadClass();

      this.code = data.code
      this.description = data.description
      this.name = data.name

      this.characteristics = data.characteristics
      this.related_factors = data.related_factors
      this.necessary_conditions = data.necessary_conditions
      this.risk_population = data.risk_population

      this.class = data.diagnosis_class.id
      this.nocs = data.diagnosis_nocs.map(d => d.id)
      this.nics = data.diagnosis_nics.map(d => d.id)
      this.indicators = data.diagnosis_indicators.map(d => d.id)
      this.loading = false;
    });
  }

  save() {
    if (this.validator.isEmptyOrNull(this.code)) {
      this.openMessage("Por favor,", "informe o código do diagnóstico.", false);
      return false;
    }

    if (this.validator.isEmptyOrNull(this.name)) {
      this.openMessage("Por favor,", "informe o nome do diagnóstico.", false);
      return false;
    }

    if (this.validator.isEmptyOrNull(this.description)) {
      this.openMessage(
        "Por favor,",
        "informe a descrição do diagnóstico.",
        false
      );
      return false;
    }

    if (this.indicators.length == 0) {
      this.openMessage(
        "Por favor,",
        "informe informe pelo menos um indicador.",
        false
      );
      return false;
    }
    if (this.nocs.length == 0) {
      this.openMessage(
        "Por favor,",
        "informe informe pelo menos um NOC.",
        false
      );
      return false;
    }
    if (this.nics.length == 0) {
      this.openMessage(
        "Por favor,",
        "informe informe pelo menos um NIC.",
        false
      );
      return false;
    }

    if (this.validator.isEmptyOrNull(this.class)) {
      this.openMessage(
        "Por favor,",
        "informe informe a classe para o diagnóstico.",
        false
      );
      return false;
    }

    const json = {
      code: this.code,
      name: this.name,
      description: this.description,
      characteristics: this.characteristics,
      related_factors: this.related_factors,
      necessary_conditions: this.necessary_conditions,
      risk_population: this.risk_population,
      indicators: this.indicators,
      nics: this.nics,
      nocs: this.nocs,
      class: this.class,
    };

    if(this.diagnosis) {
      this.adminService.updateDiagnostic(json, this.diagnosis.id).subscribe(
        (data) => {
          this.openMessage("Sucesso", "Diagnóstico editado com sucesso", false);
          this.action.next(true);
          this.modalRef.hide();
        },
        (error) => {
          this.error(error, "editar");
        }
      );
    } else{
      this.adminService.createDiagnostic(json).subscribe(
        (data) => {
          this.openMessage("Sucesso", "Diagnóstico criado", false);
          this.action.next(true);
          this.modalRef.hide();
        },
        (error) => {
          this.error(error, "criar");
        }
      );
    }

    
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível ${string} o diagnóstico.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  getColor(ind) {
    if (this.indicators.indexOf(ind.id) > -1)
      return "background-color:  #AEA2E440";
    return "background-color:  transparent";
  }

  selectInd(ind) {
    let index = this.indicators.indexOf(ind.id);
    if (index > -1) this.indicators.splice(index, 1);
    else this.indicators.push(ind.id);
  }

  getColorNoc(noc) {
    if (this.nocs.indexOf(noc.id) > -1) return "background-color:  #AEA2E440";
    return "background-color:  transparent";
  }

  selectNoc(noc) {
    let index = this.nocs.indexOf(noc.id);
    if (index > -1) this.nocs.splice(index, 1);
    else this.nocs.push(noc.id);
  }

  getColorNic(nic) {
    if (this.nics.indexOf(nic.id) > -1) return "background-color:  #AEA2E440";
    return "background-color:  transparent";
  }

  selectNic(nic) {
    let index = this.nics.indexOf(nic.id);
    if (index > -1) this.nics.splice(index, 1);
    else this.nics.push(nic.id);
  }

  createIndicator() {
    this.modalRef2 = this.modalService.show(ModalDiagnosisIndicatoromponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {},
    });

    this.modalRef2.content.action.subscribe((result: any) => {
      if (result) {
        this.loadIndicator();
      }
    });
  }

  loadIndicator() {
    this.loadingIndicator = true;
    this.adminService.getAllIndicators().subscribe(
      (data) => {
        this.all_indicators = data;
        this.loadingIndicator = false;
      },
      (error) => {
        //this.error(error, "editar");
      }
    );
  }

  loadNoc() {
    this.loadingNoc = true;
    this.adminService.getAllNocs().subscribe(
      (data) => {
        this.all_nocs = data;
        this.loadingNoc = false;
      },
      (error) => {
        //this.error(error, "editar");
      }
    );
  }

  loadNic() {
    this.loadingNic = true;
    this.adminService.getAllNics().subscribe(
      (data) => {
        this.all_nics = data;
        this.loadingNic = false;
      },
      (error) => {
        //this.error(error, "editar");
      }
    );
  }

  loadClass() {
    this.loadingClass = true;
    this.adminService.getAllClasses().subscribe(
      (data) => {
        this.all_class = data;
        this.loadingClass = false;
      },
      (error) => {
        //this.error(error, "editar");
      }
    );
  }

  createNic() {
    this.createNicNoc(true);
  }

  createNoc() {
    this.createNicNoc(false);
  }

  createClass() {
    this.modalRef2 = this.modalService.show(ModalDiagnosisClassComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {},
    });

    this.modalRef2.content.action.subscribe((result: any) => {
      if (result) {
        this.loadClass();
      }
    });
  }

  createNicNoc(isNic) {
    this.modalRef2 = this.modalService.show(ModalDiagnosisNicNocComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        isNic,
      },
    });

    this.modalRef2.content.action.subscribe((result: any) => {
      if (result) {
        if (isNic) this.loadNic();
        else {
          this.loadNoc();
        }
      }
    });
  }
}
