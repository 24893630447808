import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {

    constructor(
        private router: Router
    ) {
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                var list_url = this.router.url.split('/')
                var tam = list_url.length
                let url_page = list_url[tam - 1];

                if(url_page == 'about' || url_page == 'homecare' || url_page == 'partners') {
                    this.footer_purple = true;
                }
                else {
                    this.footer_purple = false;
                }
            }
        });
    }

    footer_purple: boolean = false;

    ngOnInit(): void {
        
    }

}
