import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormHomeService } from 'src/app/service/form-home.service';

@Component({
    selector: 'app-partners',
    templateUrl: './partners.component.html',
    styleUrls: ['./partners.component.css']
})

export class PartnersComponent implements OnInit {

    @ViewChild("form", { static: false }) form;

    constructor(
        private formHomeService: FormHomeService,
        private _snackBar: MatSnackBar
    ) { }

    check_items = [1, 2, 3, 4, 5, 6]
    plans = ['Plano Basic', 'Plano Pro', 'Plano Premium', 'Plano Platinum']

    loading: boolean = false;
    warningMap = new Map();

    name: string = '';
    email: string = '';
    phone: string = '';
    business_activity: string = '';
    message: string = '';

    error_message: string = '';

    ngOnInit(): void {
       
    }

    navigateTo(element: string) {
        this[element].nativeElement.scrollIntoView({ block: "start", behavior: "smooth" });
       
        if(element != 'top'){
            const yOffset = -135; 
            const position = document.getElementById(element);
            const y = position.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});  
        }
    }

    sendInfoForm() {
        this.formHomeService.sendForm('information', this.name, this.email, this.phone, null, null, null, this.business_activity, this.message).subscribe(data => {
            //console.log(data)

            this.name = '';
            this.email = '';
            this.phone = '';
            this.business_activity = '';
            this.message = '';

            this.loading = false;
            this.openSnackBar("Enviado!", "Fechar")
        },
            error => {
                this.loading = false;
                //console.log(error)
            })
    }

    sendForm() {
        this.error_message = ''
        this.warningMap = new Map();


        if (this.name == "" || this.name == null) {
            this.error_message = 'Por favor, insira o nome.'
        }
        else if (this.email == "" || this.email == null) {
            this.error_message = 'Por favor, insira o email.'
        }
        else if (this.phone == "" || this.phone == null) {
            this.error_message = 'Por favor, insira o telefone.'
        }
        else if (this.business_activity == "" || this.business_activity == null) {
            this.error_message = 'Por favor, insira a atividade do negócio.'
        }
        else if (this.message == "" || this.message == null) {
            this.error_message = 'Por favor, insira a mensagem.'
        }

        if (this.error_message == '' && !this.loading) {
            this.loading = true;
            this.sendInfoForm()
        }
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 4000,
        });
    }
}
