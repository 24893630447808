import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { CompanyService } from "../../../service/company.service";
import { DoctorService } from "../../../service/doctor.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalFormPermissionsDiagnosisAddComponent } from "./modal-form-permissions-diagnosis-add/modal-form-permissions-diagnosis-add.component";

@Component({
  selector: "app-modal-form-permissions-diagnosis",
  templateUrl: "./modal-form-permissions-diagnosis.component.html",
  styleUrls: ["./modal-form-permissions-diagnosis.component.css"],
})
export class ModalFormPermissionsDiagnosisComponent {
  modalRef2: MDBModalRef;
  constructor(
    public modalRef: MDBModalRef,
    public sanitizer: DomSanitizer,
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private doctorService: DoctorService
  ) {}
  @Input() questions: any;

  ngOnInit() {
    console.log(this.questions);
  }

  hasSelectable() {
    if(!this.questions || !this.questions.length) return false;
    for(const q of this.questions) {
      if(q.is_selectable || q.is_list)
        return true;
    }
    return false;
  }

  openAdd(questionId, alternativeId, alternative) {
    
    this.modalRef2 = this.modalService.show(ModalFormPermissionsDiagnosisAddComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-xl modal-size modal-xl",
      containerClass: "",
      animated: false,
      data: {
        alternative,
        selected_diagnosis: alternative.diagnosis
      },
    });

    this.modalRef2.content.action.subscribe((result: any) => {
      if (result) {
        // this.questions[questionId].alternatives[alternativeId].diagnosis = result;
        this.modalRef2.hide();
      }
    });
  }

  getDiagnosisName(dia) {
    let ans = ""
    for(const q of dia) {
      if(ans.length > 0)
        ans += ', ';
      ans += q.name
    }
    return ans;
  }
}
