import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalFormAnswerComponent } from "src/app/modals/modal-form-answer/modal-form-answer.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { CompanyService } from "src/app/service/company.service";
import { DoctorService } from "src/app/service/doctor.service";
import { MedicationService } from "src/app/service/medication.service";
import { MemedService } from "src/app/service/memed.service";
import { PrescricoesService } from "./prescicoes.service";
import { FormControl, FormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-prescricoes",
  templateUrl: "./prescricoes.component.html",
  styleUrls: ["./prescricoes.component.css"],
})
export class PrescricaoComponent {
  constructor(
    private companyService: CompanyService,
    private medicationService: MedicationService,
    private modalService: MDBModalService,
    private prescricoesService: PrescricoesService,
    private doctorService: DoctorService,
    private memedService: MemedService,
    private datePipe: DatePipe
  ) {}

  modalRef2: MDBModalRef;
  action: Subject<any> = new Subject();
  msg_error: string = "";
  loading: boolean = false;

  modalRef: MDBModalRef;
  title: string = "";
  btn_text: string;
  is_new: boolean = true;
  @Input() patient: any;
  @Input() history: boolean;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  week = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };

  selectedMedication;
  listAnswer = [];
  tamlist: number = 0;
  medication;

  memed: any = null;
  doctor = null;
  prescriptions: [] = [];
  loadingLinkPrescription = null;
  ngOnInit(): void {
    this.range.setValue({ start: new Date(), end: new Date() });
    this.getPrescriptions();
    this.memed =
      localStorage.getItem("memed") === "null"
        ? null
        : JSON.parse(localStorage.getItem("memed"));
    this.doctor =
      localStorage.getItem("doctor") === "null"
        ? null
        : JSON.parse(localStorage.getItem("doctor"));
    //console.log(this.memed);
    //console.log(this.memed == null);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.patient = changes["patient"].currentValue;
    // //console.log(this.patient)
    // this.prescricoesService.load(
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.WzQ0NDA0LCI0MzRkMjQ5N2JiYzg5ZTE1MWQ3NThkNjNjMjg0NDgyZiIsIjIwMjItMTItMTUiLCJzaW5hcHNlLnByZXNjcmljYW8iLCJwYXJ0bmVyLjMuMzk3MTQiXQ.ScNWG48OdJXs5SBiA9aaxxxbtn-rzw1ea1FWJrIiNDI",
    //   this.patient
    // );
  }

  newPrescription() {
    const token = this.memed.attributes.token;
    console.log(this.memed);
    this.prescricoesService.load(
      token,
      this.patient,
      this.doctor,
      this.memedService,
      this.onPrescription
    );
  }

  onPrescription(data, token, patient_id, doctor_id, memedService) {
    console.log("Nova prescrição", data);

    const medications = data.prescricao.medicamentos.map((medi) => {
      return {
        medication_id: medi.id,
        name: medi.nome,
        description: medi.descricao,
        posology: medi.sanitized_posology,
        quantity: medi.quantidade,
        unit: medi.unit,
      };
    });

    const allergies = data.prescricao.paciente.allergy.map((allergy) => {
      return {
        allergy_id: allergy.id,
        ingredient_id: allergy.ingredient_id,
        ingredient_name: allergy.ingredient_name,
      };
    });

    const body = {
      token,
      patient: patient_id,
      doctor: doctor_id,
      prescription_id: data.prescricao.id,
      prescription_date: data.prescricao.prescriptionDate,
      medications,
      allergies,
    };

    memedService.savePrescription(body).subscribe((data) => {
      console.log("foi");
    });
  }

  async registerMemed() {
    this.loading = true;

    this.doctorService.registerMemed().subscribe(
      (data) => {
        this.memedService.getStatus().subscribe(
          (data) => {
            if (data === null) {
              this.openMessage(
                "Erro",
                "Ocorreu um erro ao se cadastrar com a Memed",
                false
              );
            }
            this.memed = data.status;
            this.doctor = data.doctor;

            localStorage.setItem("memed", JSON.stringify(this.memed));
            localStorage.setItem("doctor", JSON.stringify(this.doctor));
            this.loading = false;
          },
          (err) => {
            let message = "Ocorreu um erro ao se cadastrar com a Memed";
            if (err && err.error && err.error.detail) message = err.error.detail;
            this.openMessage("Erro", message, false);
            this.loading = false;
          }
        );
      },
      (err) => {
        let message = "Ocorreu um erro ao se cadastrar com a Memed";
        if (err && err.error && err.error.detail) message = err.error.detail;
        this.openMessage("Erro", message, false);
        this.loading = false;
      }
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  getPrescriptions() {
    let date = this.datePipe.transform(this.range.value.start, "yyyy-MM-dd");
    let dateEnd = this.datePipe.transform(this.range.value.end, "yyyy-MM-dd");
    if (!date || !dateEnd) return;
    this.loading = true;

    this.memedService
      .getPrescriptions(this.patient.id, date, dateEnd)
      .subscribe((data) => {
        this.loading = false;
        this.prescriptions = data;
      });
  }

  getPrescriptionLink(token, id, pdf = false) {
    this.loadingLinkPrescription = id;
    if (pdf) {
      fetch(
        `https://api.memed.com.br/v1/prescricoes/${id}/url-document/full?token=${token}`,
        { method: "GET" }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.loadingLinkPrescription = null;
          if (
            data.data &&
            data.data.length &&
            data.data[0].attributes &&
            data.data[0].attributes.link
          ) {
            window.open(data.data[0].attributes.link, "_blank");
          } else {
            throw Error();
          }
        })
        .catch(() => {
          this.loadingLinkPrescription = null;
          this.openMessage(
            "Erro",
            "Ocorreu um erro para gerar o link da prescrição",
            false
          );
        });
    } else {
      fetch(
        `https://api.memed.com.br/v1/prescricoes/${id}/get-digital-prescription-link?token=${token}`,
        { method: "GET" }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (
            data.data &&
            data.data.length &&
            data.data[0].attributes &&
            data.data[0].attributes.link
          ) {
            window.open(data.data[0].attributes.link, "_blank");
          } else {
            throw Error();
          }
          this.loadingLinkPrescription = null;
        })
        .catch(() => {
          this.loadingLinkPrescription = null;
          this.openMessage(
            "Erro",
            "Ocorreu um erro para gerar o link da prescrição",
            false
          );
        });
    }
  }
}
