import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalFormQuestionCaptionComponent } from "../modal-form-question-caption/modal-form-question-caption";

@Component({
  selector: "form-question-select",
  templateUrl: "./form-question-select.component.html",
  // styleUrls: ["./modal-fill-form.component.css"],
})
export class FormQuestionSelectComponent {
  @Input() required: any;
  @Input() title: any;
  @Input() answer: any;
  @Input() setAnswer: any;
  @Input() choices: any;
  @Input() alternatives: any;
  @Input() caption_image: any;
  @Input() caption_text: any;
  @Input() totalScore: any;
  @Input() is_list: any;
  @Input() isEditing: any;
  answerAux: any = "";

  modalRef: MDBModalRef;
  constructor(private modalService: MDBModalService) {}

  ngOnInit() {
    // console.log(this.answer);
    if(this.isEditing && this.answer) {
      // console.log('aqui')
      // console.log(this.answer);
      // this.answerAux = this.answer;
      const aux = []
      for(const x in this.answer)  {
        if(this.answer[x] === true)
          aux.push(Number(x))
      }
      this.answerAux = aux;
      // console.log(this.answerAux)
    }
  }

  getValue = (alternative, value) => {
    const ans = {};
    ans[alternative.id] = value;
    return ans;
  };

  countSelected = () => {
    let ans = 0;
    for (const val in this.answer) {
      if (this.answer[val]) {
        ans += this.answer[val] === true ? 1 : 0;
      }
    }
    return ans;
  };

  update(val, ev) {
    // this.setAnswer({
    //   ...this.answer,
    //   ...this.getValue(val, !this.answer[val.id]),
    // });
    // console.log(ev);
    // console.log(val);
    if (ev) this.totalScore.value += val?.score ?? 0;
    else this.totalScore.value -= val?.score ?? 0;

    // this.totalScore =
  }

  openCaption() {
    this.modalRef = this.modalService.show(ModalFormQuestionCaptionComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      // ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Legenda",
        caption_image: this.caption_image,
        caption_text: this.caption_text,
      },
    });
  }

  changeAnswers() {
    // console.log("chamou");
    // console.log(this.answerAux);
    for (const val in this.alternatives) {
      if (this.answerAux.indexOf(this.alternatives[val].id) > -1) {
        this.answer[this.alternatives[val].id] = true;
        this.update(this.alternatives[val], true);
      } else {
        this.answer[this.alternatives[val].id] = false;
        this.update(this.alternatives[val], false);
      }
    }
  }
}
