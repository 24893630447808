import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalChoiceComponent } from "../modals/modal-choice/modal-choice.component";
import { ModalFormAnswerListComponent } from "../modals/modal-form-answer-list/modal-form-answer-list.component";
import { ModalFormAnswerComponent } from "../modals/modal-form-answer/modal-form-answer.component";
import { ModalFormCreateComponent } from "../modals/modal-form-create/modal-form-create.component";
import { ModalFormDetailComponent } from "../modals/modal-form-detail/modal-form-detail.component";
import { ModalFormFillComponent } from "../modals/modal-form-fill/modal-form-fill.component";
import { ModalFormPermissionsComponent } from "../modals/modal-form-permissions/modal-form-permissions.component";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { CompanyService } from "../service/company.service";
import { MatSort } from "@angular/material/sort";
import { ModalFormModelListComponent } from "../modals/modal-form-model-list/modal-form-model-list.component";
import { ModalScoreAnswersCreateComponent } from "../modals/modal-scoreanswers-create/modal-scoreanswers-create.component";
import { ModalScoreAnswersFormComponent } from "../modals/modal-scoreanswers-form/modal-scoreanswers-form.component";
import { ModalScoreAnswerModelListComponent } from "../modals/modal-scoreanswer-model-list/modal-scoreanswer-model-list.component";

@Component({
  selector: "app-scoreanswers",
  templateUrl: "./scoreanswers.component.html",
  styleUrls: ["./scoreanswers.component.css"],
})
export class ScoreAnswersComponent implements OnInit {
  constructor(
    private companyService: CompanyService,
    private modalService: MDBModalService
  ) {}
  company_type = localStorage.getItem('companyType')
  displayedColumns: string[] = ["empty", "id", "name", "icons"];
  data_source = new MatTableDataSource<any>();

  modalRef: MDBModalRef;

  is_loaded: boolean = false;

  /* pagination related variabels */
  page: number = 1;
  count_total: number = 0;
  page_count1: number = 0;
  page_count2: number = 0;
  page_size = 10;

  is_company_admin: boolean = false;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngOnInit(): void {
    if (
      localStorage.getItem("admin") === "true" &&
      localStorage.getItem("company") !== null
    ) {
      this.is_company_admin = true;
      this.getScoreAnswers(1);
    }
  }

  truncateString(str, max) {
    return str.length > max ? str.substr(0, max - 1) + "…" : str;
  }

  getScoreAnswers(page) {
    this.is_loaded = false;
    this.companyService.getScoreAnswers().subscribe(
      (data) => {
        //console.log(data);

        this.data_source.data = data;
        this.data_source.sort = this.sort;
        // pagination calc
        this.page = page;
        this.count_total = data.length;
        this.page_count1 = 1 + (page - 1) * this.page_size;
        if (this.page_count1 + (this.page_size - 1) > this.count_total) {
          this.page_count2 = this.count_total;
        } else {
          this.page_count2 = this.page_count1 + (this.page_size - 1);
        }
        this.is_loaded = true;
      },
      (error) => {
        //console.log(error);
        this.is_loaded = true;
      }
    );
  }

  vinculateForm(answer) {
    
    this.modalRef = this.modalService.show(ModalScoreAnswersFormComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: "Vincular formulários",
        btn_text: "Adicionar",
        is_new: true,
        answer_id: answer.id
      },
    });
   
  }

  newAnswer(data = null) {
    this.modalRef = this.modalService.show(ModalScoreAnswersCreateComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: "Editar resposta da escala",
        btn_text: "Editar",
        is_new: data ? false : true,
        name: data ? data.name : "",
        intervals: data ? data.intervals_score_answer : [],
        id: data ? data.id : null,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.getScoreAnswers(this.page);
        this.modalRef.hide();
      }
    });
  }


  edit(data) {
    this.newAnswer(data)
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  editForm(form) {
    this.modalRef = this.modalService.show(ModalFormPermissionsComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: "Editar permissões e legenda",
        btn_text: "Salvar",
        selectedDoctors: form.doctors,
        access_to_patient: form.access_to_patient,
        captionTitle: form.caption_text,
        fileImage: form.caption_image,
        questions: form.questions,
      },
    });
    this.modalRef.content.action.subscribe(async (result: any) => {
      const data = {
        doctors: result.permissions,
        access_to_patient: result.access_to_patient,
        captionTitle: result.captionTitle,
      };
      console.log(result);
      if (result.file) {
        data["captionImage"] =
          result.file === "issame"
            ? "issame"
            : await this.toBase64(result.file);
      }

      const questions = [];
      for await (const question of result.questions) {
        console.log(question, question.file === "issame");
        const q = {
          captionTitle: question.captionTitle,
          id: question.id,
        };

        if (question.file) {
          q["captionImage"] =
            question.file === "issame"
              ? "issame"
              : await this.toBase64(question.file);
        }
        questions.push(q);
      }
      data["questions"] = questions;

      this.companyService.updateFormDoctors(data, form.id).subscribe(
        () => {
          this.openMessage("Sucesso!", "Permissões atualizadas", false);
          this.getScoreAnswers(this.page);
          this.modalRef.hide();
        },
        (err) => {
          this.openMessage(
            "Erro",
            "Ocorreu um erro ao salvar os profissionais",
            false
          );
        }
      );
    });
  }

  fillForm(form) {
    //console.log(form);
    this.modalRef = this.modalService.show(ModalFormFillComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      width: "100%",
      ignoreBackdropClick: true,
      containerClass: "",
      class: "modal-dialog-centered modal-dialog modal-xl",
      StyleSheet: { width: "100% !important" },
      animated: false,
      data: {
        title: "Preencher Formulário",
        btn_text: "Salvar",
        is_new: false,
        form_clone: form,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getScoreAnswers(this.page);
      }
    });
  }

  deleteAnswer(answer) {
    // this.openMessage(
    //   `Informação.`,
    //   "Essa funcionalidade está sendo desenvolvida, em breve será liberada para você =)",
    //   false
    // );
    // return;
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja deletar a resposta ${answer.name}?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.openMessage("Por favor, aguarde", "", true);
        this.companyService.deleteScoreAnswers(answer.id).subscribe(
          (data) => {
            this.modalRef.hide();
            this.openMessage(
              "Deletado!",
              "Resposta deletada com sucesso.",
              false
            );
            this.getScoreAnswers(this.page);
          },
          (error) => {
            this.modalRef.hide();
            this.error(error, "deletar");
          }
        );
      }
    });
  }

  answerForm(form) {
    this.modalRef = this.modalService.show(ModalFormAnswerListComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: "Respostas do Formulário",
        form_id: form.id,
        form_title: form.title,
        form: form
      },
    });
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} a resposta.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  next() {
    if (this.page_count2 < this.count_total && this.is_loaded) {
      this.data_source.data = [];
      this.is_loaded = false;
      this.getScoreAnswers(this.page + 1);
    }
  }

  prev() {
    if (this.page > 1 && this.is_loaded) {
      this.data_source.data = [];
      this.is_loaded = false;
      this.getScoreAnswers(this.page - 1);
    }
  }

  showCreateWithData(form) {
    
    form.questions.map((question) => {
      const ans = {
        titleQuestion: question.title,
        position: question.position,
        is_selectable: question.is_selectable,
        is_list: question.is_list,
        is_numeric: question.is_numeric,
        is_numeric_scale: question.is_numeric_scale,
        is_image: question.is_image,

        is_date: question.is_date,
        type_date: question.type_date,
        is_frequency: question.is_frequency,
        min_frequency: question.min_frequency,
        max_frequency: question.max_frequency,
        range_frequency: question.range_frequency,
        is_scale: question.is_scale,

        required: question.required,
        number_of_choices: question.number_of_choices,
        alternatives: [],
      };

      console.log(ans);

      ans.alternatives = question.alternatives.map((alternative) => {
        const aux = {
          titleQuestion: alternative.title,
          position: alternative.position,
        };
        return aux;
      });

      return ans;
    });
    this.modalRef = this.modalService.show(ModalFormCreateComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: "Adicionar Formulário",
        btn_text: "Adicionar",
        is_new: true,
        titleQuestion: form.title,
        caption_image: form.caption_image,
        caption_text: form.caption_text,
        questions: form.questions.map((question) => {
          const ans = {
            title: question.title,
            position: question.position,
            is_selectable: question.is_selectable,
            is_list: question.is_list,
            is_numeric: question.is_numeric,
            is_numeric_scale: question.is_numeric_scale,
            is_image: question.is_image,
            is_date: question.is_date,
            type_date: question.type_date,
            is_frequency: question.is_frequency,
            min_frequency: question.min_frequency,
            max_frequency: question.max_frequency,
            range_frequency: question.range_frequency,
            is_scale: question.is_scale,
            required: question.required,
            number_of_choices: question.number_of_choices,
            alternatives: [],
          };

          ans.alternatives = question.alternatives.map((alternative) => {
            const aux = {
              title: alternative.title,
              position: alternative.position,
            };
            return aux;
          });

          return ans;
        }),
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.getScoreAnswers(this.page);
        this.modalRef.hide();
      }
    });
  } 

  showScoreAnswerModels() {
    this.modalRef = this.modalService.show(ModalScoreAnswerModelListComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: "Respostas escala modelo",
        btn_text: "Adicionar",
        is_new: true,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        
        this.newAnswer({...result, id:null})
        this.modalRef.hide();
      }
    });
  }

}
