import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalChoiceComponent } from '../modals/modal-choice/modal-choice.component';
import { ModalMessageComponent } from '../modals/modal-message/modal-message.component';
import { MedicationService } from '../service/medication.service';
import { ModalMedicationDetailComponent } from '../modals/modal-medication-detail/modal-medication-detail.component';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'app-medication',
    templateUrl: './medication.component.html',
    styleUrls: ['./medication.component.css']
})

export class MedicationComponent implements OnInit {

    constructor(
        private medicationService: MedicationService,
        private modalService: MDBModalService
    ) { }
    company_type = localStorage.getItem('companyType')
    displayedColumns: string[] = ['name', 'descrition', 'obs', 'icons'];
    data_source = new MatTableDataSource<any>();

    modalRef: MDBModalRef;
    
    is_loaded: boolean = false;

     /* pagination related variabels */
	page: number = 1
	count_total: number = 0;
	page_count1: number = 0;
	page_count2: number = 0;
    page_size = 10;

    @ViewChild(MatSort, { static: false }) sort: MatSort;
    ngOnInit(): void {
        this.getMedication(1)
    }

    getMedication(page){
        this.is_loaded = false;

        this.medicationService.getMedication().subscribe(data=>{
            //console.log(data)
            this.data_source.data = data.results;
            this.data_source.sort = this.sort;
            // pagination calc
			this.page = page;
			this.count_total = data.results.length;
			this.page_count1 = 1 + ((page-1) * this.page_size)
			if((this.page_count1 + (this.page_size-1)) > this.count_total){
				this.page_count2 = this.count_total;
			}
			else{
				this.page_count2 = this.page_count1 + (this.page_size-1);
            }
            this.is_loaded = true;

        },
        error=>{
            //console.log(error)
            this.is_loaded = true;
        })
    }

    next(){
		if(this.page_count2 < this.count_total && this.is_loaded){
			this.data_source.data = []
			this.is_loaded = false;
			this.getMedication(this.page+1)
		}
	}

	prev(){
		if(this.page > 1 && this.is_loaded){
			this.data_source.data = []
			this.is_loaded = false;
			this.getMedication(this.page-1)
		}
	}

    detailMedication(medication){
        this.modalRef = this.modalService.show(ModalMedicationDetailComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
				title: medication == null ? "Adicionar Medicamento" : "Editar medicamento",
				btn_text: medication == null ? "Adicionar" : "Editar",
				is_new: medication == null ? true : false,
                medication_clone: medication
            }
        })
        this.modalRef.content.action.subscribe( (result: any) => { 
            if(result == "update"){
                this.getMedication(this.page)
			}
        });
    }

    error(error, string){
        var msg = ""
        if(error.error != null && error.error.error != null && error.error.error.length < 50)
            msg = error.error.error;
        else
            msg = "Por favor verifique as informações inseridas"
        this.openMessage(`Erro, não foi possível ${string} o médico.`, msg, false)
    }

    openMessage(title, text, bgclick: boolean){
        this.modalRef = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }
    
}
