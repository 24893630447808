import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { PatientMedicationService } from 'src/app/service/patient-medication.service';
import { MedicationService } from '../../service/medication.service';

@Component({
    selector: 'app-modal-patient-medication-detail',
    templateUrl: './modal-patient-medication-detail.component.html',
    styleUrls: ['./modal-patient-medication-detail.component.css']
})

export class ModalPatientMedicationDetailComponent implements OnInit {

    constructor(
        public modalRef: MDBModalRef, 
        private patientMedicationService: PatientMedicationService,
        private medicationService: MedicationService
    ) { }

	action: Subject<any> = new Subject();
    modalRef2: MDBModalRef;
    msg_error: string = '';
    loading: boolean = true;

    title: string = "";
    btn_text: string;
    is_new: boolean = true;
    isPatient;

    week={
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    }

    selectedMedication;
    listMedication = []
    tamlist: number = 0;
    medication;

    ngOnInit(): void {
        this.getSpecificPatientId(this.isPatient.id)
    }

    getMedication(id){
        this.medicationService.getMedicationId(id).subscribe(data=>{
            //console.log(data)
        },
        error=>{
            //console.log(error)
        })
    }

    getSpecificPatientId(id){
        this.loading = true;
        this.patientMedicationService.getSpecificPatientId(id).subscribe(data=>{
            this.listMedication = data;
            //console.log(this.listMedication)
            this.tamlist = this.listMedication.length
            this.loading = false;
            //console.log(this.tamlist)
        },
        error=>{
            //console.log(error)
        })
    }

    getdias(row) {
        const ans = row.schedules.map((s) => s.day);
        console.log(ans);
        return ans.join(",");
    }

}
