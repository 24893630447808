import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { CompanyService } from "../../service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalFormCreateQuestionComponent } from "../modal-form-create-question/modal-form-create-question.component";
import { ModalChoiceComponent } from "../modal-choice/modal-choice.component";
import { ModalFormPermissionsComponent } from "../modal-form-permissions/modal-form-permissions.component";
import { UserService } from "src/app/service/user.service";

@Component({
  selector: "modal-core-plan-patient-vinculate",
  templateUrl: "./modal-core-plan-patient-vinculate.component.html",
  styleUrls: ["./modal-core-plan-patient-vinculate.component.css"],
})
export class ModalCorePlanPatientVinculateComponent {
  url: string = "https://www.mmlpqtpkasjdashdjahd.com";
  urlSafe: SafeResourceUrl;
  constructor(
    public modalRef: MDBModalRef,
    public sanitizer: DomSanitizer,
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private userService: UserService,
    private datePipe: DatePipe
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";
  is_new: boolean;
  loading: boolean = false;

  patients: any[];

  core_plan: any;
  selected_patients: any[] = [];
  start_date: Date = new Date();
  end_date: Date = new Date();
  is_continuous: any;
  core_plan_patient_id: any;
  ngOnInit() {
    this.getPatients();
  }

  isEmpty(str) {
    if (!str || !str.length) {
      return true;
    }
    return false;
  }

  getPatients() {
    this.loading = true;
    this.userService.getPatients().subscribe((data) => {
      this.patients = data;
      this.loading = false;
    });
  }
  submit() {
    if (!this.start_date) {
      this.openMessage(
        "Erro",
        "Você precisa informar a data de início do plano de cuidado"
      );
      return;
    }
    if (!this.is_continuous) {
      if (!this.end_date) {
        this.openMessage(
          "Erro",
          "Você precisa informar a data de término do plano de cuidado caso ele não seja contínuo"
        );
        return;
      } else {
        if (this.end_date < this.start_date) {
          this.openMessage(
            "Erro",
            "A data de término preciso ser maior que a data de início"
          );
          return;
        }
      }
    }

    if (!this.selected_patients || !this.selected_patients.length) {
      this.openMessage(
        "Erro",
        "Você precisa informar pelo menos um paciente que deseja vincular"
      );
      return;
    }

    let start =
      this.datePipe.transform(this.start_date, "yyyy-MM-dd") + " 00:00:00";
    let end =
      this.datePipe.transform(this.end_date, "yyyy-MM-dd") + " 23:59:59";

    const form = {
      start_date: start,
      end_date: this.is_continuous ? null : end,
      is_continuous: this.is_continuous ? true : false,
      patients: this.selected_patients,
      core_plan: this.core_plan.id,
    };

    this.loading = true;

    if (this.is_new) {
      this.companyService.createPatientCorePlan(form).subscribe(
        (data) => {
          this.openMessage("Sucesso", "Paciente vinculado!");
          this.action.next(true);
          this.modalRef2.hide();
        },
        (error) => {
          let msg = "Ocorreu um erro, tente novamente";
          if (error.error && error.error.detail) {
            msg = error.error.detail;
          }
          this.openMessage("Erro", msg);

          this.loading = false;
        }
      );
    } else {
      this.companyService
        .updatePatientCorePlan(form, this.core_plan_patient_id)
        .subscribe(
          (data) => {
            this.openMessage("Sucesso", "Vínculo do paciente editado!");
            this.action.next(true);
            this.modalRef2.hide();
          },
          (error) => {
            let msg = "Ocorreu um erro, tente novamente";

            if (error.error && error.error.detail) {
              msg = error.error.detail;
            }
            this.openMessage("Erro", msg);

            this.loading = false;
          }
        );
    }
  }

  openMessage(title, text, bgclick: boolean = false) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
