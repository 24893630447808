import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { ModalMessageComponent } from 'src/app/modals/modal-message/modal-message.component';
import { CompanyService } from '../../service/company.service';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { Md5 } from "md5-typescript";

@Component({
  	selector: 'modal-form-detail',
  	templateUrl: './modal-form-detail.component.html',
  	styleUrls: ['./modal-form-detail.component.css']
})

export class ModalFormDetailComponent {

	constructor(
        public modalRef: MDBModalRef, 
        private modalService: MDBModalService,
        private companyService: CompanyService,
        private datePipe: DatePipe,
    ) { }

	action: Subject<any> = new Subject();
    modalRef2: MDBModalRef;
    
    title: string = "";
    btn_text: string;
    is_new: boolean = true;

    form_clone;

    form = {
        id: null,
        title: null,
        link: null,
        created_at: null,
        updated_at: null,
        company_id: null,
        link_sheet: null,
    }


    validator = new ValidatorUtils();

    ngOnInit() {
        if(this.form_clone != null && !this.is_new){
            for(var key in this.form){
                this.form[key] = this.form_clone[key]
            }
        }
    }
    
    checkForm(){
        if(this.validator.isEmptyOrNull(this.form.title)){
            this.openMessage("Por favor,", "insira o título do formulário.", false);
            return false
        }
        else if(this.validator.isEmptyOrNull(this.form.link)){
            this.openMessage("Por favor,", "insira o link do formulário.", false);
            return false
        }
        else if(this.validator.isEmptyOrNull(this.form.link_sheet)){
            this.openMessage("Por favor,", "insira o link da planilha com as resposta do formulário.", false);
            return false
        }
        else{
            if(this.is_new)
                this.createForm()
            else
                this.editForm()
        }
    }

    createForm(){
        this.openMessage("Por favor, aguarde", "", true)
        this.companyService.newForm(this.form).subscribe( data => {
            this.modalRef2.hide()
            this.openMessage("Criado!", "Formulário criado com sucesso.", false)
            this.action.next('update');
            this.modalRef.hide()
        }, error => {
            this.modalRef2.hide()
            this.error(error, 'criar')
        })
    }

    editForm(){
        this.openMessage("Por favor, aguarde", "", true)
        
        this.companyService.editForm(this.form, this.form_clone.id).subscribe( data => {
            this.modalRef2.hide()
            this.openMessage("Salvo!", "Formulário editado com sucesso.", false)
            this.action.next('update');
            this.modalRef.hide()
        }, error => {
            this.modalRef2.hide()
            this.error(error, 'editar')
        })
    }

	error(error, string){
        var msg = ""
        if(error.error != null && error.error.error != null && error.error.error.length < 50)
            msg = error.error.error;
        else
            msg = "Por favor verifique as informações inseridas"
        this.openMessage(`Erro, não foi possível ${string} o médico.`, msg, false)
    }

	openMessage(title, text, bgclick: boolean){
        this.modalRef2 = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }

}