import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class CompanyInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const company = localStorage.getItem("company");
    const modifiedReq = req.clone({ 
      params: req.params.set('company', company)
    });
    return next.handle(modifiedReq);
  }
}