import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { UserService } from "src/app/service/user.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import { CalendarService } from "src/app/service/calendar.service";
import { ProcedureService } from "src/app/service/procedure.service";
import { ModalPatientDetailComponent } from "src/app/modals/modal-patient-detail/modal-patient-detail.component";

@Component({
  selector: "modal-add-calendar",
  templateUrl: "./modal-add-calendar.component.html",
  styleUrls: ["./modal-add-calendar.component.css"],
})
export class ModalAddCalendarComponent {
  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private userService: UserService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private calendarService: CalendarService,
    private procedureService: ProcedureService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;
  title: string = "";
  btn_text: string;
  is_new: boolean = true;
  event: any;

  patient: string;
  date: string;
  hour: string;
  observation: string;
  validator = new ValidatorUtils();
  loadingPatient: boolean = false;
  loadingDoctor: boolean = false;
  allPatients: any[] = [];
  patients: any[] = [];

  doctor: string;
  allDoctor: any[] = [];
  doctors: any[] = [];

  procedures: any[] = [];
  procedure: any;
  procedure_time: any;

  isSecretary = false;

  ngOnInit() {
    const d = localStorage.getItem("doctor");
    if (d) {
      const aux = JSON.parse(d);
      if(aux.specialty === 23)
        this.isSecretary = true;
    }
    this.getProcedures();
    if (!this.event) {
      this.getPatients(1);
    } else {
      this.startField(this.event);
    }

    if(this.isSecretary) {
      this.getDoctors();
    }
  }

  onChange() {
    try {
      this.procedure_time = this.procedures.filter(
        (p) => p.id === this.procedure
      )[0].time;
    } catch {}
  }

  startField(ev) {
    this.observation = ev.observation;

    this.date = ev.cleanDate.split("T")[0].split("-").reverse().join("/");
    this.hour = ev.cleanDate.split("T")[1].substring(0, 5);
    this.procedure = ev.procedure.id;
    this.procedure_time = ev.procedure_time;
  }

  getProcedures() {
    this.procedureService.getProcedures().subscribe(
      (data) => {
        this.procedures = data;
      },
      (error) => {}
    );
  }

  getPatients(page, search = null) {
    this.loadingPatient = true;
    this.userService.getPatientsPagination(page).subscribe(
      (data) => {
        data = data.filter((item) => item.is_doctor === false);
        // console.log(data);
        data.sort((a, b) => a.name.localeCompare(b.name));
        this.allPatients = data;
        this.patients = data;
        this.loadingPatient = false;
      },
      (error) => {}
    );
  }

  getDoctors() {
    this.loadingDoctor = true;
    this.companyService.getAllDoctorsFromCompany().subscribe(
      (data) => {
        // data = data.filter((item) => item.is_doctor === false);
        // console.log(data);
        data.sort((a, b) => a.name.localeCompare(b.name));
        this.allDoctor = data;
        this.doctors = data;
        this.loadingDoctor = false;
      },
      (error) => {}
    );
  }

  accentFold(inStr) {
    return inStr.replace(
      /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
      function (str, a, c, e, i, n, o, s, u, y, ae) {
        if (a) return "a";
        if (c) return "c";
        if (e) return "e";
        if (i) return "i";
        if (n) return "n";
        if (o) return "o";
        if (s) return "s";
        if (u) return "u";
        if (y) return "y";
        if (ae) return "ae";
      }
    );
  }

  selectSearchPatient(value: string) {
    this.patients = [];
    let filter = this.accentFold(value.toLowerCase());
    for (let i = 0; i < this.allPatients.length; i++) {
      let option = this.allPatients[i];
      if (this.accentFold(option.name.toLowerCase()).indexOf(filter) >= 0) {
        this.patients.push(option);
      }
    }
  }

  selectSearchDoctor(value: string) {
    this.doctors = [];
    let filter = this.accentFold(value.toLowerCase());
    for (let i = 0; i < this.allDoctor.length; i++) {
      let option = this.allDoctor[i];
      if (this.accentFold(option.name.toLowerCase()).indexOf(filter) >= 0) {
        this.doctors.push(option);
      }
    }
  }

  getForm() {
    const aux = this.date.split("/");
    const time = `${aux[2]}-${aux[1]}-${aux[0]}T${this.hour}:00`;
    let body = {
      patient: this.patient,
      observation: this.observation,
      date: time,
      procedure: this.procedure,
      procedure_time: this.procedure_time,
    };
    if (this.isSecretary) {
      body['doctor'] = this.doctor;
    }
    return body;
  }

  insertRecord() {
    this.openMessage("Por favor, aguarde", "", true);
    var body = this.getForm();

    if (this.event) {
      this.calendarService.editRecord(body, this.event.id).subscribe(
        (data) => {
          this.modalRef2.hide();
          this.openMessage("Sucesso!", "Registro editado", false);
          this.action.next(data);
          this.modalRef.hide();
        },
        (error) => {
          this.modalRef2.hide();
          this.error(error, "editar");
        }
      );
    } else {
      this.calendarService.createRecord(body).subscribe(
        (data) => {
          this.modalRef2.hide();
          this.openMessage("Sucesso!", "Registro criado", false);
          this.action.next(data);
          this.modalRef.hide();
        },
        (error) => {
          this.modalRef2.hide();
          this.error(error, "criar");
        }
      );
    }
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi adicionar o registro.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "modal-lg",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  createRecord() {
    if (this.validator.isEmptyOrNull(this.patient) && !event) {
      this.openMessage("Por favor,", "informe o paciente.", false);
      return false;
    } else if (this.isSecretary && this.validator.isEmptyOrNull(this.doctor) && !event) {
      this.openMessage("Por favor,", "informe o profissional responsável", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.procedure)) {
      this.openMessage("Por favor,", "informe o tipo de procedimento.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.date)) {
      this.openMessage("Por favor,", "insira a data do registro.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.hour)) {
      this.openMessage("Por favor,", "insira a hora do registro.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.procedure_time)) {
      this.openMessage("Por favor,", "insira o tempo do procedimento.", false);
      return false;
    } else {
      var day = Number(this.date.substring(0, 2));
      var month = Number(this.date.substring(3, 5));
      var year = Number(this.date.substring(6, 10));

      if (day > 31 || day < 1 || month > 12 || month < 1 || year < 1900) {
        this.openMessage("Por favor,", "insira uma data válida", false);
        return false;
      }
      var hora = Number(this.hour.substring(0, 2));
      var minuto = Number(this.hour.substring(3, 5));
      if (hora >= 24 || hora < 0 || minuto > 59 || minuto < 0) {
        this.openMessage("Por favor,", "insira uma hora válida", false);
        return false;
      }
    }
    this.insertRecord();
  }

  addPatient() {
    this.modalRef = this.modalService.show(ModalPatientDetailComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: "Adicionar Paciente",
        btn_text: "Adicionar",
        is_new: true,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getPatients(1);
      }
    });
  }
}
