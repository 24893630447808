import { Component, OnInit } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Md5 } from "md5-typescript";
import { ModalChoiceComponent } from "src/app/modals/modal-choice/modal-choice.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { AdminService } from "src/app/service/admin.service";
import { CompanyService } from "src/app/service/company.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { ModalDiagnosisomponent } from "./modal-diagnosis/modal-diagnosis.component";


@Component({
  selector: "app-diagnosis",
  templateUrl: "./diagnosis.component.html",
  styleUrls: ["./diagnosis.component.css"],
})
export class DiagnosisComponent implements OnInit {
  
  modalRef: MDBModalRef;
  loading: boolean =false;
  data: any[]
  

  titles = ["code", "name", 'domain', 'class', 'edit'];
  

  constructor(
    private modalService: MDBModalService,
    private companyService: CompanyService,
    private adminService: AdminService,
  ) {}

  ngOnInit(): void {
    this.getDiagnosis();
  }


  add(diagnosis = null) {
    this.modalRef = this.modalService.show(ModalDiagnosisomponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-xl modal-size modal-xl",
      containerClass: "",
      animated: false,
      data: {
        title: (!diagnosis ? 'Cadastrar ' : "Editar ") + 'diagnóstico',
        
        diagnosis
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.getDiagnosis();
      }
    });
  }

  deleteDiagnosis(diagnosis) {
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja deletar esse diagnóstico?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        console.log('Apagar')
        this.adminService.removeDiagnosis(diagnosis.id).subscribe((data) => {
          this.openMessage("Sucesso", "Diagnóstico deletado", false);
          this.getDiagnosis();
        },
        (error) => {
          this.error(error, "deletar");
        })
      }
    });
  }

  getDiagnosis() {
    this.loading = true;
    this.adminService.getDiagnosis().subscribe((data) => {
      this.data = [...data];
      this.loading = false;      
    })
  }


  

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} o formulário.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  
}
